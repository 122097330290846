@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

.cardAmplify{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 119px;
  gap: 6px;
  align-self: stretch;
  border-radius: 10px;
border: 0.5px solid var(--gray-300);
background-color: var(--white-base);
box-shadow: 1px 1px 9px 0px #EDEDED;
}
.cardAmplifyIcon{
  display: flex;
  width: 70px;
  height: 70px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 10px;
  background: var(--blue-100);
}
.cardAmplifyContainer{
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 6px;
  padding: 0px;
  width: 100%;
  height: 80px;
}
.cardAmplifyText{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  width: 100%;
}
.cardAmplifyTitle,
.cardAmplifyClient,
.cardAmplifyValue{
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  font-style: normal;
  line-height: normal;
  color: var(--gray-600);
}
.cardAmplifyTitle{
  color: var(--gray-600);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.28px;
}
.cardAmplifyClient{
  color: var(--gray-600);
   font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;



  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.26px;
}
.cardAmplifyValue{
  color: var(--gray-600);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.26px;
}
.cardAmplifyValueTotal{
  font-weight: 600;
  color:var(--blue-700);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


font-size: 24px;
font-style: normal;
line-height: normal;
letter-spacing: 0.48px;
}
.cardAmplifyProgressBar{
  width:100%;
  height: 8px;
  border-radius: 20px;
  background: var(--gray-100);
}
.cardAmplifyProgressBarActive{
  width: 114.393px;
  height: 8px;
  border-radius: 20px;
  background: var(--blue-300);
}
.descubristeContainer, .esteMesContainer, .disponiblesContainer {
  display: flex;
  flex-direction: column;
padding: 16px 24px;
justify-content: center;
gap: 12px;
height: 88px;
width: 100%;
}
.verticalDivider{
  display: flex;
  height: 100px;
  border: 1px solid var(--gray-300);
}
.disponiblesContainer{
  flex-direction: row;
}
.descubristeContainerIcon, .esteMesContainerIcon, .disponiblesContainerIcon{
  display: flex;
align-items: center;
gap: 4px;
}
.descubristeContainerIcon p, .esteMesContainerIcon p, .disponiblesContainerIcon p{
  color: var(--gray-600);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.28px;
}
.descubristeContainerValue, .esteMesContainerValue{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 4px;
}
.descubristeValue, .esteMesValue, .disponiblesValue{
  color: var(--gray-700);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: 0.48px;
}
.disponiblesValue{
  height: 27px;
}
.descubristeClientesPotenciales, .esteMesClientesPotenciales, .disponiblesClientesPotenciales{
  color: var(--gray-500);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.26px;
}

.disponiblesCantClientes{
  display: flex;
align-items: flex-end;
gap: 6px;
height: 21px;
}
.disponiblesMasCreditosContainer{
  display: flex;
justify-content: center;
align-items: center;
gap: 2px;

}
.disponiblesMasCreditos{
  color: var(--primary-600);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


font-size: 13px;
font-style: normal;
font-weight: 500;
line-height: normal;
width: 159px;
cursor: pointer;
}
.disponiblesChart{
  display: flex;
width: 81px;
height: 81px;
justify-content: center;
align-items: center;
align-self: center;
}
.disponiblesContainerContent{
  display: flex;
flex-direction: column;
align-items: flex-start;
gap: 16px;
}
.closeModal{
  cursor: pointer;
}
.disponiblesContainerSubContent{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}
@media  (max-width:1124px) {
	.cardAmplify {
		display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px;
    gap: 6px;
    width: 90%;
	}
	.cardAmplifyTexto{
		color: var(--gray-600);
		    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


		font-size: 11px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		letter-spacing: 0.22px;
	}
	.cardAmplifyValor p {
		color: var(--gray-600);
		    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


		font-size: 20px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		letter-spacing: 0.4px;
	}

}

