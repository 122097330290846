@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
    margin: 0;
    padding: 0;
}

.bodyBox {
    align-items: flex-start;
    border-radius: 6px;
    border: 1px solid var(--gray-300);
    background-color: var(--white-base);
    bottom: 50px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    height: 6.8em;
    padding: 16px;
    position: absolute;
    width: 100%;
    z-index: 3;
}

.bodyBox.closed {
    left: 84px;
    width: 12em;
}

.bodyBox.expanded {
    left: 241px;
}

.bodyBoxUP,
.bodyBoxDown {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
}

.bodyBoxUP {
    gap: 6px;
}

.bodyBoxDown {
    gap: 16px;
}

.bodyBoxDown {
    padding: 8px;
    border-radius: 8px;
}

.bodyBoxPerfil {
    display: flex;
    align-items: center;
    gap: 6px;
    align-self: stretch;
}

.bodyBoxImg {
    display: flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;

}

.bodyBoxImgSecond {
    margin-left: 4px;
}

.bodyBoxPerfil img,
.bodyBoxCompany img {
    width: 24px;
    height: 24px;
}



.bodyBoxCompany {
    display: flex;
    align-items: center;
    align-self: stretch;
    /* padding: 0px 8px 0px 4px; */
    gap: 6px;
}

.dividerBox {
    border: 0.5px solid #E2E4E3;
    width: 100%;
}

.bodyBoxCountryContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
    /* margin-bottom: 16px; */
}

.bodyBoxCountryContainer p {
    color: var(--gray-600);
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 15px;
    /* 136.364% */
    letter-spacing: 0.22px;
}

.bodyBoxCountry {
    display: flex;
    height: 32px;
    padding: 4px 8px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid var(--gray-300);
    background-color: var(--white-base);
}

.custom-option {
    display: flex;
    padding: 8px;
    gap: 8px;
    border-radius: 8px;
    background-color: var(--white-base);
}

.bodyBoxTextItem {
    width: 100%;
    padding: 4px 0px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.bodyBoxLeftItem {
    display: flex;
    align-self: stretch;
    justify-content: space-between;
    align-items: center;

    gap: 4px;
    margin-bottom: 16px;
}

.bodyBoxRightItem {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    gap: 4px;
    flex-direction: column;

}

.bodyBoxBuyerPersona {
    display: flex;
    align-items: flex-start;
    gap: 6px;
}

.bodyBoxBuyerPersona img {
    height: 24px;
    width: 24px;

}

.bodyBoxBuyerPersona p {
    align-self: center;
    color: var(--gray-600);
     font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.24px;
    width: max-content;
}

.bodyBoxItemText {
    color: var(--gray-600);
     font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.32px;
}

.bodyBoxItemTextSecond {
    color: var(--gray-600);
     font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 0.26px;
}

.bodyBoxValue {
    color: var(--gray-800);
     font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.26px;
}

/* .bodyBoxItemText{
    color: var(--sistem-texto-negro-texto, #707477);
    font-weight: 500;
}
.bodyBoxItemTextSecond{
    color: var(--sistem-texto-negro-texto, #707477);
    font-weight: 300;
}
.bodyBoxValue{
    color: var(--sistem-texto-negro-prrafo, #34343F);
    font-weight: 400;
} */




.absoluteFlag {
    position: absolute;
    left: 25%;
}

.css-os8l6b-singleValue {
    padding-left: 20%;
}

.css-1lyzeu2-placeholder,
.css-1t38kps-placeholder,
.css-os8l6b-singleValue {
    padding-left: 20%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre;
    width: 80%;
    font-size: 12px;
}

.css-1t38kps-placeholder {
    padding-left: 20%;
    font-size: 12px;
}

.imgBuyerPersona {
    border-radius: 6px;

    display: flex;
    width: 52px;
    height: 52px;
    justify-content: center;
    align-items: center;


}

.imgAmplifyPersona {
    border-radius: 6px;

    display: flex;
    width: 52px;
    height: 52px;
    justify-content: center;
    align-items: center;

}

.bodyBoxLeftItem {
    display: flex;
    align-items: center;
    gap: 6px;
    align-self: stretch;

    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    transition: all .5s ease;
}

.bodyBoxLeftItemNone {
 /*    overflow: hidden; */
    gap: 0px;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    margin-bottom: 0em;
    width: 100%;
    transition: all .5s ease;
    height: 100%;
    display: flex;
    align-items: center;
}

.css-1n6sfyn-MenuList {
    height: 10em;
}

.css-1ipe7nu-container {
    width: 80%;
}

.BoxUserNameMenu {
    color: var(--gray-600);
     font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 19.5px; /* 121.875% */
    letter-spacing: 0.32px;
}

.css-3mrmOx-menu {
    height: 248px;
}

.bodyBoxLeftItem {
    width: 100px;
    /* Ajusta el ancho del contenedor */
}

.chartBoxNavbar {
    position: absolute;
    right: -18px;
}

.PopUpboxNavbarData {
    display: none;
}

.bodyBoxLeftItemAmHover:hover .PopUpboxNavbarData {
    position: absolute;
    height: 49px;
    z-index: 4;
    display: flex;
    width: max-content;
    padding:10px;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
    border-radius: 4px;
    border: 1px solid var(--gray-300);
    background-color: var(--white-base);
    left: 16.8em;
    
}
.PopUpboxNavbarDataSecond{
    bottom: 0px;
}

.PopUpboxNavbarDataTitle {
    color: var(--gray-600);
     font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.24px;
}

.PopUpboxNavbarDataText {
    color: var(--gray-600);
     font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 0.24px;
}

.PopUpboxNavbarDataNumbers {
    color: var(--gray-600);
     font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.24px;
}

.PopUpboxNavbarDataSpan {
    color: var(--gray-800);
     font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.24px;
}

@media (max-width:1439px) {

    .bodyBox.closed {
        left: 68px;
  
    }

    .bodyBox.expanded {
        left: 164px;
    }
}

@media (min-width: 1920px) {
  
    .bodyBox.expanded {
        left: 272px;
    }
    .bodyBoxLeftItemAmHover:hover .PopUpboxNavbarData{
        left: 19em;
    }
}