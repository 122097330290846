.cardDatosGeo{
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
  border-radius: 10px;
  border: 1.5px solid var(--gray-300);
  background-color: var(--white-base);
  width: 578px;
}
.cardTitleDatosGeo,
.cardTextDatosGeo{
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  font-style: normal;
  line-height: 19.5px; 
  letter-spacing: 0.26px;
}
.cardTitleDatosGeo{
  color: var(--gray-600);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 19.5px; /* 121.875% */
letter-spacing: 0.32px;
}
.cardTextDatosGeo{
  color: var(--gray-600);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


font-size: 12px;
font-weight: 400;
}
.cardContentDatosGeo{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 100%;
}
.formDatosGeo{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex-shrink: 0;
  width: auto;
}
.formDatosGeo.region{
  width: 182px;
}
.formDatosGeo.comuna{
  width: 190px;
}
.labelDatosGeo{
  color: var(--gray-600);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0px 8px;
}
.labelDatosGeo.Porcentaje{
  padding: 0px 0px;
}
.selectDatosGeo{
  display: flex;
  gap: 10px;
height: 38px;
  color: var(--gray-600);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal; 
  letter-spacing: 0.26px;
}
.selectDatosGeo:focus {
  outline: none;
  border-radius: 10px;
  border: 2px solid var(--primary-400);
  background-color: var(--white-base);
}
.selectDatosGeo.region{
  width: 184px;
}
.selectDatosGeo.comuna{
  width: 190px;
}
.inputContainerDatosGeo{
  display: flex;
  align-items: center;
  gap: 4px;
}
.inputDatosGeo{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 46px;
  height: 36px;
  padding: 0px 8px;
  flex: 1 0;
  border-radius: 6px;
  border: 2px solid var(--gray-300);
  background: var(--gray-25);
  outline-color: var(--primary-500);
}

.inputContainerDatosGeo p{
  color: var(--gray-600);
   font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;



  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.5px; /* 139.286% */
  letter-spacing: 0.28px;
}
.buttonNuevosCamposContenedor{
  display: flex;
  gap: 6px;
}
.imgNuevoCampos{
  width: 18px;
  height: 18px;
  
}
.buttonNuevosCampos{
  border-radius: 4px;
border: 0.5px solid  #E2E4E3;
background-color: var(--white-base);
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
width: 38px;
height: 38px;
}
.campoAdicional {
  display: flex;
  width: auto;
  align-items: center;
  gap: 16px;
}

.inputDatosGeo.invalid {
  border: 2px solid var(--primary-500);
}
.warningData{
  color:var(--warning-500);
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


font-size: 12px;
font-weight: 400;
font-style: normal;
line-height: 19.5px; /* 243.75% */
letter-spacing: 0.16px;
align-self: end;
}
@media (max-width:1024px){
  .selectDatosGeo.region{
    width: 100%;
  }
 
}