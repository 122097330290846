* {
    margin: 0;
    padding: 0;
}

.inputGeneral{
    display: flex;
    width: -webkit-fill-available;
height: 34px;
    align-items: center;
    flex-shrink: 0;
    color: var(--gray-600);
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 19.5px; /* 139.286% */
    letter-spacing: 0.28px;
    border-radius: 10px;
border: 0.5px solid var(--secondary-gray-300, #D0D5DD);
}
.inputGeneral::placeholder{
    color: var(--gray-400);
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.28px;
}
