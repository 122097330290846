@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
*{
  margin: 0;
  padding: 0;
}

.loginError{
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}
.loginErrorCard {
  display: flex;
padding: 20px;
flex-direction: column;
align-items: center;
gap: 44px;
}
.loginErrorCard img{
  width: 252px;
height: 252px;
}
.loginError_container{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 19px;
  width: 300px;
}
.loginError_container h1 {
  color: var(--gray-600);
text-align: center;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


font-size: 26px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.52px;
}
.textLoginError {
  height: 48px;
  width: 330px;
  display: flex;
  align-items: flex-start;
  gap: 19px;
  color: var(--gray-600);
text-align: center;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


font-size: 12px;
font-style: normal;
font-weight: 300;
line-height: 24px; /* 184.615% */
}
.btnPrimaryLoginError{
  display: flex;
  width: 122px;
  padding: 8px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: var(--primary-600);
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25);
  
color: var(--white-base);
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 21px; /* 150% */
letter-spacing: 0.28px;

}
.btnPrimaryLoginError:hover{
  border-radius: 8px;
  background: var(--primary-500);
  box-shadow: 0px 2px 4px 0px #D0D5DD;
}
@media (max-width: 1024px) {
  .loginErrorCard img{
    width: 200px;
    height: 200px;
  }
}

