/* SelectBox.css */

/* Estilos para el contenedor del select */
.SelectBox {
  height: 38px;
}
.css-1osikpm-container {
  position: relative;
  box-sizing: border-box;
  width: 100%!important;
  height: 38px!important;
}
/* Estilos para el control del select */
.selectBox__control {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer!important;
  width: 575px!important;
  height: 38px;
  padding: 0px 8px;
  border-radius: 10px!important;
  border: 2px solid var(--gray-300)!important;
  background: var(--white-base)!important;
}
.selectBox__control--is-focused {
  outline: none;
  border-radius: 10px;
  border: 2px solid var(--primary-400)!important;
  box-shadow: 2px solid var(--primary-400)!important;
}
.selectBox__placeholder{
  color: var(--gray-400)!important;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
  font-size: 12px!important;
  font-style: normal!important;
  font-weight: 400!important;
  line-height: normal!important;
  letter-spacing: 0.28px!important;
}
/* Estilos para el menú de opciones */
.selectBox__menu,
.selectBox__menu-list {
  top: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  
  color: var(--gray-600)!important;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 12px!important;
  font-style: normal;
  font-weight: 400;
  line-height: 19.5px; /* 139.286% */
  letter-spacing: 0.28px;
}
.selectBox__menu{
  padding: 8px;
  gap: 8px;
  border-radius: 6px!important;
  background: var(--white-base)!important;
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.10)!important;
  margin: 0px;
  overflow-y: auto;
}
.selectBox__menu-list{
  box-sizing: content-box;
}
/* Estilos para el separador del indicador */
/* .selectBox-indicator-separator {
  display: none;
} */
/* Estilos para las opciones */
.selectBox__option {
  padding: 6px!important;
  cursor: pointer!important;

  color: var(--gray-600)!important;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
  font-size: 12px!important;
  font-style: normal;
  font-weight: 400;
  line-height: 19.5px; /* 139.286% */
  letter-spacing: 0.28px;
}
.selectBox-option:active {
  background: white!important;
}

/* Estilos para el valor seleccionado */
.selectBox__single-value {
  color: var(--gray-600)!important;
   font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;



  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.5px; /* 139.286% */
  letter-spacing: 0.28px;
  background-color: transparent!important;
}
@media (min-width:1440px){
  .selectBox__control{
    width: 535px;
  }
  .selectBox__menu{
    min-width: 535px;
  }
}
.css-1jd79bt-option{
  background-color: var(--white-base)!important;
  color: var(--gray-600)!important;
  font-size: 12px!important;
  font-weight: 400!important;
   font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',  sans-serif !important;

}
.react-select__option:hover{
  background: pink!important;
}
