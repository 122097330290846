@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

/*  */

.Confirmacion{
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  width: 388px;
  padding: 16px;
  gap: 24px;
  border-radius: 20px;
  background-color: var(--white-base);
  filter: drop-shadow(1px 1px 9px #EDEDED);

}
.Confirmacion_FormContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  gap: 16px;
}
.imgConfirmacionBP{
  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 10px;
  background: var(---blue-25);
}
.negativeBackground{
  background: var(--error-25);
}
.imgConfirmacionBP img{
  display: flex;
  align-items: flex-start;
  width: 200px;
  height: 200px;
  padding: 24px;
}
.Confirmacion_resumen{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  width: 328px;
}
.Confirmacion_box{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  /* gap: 16px; */
}
.Confirmacion_boxText{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: 8px;
}
.Confirmacion_text{
  display: flex;
  align-items: center;
  align-self: stretch;
  gap: 2px;
}
.Confirmacion_textIcon img{
  width: 24px;
  height: 24px;
}
.Confirmacion_textIcon{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 6px;
  background: var(--blue-25);
}
.Confimacion_line{
  width: 307px;
  height: 1.5px;
  background: var(--gray-300);
  margin-top: 16px;
  margin-bottom: 4px;
}
.Confirmacion_boxCreditos{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: auto;
  gap: 12px;
}
.Confirmacion_Creditos{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
min-width: 100px;
  padding: 8px 2px;
  gap: 2px;
  flex: 1 0 0;
}

/* Textos */
.Confirmacion_title, .Confirmacion_text p,
.signsCred, .TextCred {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  font-style: normal;
  line-height: 19.5px; 
}
.Confirmacion_title, .Confirmacion_text p,
.Creditos{
  color: var(--gray-600);
}
.signsCred, .TextCred{
  color: var(--gray-400);
}
.Confirmacion_title{
  color: var(--gray-600);
  text-align: center;
   font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;



  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 19.5px; /* 121.875% */
  letter-spacing: 0.32px;
}
.Confirmacion_text p{
  color: var(--gray-600);
  text-align: center;
   font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;



  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.5px; /* 162.5% */
  letter-spacing: 0.24px;
}
.signsCred{
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.4px;
}
.TextCred{
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0.28px;
}
.Creditos{
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.4px;
}
.negativeCred{
  color: var(--error-400);
}
.negativeText{
  color: var(--error-400);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.24px;
}
/* Botones */
.ConfirmacionBP{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}
.btnSecondary_ConfirmacionBP, .btnPrimary_ConfirmacionBP{
  display: flex;
  height: 40px;
  padding: 8px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  border-radius: 8px;
  cursor: pointer;

  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px; 
  letter-spacing: 0.28px;
}
.btnSecondary_ConfirmacionBP {
  border-radius: 8px;
border: 1.5px solid var(--gray-300);
background-color: var(--white-base);
color: var(--gray-600);

}
.btnSecondary_ConfirmacionBP:hover{
  border-radius: 8px;
border: 1.5px solid var(--gray-300);
background-color: var(--white-base);

/* hover */
box-shadow: 0px 2px 4px 0px #D0D5DD;
}
.btnPrimary_ConfirmacionBP{
  color: var(--white-base);

  border-radius: 8px;
  background: var(--primary-600);
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25);
 
}
.btnPrimary_ConfirmacionBP:hover{
  border-radius: 8px;
background: var(--primary-500);
box-shadow: 0px 2px 4px 0px #D0D5DD;
}
/* MediaQuerys */
@media (max-width:1439px) {
  .Confirmacion{
    display: flex;
    width: 384px;
    height: 470px;
    padding: 16px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 0px;
  }
  .Confirmacion_FormContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    align-self: stretch;
  }

  .imgConfirmacionBP{
    width: 388px;
    height: 200px;
    align-self: center;
  }
  .imgConfirmacionBP img{
    width: 152px;
    height: 152px;
  }
}