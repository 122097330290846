@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

.enterpriceposition{
  display: flex;
  justify-content: space-between;
}
/* Boton para expandir */
.boxBrandButton{
  display: flex;
  width: 100%;
  justify-content: center;
height: 20px;
padding: 16px 0px;
}
.toggleButton, 
.toggleButtonOpen {
  display: flex;
  border: solid 0.5 var(--gray-50); 
  flex-direction: row;
  align-items: flex-start;
  padding: 8px;
  gap: 10px;
  position: absolute;
  top: 14px;
  border-radius: 4px;
  background-color: var(--white-base);
  cursor: pointer;

}
.toggleButton{
  left: 55px;
}
.toggleButtonOpen{
  left: 214px;
}
.toggleButton img,
.toggleButtonOpen img{
  width: 24px;
  height: 24px;
}

/* Navbar */
.navbar {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 64px; /* ancho total de 80px - 16px de padding */
  height: -webkit-fill-available;
  gap: 80px;
  background-color: var(--white-base);
  z-index: 999;
}
.navbarLink {
  display: flex;
}
.navbarLink span{
  color: var(--gray-600);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.14px;
}
.IconContainerNav{
  width: 24px;
  height: 24px;
}

.active{  background: var(--white-base); }
/* Navbar cerrado */
.menuPrincipal{
  height: 100%;
}
.menuPrincipal,
.menuSecundario {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 62px;
  align-items: center;
}
.navbarRectangleDefault{
  display: flex;
  width: 4px;
  height: 44px;
  flex-shrink: 0;
  background: transparent;
}
.menuPrincipal a,
.menuSecundario a,
.menuSecundario button{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
}
.menuPrincipal a:hover,
.menuSecundario a:hover,
.menuSecundario button:hover {
  border-radius: 6px;
  border: 0.25px solid var(--gray-300);
  background: var(--gray-100);
  height: 43px;
  width: 43px;
}
/* .selected {
  background: var(--gray-100);
  border-radius: 8px;

} */

/* Navbar abierto */
.navbar.expanded {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 0px;
  width: 234px; 
  height: -webkit-fill-available;/* largo total de 900px - 24px de padding */
  gap: 72px;
  background-color: var(--white-base);
  filter: drop-shadow(1px 1px 9px #EDEDED) 
}
.boxBrandNav {
  display: flex;
  align-self: center;
  padding: 16px 0px;
height: 20px;
justify-content: center;
}
.brandSimpleNavbar{
  width: 27px;
  height: 28px;

}
.positivo{
  width: 124px;
  height: 28px;

}
.menuContentContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}
/* .menuPrincipalAbierto{
  height: 500px;
} */
.menuPrincipalAbierto,
.menuSecundarioAbierto {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  margin-right: 12px;
}
.menuPrincipalAbierto{
  height: 100%;
}

.divider{
  border: 1px solid #E2E4E3;
  width: 100%;
}

.menuPrincipalAbierto a,
.menuSecundarioAbierto a,
.menuSecundarioAbierto button{
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 44px;
  width: 208px;
  gap: 12px;
  border-radius: 8px;
}
/* .menuPrincipalAbierto a:hover,
.menuSecundarioAbierto a:hover {
  border-radius: 6px;
border: 0.25px solid var(--gray-300);
background: var(--gray-100);
} */
 .ImagenSeparacionNavbar{
  display: none;
  }
.navbarLink:hover .ImagenSeparacionNavbar{
display: block;
}
.menuSecundarioAbierto button{
  display: flex;
  align-items: center;
  align-self: stretch;
  flex-direction: row;
  height: 48px;
  gap: 4px;
  border-radius: 8px;
  width: 100%;
}


/*  768 */

.separaciónTextoImagen{
  width: 100%;
  display: flex;
  justify-content:space-between ;
}

/* desde 1920 */

/* nuevos estilos navs */
.navbarLinkContainer{
  display: flex;
  gap: 10px;
  align-items: center;
  width: -webkit-fill-available;
}


.itemNavLinkselected .navbarRectangleHover{
  display: flex;
  width: 4px;
  height: 44px;
  flex-shrink: 0;
  background: var(--primary-600);
}

.itemNavLinkselected:hover .navbarRectangleHover {
  background: var(--gray-800); 
}

.navbarLink:hover{
  border-radius: 6px;
background: var(--gray-100);
height: 44px;
width: 208px;
}

.navbarLinkContainer:hover {
  display: flex;
  align-items: center;

}
.navbarLinkContainer:hover .navbarRectangleHover{
  background: var(--gray-800);
}
.navbarLink:hover span{
  color: var(--gray-800);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.15px;
}
.selected span{
  color: var(--gray-800);
  font-weight: 600;
}
.BPIcon, .navbarLink:hover .BPIcon{
  stroke: #1D2939;
  fill: none;
}
.AmplifyIcon, .navbarLink:hover .AmplifyIcon{
  stroke: #1D2939;
}
.selected .AmplifyIcon{
  stroke: var(--primary-400);
}
.selected .BPIcon{
  fill: var(--primary-400);
  stroke: var(--primary-400);
}
.helpIcon, .navbarLink:hover .helpIcon{
  stroke: #1D2939;
  fill: none;
}
.selected .helpIcon{
  fill: var(--primary-400);
  stroke: transparent;
}

.dividerNavPrincipal{
  border: 1px solid var(--gray-300);
}
.contentClosed .dividerNavPrincipal {
  width: 44px;
display: flex;
align-self: center;

}
.navbar.expanded .dividerNavPrincipal{
  width: 176px;
  display: flex;
align-self: center;
}

.navbarLink img{
  width: 1.5em;
  height: 1.5em;
  object-fit: cover;
}

@media (min-width: 1920px){
  .boxBrandNav img{
    width: 124.444px;
    height: 28px;
  }
  .toggleButtonOpen {
    display: none;
  }
  .navbar.expanded{
    padding: 24px 16px;
  }
 
}
@media (max-width: 1439px) {
  .toggleButton{
    display: none;
  }
  .boxBrandNav img{
    width: 80px;
    height: 18px;
  }
  .navbar{
    width: 78px; 
    height: -webkit-fill-available;
    border-radius: 2px;
    
  }
  
  .navbar.expanded{
    width: 144px;
    height: -webkit-fill-available;
    padding: 24px 8px;
  }
  .navbarLink img{
    display: flex;
    width: 20px;
    height: 20px;
  }
  .navbarLink span{
    font-size: 12px;
    letter-spacing: -0.12px;
  }
 
  
 
  .menuSecundario button{
    width: 44px;
    height: 44px;
    padding: 12px;
    gap: 10px;
  }
  .toggleButtonOpen img,
  .toggleButton img{
    width: 16px;
    height: 16px;
  }
  .toggleButton {
    left: 45px;
    top: 15px;
  }
  .toggleButtonOpen{
    left: 140px;
    top: 16px;
  }
  .menuPrincipalAbierto a,
  .menuSecundarioAbierto a{
    display: flex;
    height: 36px;
    padding: 0px 12px;
    align-items: center;
    gap: 6px;
    align-self: stretch;
    width: 120px;
  }
  .menuSecundarioAbierto button{
    display: flex;
    align-items: center;
    align-self: stretch;
    flex-direction: row;
    height: 36px;
    gap: 2px;
    border-radius: 8px;
    width: 100%;
  }
  .menuPrincipal{
    width: 100%;
  }
 
}