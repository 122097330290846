.login__formCrearCuenta {
    display: flex;
    width: auto;
    flex-direction: column;
    align-items: center;
    gap: 60px;
    flex-shrink: 0;
}

.form__titles {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 32px;

    width: 425px;
    height: auto;
}

.titles {

    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1 0 0;
    color: var(--primary-600);
    text-align: center;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.form__titlesContainer_text {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 12px;
    width: 100%;
    height: auto;

}


.form__inputs {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 23px;
    width: auto;
    height: 146px;
}

.form__label--text {
    width: auto;
    height: auto;

    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-style: normal;

}

.form__titlesContainer_text p {
    color: var(--gray-600);
    text-align: center;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    display: flex;
    width: 374px;
    height: 45px;
    align-items: flex-start;
    gap: 10px;
    flex-shrink: 0;
}

.form__inputs {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 23px;
    width: auto;
    height: 146px;
}

.form__label--text {
    color: var(--gray-600);
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1 0 0;


}

.form__titles--text {
    width: 406.58px;
    height: 16px;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: rgba(30, 30, 28, 0.25);

}

.login__form--button {
    width: 406px;
}

.form__input--texts {
    display: flex;
    height: auto;
    padding: 10px 6px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 8px;
    border: 2px solid var(--gray-300);
    background-color: var(--white-base);
}

.form__input--texts::placeholder {

    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-style: italic;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: var(--gray-400);



}

.inputs__cont {
    display: flex;
    justify-content: center;
}

.cont__login--link {
    width: 100px;
    height: 30px;
    text-decoration: none;

    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    display: flex;
    align-items: center;
    letter-spacing: 0.005em;

    color: var(--blue-500);

}

#change__screen {
    display: none;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;

    width: 406.58px;
    height: 272px;
}


.disabled {
    display: flex;
    width: 146px;
    padding: 8px 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: var(--primary-600);
    color: var(--white-base);
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0.28px;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25);
}

.disabled:hover {
    border-radius: 8px;
    background: var(--primary-500);
    box-shadow: 0px 2px 4px 0px #D0D5DD;
}

#change__screen--final {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 23px;
    align-self: stretch;


}

.container_form_input {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}

.login__final--form {
    display: flex;
    width: 146px;
    padding: 8px 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background: var(--primary-200);
    color: var(--gray-600);
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.28px;

}

.form__inputs--image--containerIniciarSesion {
    display: flex;
    flex-direction: row;
    position: absolute;
    padding: 0px;
    margin-left: 390px;
    width: 24px;
    margin-top: 31px;
    height: 24px;
}

#name-error,
#password-error,
#email-error {
    color: #FF696A;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.24px;
}

/* @media (min-width:1024px){
    .form__label--text{
font-size: 16px;
    }
   
}
@media (min-width:1336px){
    .form__label--text{
        font-size: 16px;
            }
}
@media (min-width:1920px){
    
    .form__label--text{
        font-size: 18px;
            }
} */