@font-face {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  src: url('https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap');
}

* {
  margin: 0;
  padding: 0;
}
.bayerPerson_body_container{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.headerBuyerPerson{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
  width: 50%;
  height: 10vh; 
}
.mainBuyerPerson{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 32px;
  width: 500px;
  gap: 32px;
  flex: 1 0 0;
  filter: drop-shadow(1px 1px 9px #EDEDED);

}
.buyerPersona-title{
  color: var(--gray-600);
      font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.32px;
}

.mainBuyerPersona-title{
  color: var(--gray-600);
      font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.36px;
}


