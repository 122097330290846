.ListEnterpricesBox {
    border-radius: 6px;
    border: 1px solid var(--gray-300);
    background-color: var(--white-base);
    display: flex;
    width: 190px;
    padding: 8px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    position: absolute;
    bottom: 2px;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 280px;
}

.PopUpboxNavbarDataListEnterprices {
    border-radius: 6px;
    border: 1px solid var(--gray-300);
    background-color: var(--white-base);
    display: flex;
    width: max-content;
    padding: 8px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    position: absolute;
    height: 1em;
    top: 0;
    /* Ajusta la posición verticalmente según tus necesidades */
    left: 100px;

}

.ListEnterpricesBox.closed {
    left: 84px;
    width: 12em;
}

.ListEnterpricesBox.expanded {
    left: 267px;
    width: 16.875em;
    margin-left: 4px;
}

.ListEnterpricesBox.closed {
    width: 18em
}

.ListEnterprices, .ListEnterpricesInputs {
    display: flex;
    flex-direction: column;
    text-overflow: ellipsis;
    align-items: start;
    justify-content: start;
    width: 100%;
    border-radius: 6px  !important;
    background: transparent !important;
    height: 36px;


}

.ListEnterpricesButton {
    display: flex;
    justify-content: flex-start !important;
    width: 100% !important
}

.ListEnterpricesButton p {
    text-align: start !important;

    text-overflow: ellipsis;
    color: var(--gray-600);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 12.5px;
    /* 89.286% */
    letter-spacing: 0.28px;

}

.imagenNavEnterPrice {
    width: 1.5em;
    height: 1.5em;
}

.ListEnterprices:hover {
    border-radius: 6px !important;
    background: var(--blue-50) !important;
}
.InputListEnterpricesBox{
    border-radius: 10px;
    border: 2px solid var(--gray-300);
    outline: none;
    height: 34px;
    text-indent:6px;
    width: 98%;
    color: var(--gray-400);
    font-size: 12px;
}
.InputListEnterpricesBox::-webkit-input-placeholder{
 padding-left: 1px;
 color:2px solid var(--gray-300); ;
}
