.loaderLottieContainer{
  display: inline-flex;
  padding: 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  gap: 24px;
background-color: var(--white-base);
filter: drop-shadow(1px 1px 9px #EDEDED);
}
.containerGift{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.textGiftContainer{
display: flex;
flex-direction: column;
gap: 24px;
align-items: center;
}
.textGift{
  color: var(--gray-600);
   font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.5px; /* 162.5% */
  letter-spacing: 0.24px;
}
.textGiftBuscando{
  color: var(--gray-900);
text-align: center;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 19.5px; /* 139.286% */
letter-spacing: 0.28px;
}
.textLoaderLottie{
  color: var(--gray-900);
  text-align: center;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.5px; 
  letter-spacing: 0.28px;
}