.cardHomeEmpty{
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    width: 300px;
    padding: 24px;
    gap: 44px;
}

.homeEmptyMain{
    display: flex;
    width: 960px;
    padding: 24px 0px;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    flex: 1 0;
}
.cardHomeEmptyAuto{
    display: flex;
padding: 24px;
flex-direction: column;
align-items: center;
gap: 44px;
width: 404px;
align-self:center;
}
.homeEmptyImg{
    display: flex;
width: 252px;
height: 252px;
padding: 24px;
align-items: flex-start;
}
.homeEmptyImg img{
    width: 100%;
}
.cardHomeEmptyContent{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    align-self: stretch;
}
.cardHomeEmptyTitle{
    color: var(--gray-600);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.32px;
}
.cardHomeEmptyContentContainer{
    display: flex;
flex-direction: column;
align-items: center;
gap: 24px;
align-self: stretch;
}
.cardHomeEmptyText{
    color: var(--gray-600);
text-align: center;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 19.5px; /* 162.5% */
letter-spacing: 0.24px;
}
.cardHomeEmptyButtons{
    display: flex;
justify-content: center;
align-items: center;
gap: 24px;
}
.bntSecondaryHE{
    width: 190px;
}
.createNewBuyerPersona:hover{
    border-radius: 8px;
    background: var(--primary-500);
    box-shadow: 0px 2px 4px 0px #D0D5DD;
  }