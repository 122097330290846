@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
  margin: 0;
  padding: 0;
}
.amplify_body_container{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.headerAmplify{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
  width: 50%;
  height: 10vh; 
}
.mainAmplify{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: center;
}

@media (min-width:1440px){
  .headerAmplify{
    align-self: center;
  }
  .mainAmplify{
    align-self: center;
  }
} 

/* Generales */

.borde-radius-gris{
  border-radius: 10px;
  border: 0.5px solid var(--gray-300);
  background-color: var(--white-base);
}
.borde-inputs-generales{
  border-radius: 10px;
  border: 2px solid var(--gray-300);
  background-color: var(--white-base);
padding: 0px 8px;
}

.strong{
  color: var(--gray-800);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: 19.5px;
letter-spacing: 0.24px;
}
