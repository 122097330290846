.walkthrough {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px;
    width: 399px;
    border-radius: 20px;
background-color: var(--white-base);
filter: drop-shadow(1px 1px 9px #EDEDED);

}
.walkthrough img {
    width: 252px;
    height: 252px;
    padding: 24px;
    margin-bottom: 32px;
}
.walkthrough_text_container {
    display: flex;
    flex-direction: column;
    padding: 0px;
    gap: 14px;
    align-items: center;
    margin-bottom: 60px;
}
.walkthrough_text_container h2{
    color: var(--gray-600);
    text-align: center;
        font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.32px;
}
.walkthrough_text_container p{
    color: var(--gray-600);
    text-align: center;
        font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 19.5px; /* 150% */
    letter-spacing: 0.26px;
}
.walkthrough_btn_container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 70px;
    gap: 24px;
}
.dotsContainerW{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}
.dotsW{
    border-radius: 50%;
    width: 8px;
    height: 8px;
    background-color: var(--gray-200);
}
.dotsW.active{
    background-color: #FFA800;
}
.OmitirPrimaryWalktrough{
    color: var(--primary-500);
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
}
.buttonWalkthrough{
    display: flex;
    width: 146px;
    padding: 8px 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 26px 0px;
    border-radius: 8px;
    color: var(--white-base);
    background: var(--primary-600);
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25);
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0.28px;
    cursor: pointer;
}
.buttonWalkthrough:hover{
    border-radius: 8px;
    background: var(--primary-500);
    
    /* hover */
    box-shadow: 0px 2px 4px 0px #D0D5DD;
}
.strongW{
    color: var(--gray-600);
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: 19.5px;
letter-spacing: 0.26px;
}
@media only screen and (max-width: 1024px) {
    .glider-track {
        width: 1680px;
        height: 600px;
    }
}
@media (max-width:1224px) {
    .walkthrough {
        padding: 24px;
        gap: 24px;
    }
    .walkthrough img {
        width: 180px;
        height: 180px;
        margin-bottom: 0px;
        padding: 0px;
     
    }
    .walkthrough_text_container{
        gap: 8px;
        margin: 0px;
    }
}