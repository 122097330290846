.greyBackgroundModalAdminPopUp {
    position: fixed;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 999;
    color: var(--gray-600);
}

.greyBackgroundModalAdminPopUp input {
    border: solid 1px var(--gray-300);
    padding-left: 10px;
}

.ModalExitoDetallePopUpAdmin {

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    border-radius: 5px;
    z-index: 1000;
    overflow: hidden;
}

.amplifyModalTablaExitoPopUpAdmin,
.amplifyModalTablaExitoPopUpAdminTarget {
    width: 563px;
    display: flex;
    padding: 32px;
    flex-direction: column;
    align-items: start;
    border-radius: 20px;
    background-color: var(--white-base);
    box-shadow: 8px 8px 12px 0px rgba(168, 168, 168, 0.10);
    left: 40%;
    top: 30%;
    position: absolute;
    justify-content: space-between;

}

.amplifyModalTablaExitoPopUpAdmin {
    height: 366px;
    justify-content: space-evenly;
}

.amplifyModalTablaExitoPopUpAdminTarget {
    height: 466px;
}

.amplifyModalTablaExitoPopUpAdminTargetHeight {
    height: 300px;
}


.amplifyModalTablaExitoPopUpAdmin input,
.amplifyModalTablaExitoPopUpAdminTarget input {
    color: var(--gray-600);
}


.divPopUpAdminButtons {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.divPopUpAdminButtons button {
    margin: 1em;
}

.AdminInputBPAMPopUp,
.AdminInputPasswordPopUp {
    width: 340px;
    height: 34px;
    border-radius: 8px;

}

.amplifyModalTablaExitoPopUpAdmin p {
    margin-bottom: 5px;
}

.activeUserOrganization,
.inactiveUserOrganization {
    font-size: 12px;
}

.amplifyModalTablaExitoPopUpAdminP {
    margin-bottom: 0.3em;
}

.AdminInputBPAMPopUptext {
    font-size: 12px;
    margin-left: 1em;
    color: var(--primary-600);
}

.greyBackgroundModalAdminPopUp .AdminInputBPAMPopUpInput {
    border: 1px solid var(--primary-600) !important;
}

.error-message-admin-popupadmin {
    position: absolute;
    font-size: 10px;
    color: var(--gray-600);
    background-color: white;
    border: solid 1px var(--gray-600);
    border-radius: 1em 1em 1em 1em;
    padding: 3px;
    width: 14em;
    translate: 250% -82%;
}

.userOrganizationSelect {
    width: 10em !important
}

.EyePasswordAdmin {
    position: absolute;
    margin-left: 51%;
    margin-top: 1%;

}

.EyeAdmin {
    object-fit: contain;
    width: 25px;
    height: 25px;
}

@media (min-width:1439px) {
    .amplifyModalTablaExitoPopUpAdmin {
        left: 35%;
        top: 16%;
    }
}

@media (min-width:1921px) {
    .amplifyModalTablaExitoPopUpAdmin {
        width: auto;
        height: auto;
        left: 46%;
        top: 16%;

    }
}

@media (max-width: 1920px) {
    .ModalExitoDetallePopUpAdmin {
        height: 110vh;
        width: 118vw;

    }

}

@media (max-width: 1440px) {
    .ModalExitoDetallePopUpAdmin {
        height: 100vh;
        width: 100vw;
    }
}

@media (min-width:1919px) {
    .ModalExitoDetallePopUpAdmin {
        top: 64%;
        left: 50%;
        width: 100%;
        height: 100%;
    }
}