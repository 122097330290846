.buttonPrimaryCharts {
  width: 40px;
  height: 40px;
  display: flex;
  background: var(--primary-default);
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  box-shadow: 4px 4px 4px #DEDEDE;
  
}
.imgButtonFlotante{
  width: 24px;
  height: 24px;
}
.buttonPrimaryCharts:hover {
  background: var(--primary-hover);
  box-shadow: 4px 4px 4px #dedede;
  border-radius: 5px;
}

.buttonPrimary:active {
  background: var(--primary-pressed);
}

.buttonPrimary:disabled {
  background: var(--primary-disable);
  border: 1px solid var(--primary-default);
  border-radius: 5px;
}
.noLine {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 7.2px 14.4px;
  gap: 4px;
  width: 91.8px;
  height: 44.4px;
  background: var(--gray-white);
  border-radius: 5px;
  font-weight: 400;
  font-size: 20px;
  border: none;
}
