@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
    margin: 0;
    padding: 0;
}

.amplifyModalTablaNoCreditsGeneral {
    background-color: var(--white-base);
    position: relative;
    top: 17vh;
    display: flex;
    width: 384px;
    height: 100%;
    gap: 40px;
    padding: 16px;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    border: 1.5px solid var(--gray-300);
    filter: drop-shadow(1px 1px 9px #EDEDED);


}

.amplifyModalTablaNoCreditsContainer {
    display: flex;
    padding: 0px 24px;
    flex-direction: column;
    justify-content: start;
    align-self: stretch;
    gap: 12px;

}

.amplifyModalTablaNoCredits {
    display: flex;
    padding: 0px 24px;
    gap: 4px;
    align-items: center;
}

.amplifyModalTablaNoCredits img {
    width: 24px;
    height: 24px;
}

.amplifyModalContentImg {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
}

.modalNoCreditsText {
    color: var(--gray-600);
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    ;

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.24px;
    width: 356px;
}

.amplifyModalTablaNoCreditsContainer .amplifyModalTablaNoCredits h2 {
    color: var(--gray-600);
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.32px;

}

.ConfirmacionBotonesNoCreditsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ConfirmacionBotonesNoCredits {
    display: flex;
    gap: 16px;
    align-self: center;
    width: 146px;
    height: 38px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 8px;
    background: var(--primary-600);
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25);
    color: var(--white-base);
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    ;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0.28px;
}

.ConfirmacionBotonesNoCredits:hover {
    border-radius: 8px;
    background: var(--primary-500);
    box-shadow: 0px 2px 4px 0px var(--gray-300);
}

.imgModalExitoNoCredits {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background: var(--success-25);
    background-color: var(--warning-50);
    padding: 0px;
    width: 388px;
    height: 220px;
}

.imgModalExitoNoCredits img {
    object-fit: cover;
    width: 234px;
    padding: 0px;
    height: 250px;

}