* {
    margin: 0;
    padding: 0;
}

.ChartsGraphics {
    flex-direction: column;
    align-items: flex-start;
    padding: 32px;
    gap: 32px;
    width: 100%;
    height: auto;
    background: var(--gray-white);
    border-radius: 8px;
    background-color: var(--white-base);

}




.barsBenchamarkGenero {
    display: flex;
    width: 100%;
    height: 12em;
    justify-content: center;
    padding: 16px;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    border-radius: 10px;
    background: var(--gray-100);
}

.ChartsGraphicsContainer {
    width: 100%;
    height: auto;
    display: flex;
    gap: 32px;
    flex-direction: column;
    align-items: flex-start;

}

.ChartsGraphicsTitle {

    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;

    display: flex;
    align-items: center;
    text-transform: uppercase;

    color: var(--gray-600);


}

.ChartsGraphicsText {

    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    width: 100%;
    height: auto;
    color: var(--gray-600);
}