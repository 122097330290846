* {
    margin: 0;
    padding: 0;
}

.ChartsGraphicsCL {
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 40px 40px 40px;
    gap: 24px;
    /* width: 100%; */
    height: auto;
    border-radius: 10px;
    border: 1px solid var(--gray-300);
    background-color: var(--white-base);
    /* box-shadow: 1px 1px 9px 0px #EDEDED; */
    max-width: 956px;

}
.barsBenchamarkGenero {
    display: flex;
    width: 94%;
    justify-content: center;
    
}
.barsBenchamarkGeneroPropio{
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    border-radius: 10px;
}
.ChartsGraphicsContainerCL {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 12px;
}
.ChartsGraphicsContainerCL h2 {
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

    font-size: 18px;
    font-weight: 600;
    color: var(--gray-600);
    line-height: normal;
    letter-spacing: 0.28px;
    font-style: normal;
    display: flex;
    align-items: center;
}

.ChartsGraphicsContainerCL p {
    color: var(--gray-600);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 19.5px; 
    letter-spacing: 0.24px;
    height: auto;    
}
