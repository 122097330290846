.amplifyCardTabla{
  display: flex;
  flex-direction: column;
  gap: 16px;
  /* width: 805px; */
}
.amplifyTitleTabla{
  color: var(--gray-500);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.28px;
  padding: 0px 16px;
}
.amplifyTabla{
  display: flex;
  width: 884px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  border-radius: 20px;
  border: 1.5px solid var(--gray-300);
}
.amplifyTablaThead{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 20px 20px 0px 0px;
  background: var(--gray-50);
}

.amplifyTablaTbody tr{
  width: auto;
  height: 15.5px;
  display: flex;
  justify-content: space-around;
  padding: 16px;
}
.amplifyTablaThead tr{
  display: flex;
}
.amplifyTablaRow:first-child {
  border-top: 0.5px solid var(--gray-300);
}
.amplifyTablaRow:not(:last-of-type) {
  border-bottom: 0.5px solid var(--gray-300);
}
.amplifyTablaThead th,
.amplifyTablaTbody td{
  display: flex;
  align-items: center;
  align-content: center;
  /* justify-content: space-between; */
  height: 48px;
  padding: 0px 16px;
  align-self: center;
}
.amplifyTablaHead {
  display: flex;
  /* padding: 10px 16px; */
  align-items: stretch;
  justify-content: space-between;
}
.amplifyTablaHead,
.amplifyTablaTbody p{
  color: var(--gray-500);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  font-size: 12px;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.24px;
}
.amplifyTablaHead{
  font-weight: 600;
}
.amplifyTablaFilaComuna{ 
  font-weight: 500!important;
}
.amplifyTablaFilaComuna::first-letter{
  text-transform: uppercase;
}

.amplifyTablaTbody p{
  font-weight: 400;
} 
.amplifyTablaTbody {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  /* margin-bottom: 32px; */
}
.amplifyTablaTbody input{
  display: flex;
  align-items: center;
  outline-color: var(--primary-500);
  width: 105px;
  height: 32px;

  color: var(--gray-500);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px; /* 125% */
  letter-spacing: 0.24px;
}
.input-container {
  position: relative;
}
.warning-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
}
.amplifyTablaFila.Comuna{
  min-width: 173px;
  max-width: 230px;
}
.amplifyTablaFila.BP{
  min-width: 92px;
  max-width: 124px;
  justify-content: center;
}
.amplifyTablaFila.Genero{
  min-width: 78px;
  max-width: 110px;
  justify-content: center;
}
.amplifyTablaFila.Estado{
  min-width: 75px;
  max-width: 107px;
  justify-content: center;
}
.amplifyTablaFila.Encontrado{
  min-width: 79px;
  max-width:111px ;
}
.amplifyTablaFila.Solicitado{
  padding: 6px 16px;
 min-width: 170px;
 max-width: 202px;
 height: 36px;
}
.amplifyTablaButtons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  background-color: var(--white-base);
  width: 100%;
  border-radius: 0 0 20px 20px;
}
.amplifyTablaButtons button {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  border: none;
  border-radius: 8px;
  background-color: var(--white-base);
  cursor: pointer;
}

.amplifyTablaButtons button img {
  width: 20px;
  height: 20px;
}
.amplifyTablaButtons button:hover {
  background-color: var(--primary-100); 
}
.page-link{
  cursor: pointer;
}
.amplifyTablaPaginacion {
  display: flex;
  width: 20px;
  justify-content: center;
  align-items: center; 
  color: var(--gray-600);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.5px; 
  letter-spacing: 0.26px;
}
.amplifyTablaRow.selected-row {
  background-color: var(--primary-50);
}
.dimmed-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); 
  z-index: 1000; 
  pointer-events: none; 
}
.iconEstado{
width: 15px;
height: 15px;
}
@media (min-width:1440px) {
  .amplifyTabla{
    width: 805px;
  }
  .amplifyTablaTbody input{
    width: 71px;
  }
  .amplifyTablaFila.Solicitado{
    min-width: 91px;
    max-width: 123px;
    justify-content: center;
  }
}

