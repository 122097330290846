.acordeonListContainer ul{
list-style:lower-alpha;
display: flex;
padding: 12px 16px;
flex-direction: column;
align-items: flex-start;
gap: 10px;
align-self: stretch;
}
.acordeonListContainer ul li{
    color: var(--gray-600);
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


font-size: 12px;
font-style: normal;
font-weight: 300;
line-height: 19.5px; 
letter-spacing: 0.26px;
}