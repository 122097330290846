.recuperoExitosoContainer{
   display: flex;
width: auto;
height: 100vh;
padding: 10px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 10px;
}
.recuperoExitosoCard{
    display: flex;
width: 438px;
height: 510px;
padding: 32px 32px 40px 32px;
flex-direction: column;
align-items: center;
gap: 37px;
flex-shrink: 0;
border-radius: 20px;
background-color: var(--white-base);
box-shadow: 8px 8px 12px 0px rgba(168, 168, 168, 0.10);
}

.recuperoExitosoTexto{
    display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 19px;
}
.recuperoExitosoTexto h1{
    color: var(--primary-600);
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.32px;

}
.recuperoExitosoTexto p{
    color: var(--gray-600);
text-align: center;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 19.5px; 
letter-spacing: 0.26px;
}
.recuperoExitosoButton{
    display: flex;
width: 146px;
padding: 8px 12px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 8px;
background: var(--primary-600);
color: var(--white-base);
font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 21px; 
letter-spacing: 0.28px;
box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25);
}
.recuperoExitosoButton:hover{
    border-radius: 8px;
background: var(--primary-500);
box-shadow: 0px 2px 4px 0px #D0D5DD;
}