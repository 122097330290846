@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

a {
  text-decoration: none;
}

.automaticoTexts {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.amplifyPrincipalContainer {
  display: flex;
  flex-direction: column;
  align-self: flex-start;


}

.amplifyTabs {
  display: flex;
  /* gap: 4px; */
}

.amplifyTabs button,
.amplifyTabs button.tabsActive {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 10px 10px 0px 0px;
  border-top: 0.5px solid var(--gray-300);
  border-right: 0.5px solid var(--gray-300);
  border-left: 0.5px solid var(--gray-300);
  background: var(--gray-50);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.amplifyTabs button {
  background: var(--gray-50);
  color: var(--gray-600);
}

.amplifyTabs button.tabsActive {
  background-color: var(--white-base);
  color: var(--primary-500);
}

.amplifyContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 32px;
  gap: 24px;
  border-radius: 0px 0px 10px 10px;
  border: 0.5px solid var(--gray-300);
  background-color: var(--white-base);
  width: 1107px;
  filter: drop-shadow(1px 1px 9px #EDEDED);

}

.amplifyContainerExtend {
  width: 1232px;
  padding: 24px 32px;
}

.automaticoContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
}

.automaticoContainer h1,
.automaticoContainerText {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  font-style: normal;
}

.automaticoContainer h1 {
  color: var(--gray-600);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.32px;
}

.automaticoContainerText {
  font-size: 12px;
  font-weight: 400;
  line-height: 19.5px;
  /* 139.286% */
  letter-spacing: 0.28px;
}

.solicitudBuyerPersonaAutomatico {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  width: 100%;
}

.solicitudBPAutomaticoExtend {
  width: 1232px;
}

.containerInputAmplify {
  display: flex;
  /* padding: 10px 6px; */
  flex-direction: column;
  justify-content: center;

  align-self: stretch;
  gap: 8px;
  width: 100%;
}

.containerInputAmplify select {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 38px;
  padding: 0px 8px;
  border-radius: 8px;
  border: 2px solid var(--gray-300);
  background-color: var(--white-base);
  color: var(--gray-600);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.26px;
}

.containerInputAmplify select:focus {
  border-radius: 10px;
  border: 2px solid var(--primary-400);
  background-color: var(--white-base);
}

.Confirmacion_botones {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}

.containerInputAmplify option {
  border-radius: 8px;
  background-color: var(--white-base);
  box-shadow: 6px 2px 20px 0px rgba(172, 172, 172, 0.25);
  color: var(--gray-600);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 19.5px;
  /* 150% */
  letter-spacing: 0.26px;
}

.containerInputAmplify p {
  padding: 0px 6px;
  color: var(--gray-600);
  font-size: 12px;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  font-weight: 400;
}

.contenedorBotonesAmplify {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}



.buyerPersonaCardContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 30%;
}

.buyerPersonaCardContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
}

.buyerPersonaCardTextoPerfiles {
  display: flex;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}

.amplifyDesplegableContainer {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 20px;
  border: 1.5px solid var(--gray-300);
  background-color: var(--white-base);
  width: 1060px;
  align-self: center;
}

.cardCantidadPerfiles {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 12px;
  border: 0.5px solid var(--gray-300);
  background-color: var(--white-base);
  width: 1012px;
}

.cardCantidadPerfilesHeader {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.cardCantidadPerfilesHeader p {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  font-size: 12px;
  font-style: normal;
  line-height: 19.5px;
  letter-spacing: 0.26px;
}

.cardCantidadPerfilesHeader h2 {
  color: var(--gray-600);
  font-weight: 600;
  font-size: 16px;
}

.cardCantidadPerfilesHeader p {
  color: var(--gray-600);
  font-size: 12px;
  font-weight: 400;
  line-height: 19.5px;
  /* 139.286% */
  letter-spacing: 0.28px;
}

.inline-paragraph {
  display: inline;

}

.TextSecondPerfiles {
  color: var(--gray-600);
  font-weight: 400;
}

.cardContainerDatosEcoGen {
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  gap: 24px;
}

.cardCantidadPerfilesBody,
.cardDatosEcoGeo {
  display: flex;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;

  width: 999px;
}

.cardDatosEcoGeo {
  flex-direction: column;
}

.cardContainerDatosGeo {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}

.Confirmacion_botones {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}

@media (max-width:1439px) {

  .cardDatosEcoGeo {
    flex-direction: column;
  }

  .buyerPersonaCardContent {
    width: 100%;
  }

  .amplifyContainerExtend {
    width: 1080px;
  }
}

@media (min-width:1441px) {
  .amplifyContainer {
    width: 1107px;
  }

  .amplifyContainerExtend {
    width: 1232px;
    padding: 24px 32px;
  }

  .solicitudBPAutomaticoExtend {
    width: 1232px;
  }
}

/* .css-13cymwt-control, .css-t3ipsp-control, .css-1osikpm-container, .css-1nmdiqa5-menu{
  width: 20em !important;
} */
@media (min-width:1920px) {
  .cardCantidadPerfiles {
    width: 1012px;

  }
}

.css-19bb58m {
  margin: 0px !important;
  padding: 0px !important;
  padding-left: 8px !important;
}

.css-hlgwow {
  padding: 0px 0px !important;

}

.selectBox .input-container.css-1cfo1cf {
  height: 10px !important;
}

.selectBox__input {
  font-size: 12px !important;

}

.css-1wy0on6 {
  box-sizing: inherit;
}

.css-1cfo1cf {
  min-height: 5px !important;
  max-height: auto !important;
  display: flex !important;
  align-items: center !important;
}

@media (min-width:1920px) {
  .anchoInputAA {
    width: 523px;
  }

  .selectBox__control {
    width: 523px !important;
  }
}

/* Estilos para el cuadro de selección */
.custom-select {
  width: 154px;
  height: 32px;
}

/* Estilos para el contenedor del cuadro de selección */
.custom-control {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  align-self: stretch;
  cursor: pointer;
  height: 38px;
  min-height: 24px;
  border-radius: 8px;
  color: #707477;
   font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;



  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0.24px;
}

/* Estilos para el valor seleccionado */
.custom-value {
  color: #707477;
  background-color: #F0F0F0;
}

/* Estilos para las opciones */
.custom-option {
  color: #0C0C0F;
   font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;



  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 12.5px;
  letter-spacing: 0.2px;
}

/* Estilos para la opción cuando está enfocada */
.custom-option:focus {
  background-color: #f0f0f0;
}

/* Estilos para la opción cuando está seleccionada */
.custom-option:selected {
  color: white;
  background-color: #F18F49;
}