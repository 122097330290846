

.popUpBP {
  display: flex;
  width: 130px;

  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  border-radius: 8px;
  background-color: var(--white-base);
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.10);
  transform: translate(-50%, -50%);
}
.popUpBPContent, .popUpBPContentDisabled{
  display: flex;
  height: 32px;
  align-items: center;
  padding: 0px 8px;
  gap: 3px;
  align-self: stretch;
  cursor: pointer;
}
 
.popUpBPContent span{
  color: var(--secondary-gray-600, #475467);
   font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;



  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.popUpBPContentDisabled span{
  color: var(--gray-400);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.24px;
}
.PopUpVisualizar{
  width: 22px;
  height: 22px;
}
.PopUpDescargar{
width: 24px;
height: 24px;
}
.PopUpTrash{
  width: 24px;
height: 24px;
}
.IconEliminar{
  margin-left: 2px;
  margin-right: 4px;
}
.rotatingSVG {
  display: inline-block;
  animation: rotate 2s linear infinite;
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.popUpBPContent
.popUpBPContentDesactivado{
color: var(--gray-400);
}
.popUpBPContent:hover{
background:var(--gray-100);
padding: 0px 8px;
border-radius: 6px;
}