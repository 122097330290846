.login__container {
    margin-top: 5em;
}

.login__formRecupero {
    display: flex;
    width: auto;
    height: auto;
    padding: 32px 32px 40px 32px;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    flex-shrink: 0;
}

.form__inputsRecupero {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
}

.form__titles--Fp {
    font-weight: 600;
    font-size: 1vw;
    margin-bottom: 10px;
}

#mostrar__screen--Fp {
    display: none;
}

#change__screen--Fp {
    display: none;
    width: 100%;
    flex-direction: column;
    font-size: 1.2vw;
}

.cont__login--link {
    width: 100%;
    justify-content: center;
    height: 30px;
    text-decoration: none;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    display: flex;
    align-items: center;
    letter-spacing: 0.005em;
    color: var(--blue-500);
}

.strongRecupero {
    color: var(--gray-600);
    text-align: center;
     font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 19.5px;
    letter-spacing: 0.26px;
}

.form__second__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    align-self: stretch;
}

.form__titles--textRecupero {
    width: 374px;
    height: 59px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    align-self: stretch;
    color: var(--gray-600);
    text-align: start;
     font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 19.5px;
    letter-spacing: 0.26px;
}

.form_titlesRecupero {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
    align-self: stretch;
    width: 100%;
    height: auto;
}

.form__input--textsRecupero {
    display: flex;
    height: auto;
    width: 100%;
    padding: 10px 6px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 8px;
    border: 2px solid #E2E7F1;
    background-color: var(--white-base);
}

.fp__btn {

    display: flex;
    width: 146px;
    padding: 8px 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background: #FFEFE0;
    color: var(--gray-600);
     font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.28px;
}

#btns__final {
    display: none;
    flex-direction: column;
    justify-content: space-around;
    gap: 20px;
}

.fp__btn--enviar {
    display: flex;
    width: 146px;
    padding: 8px 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background: #FFEFE0;
    color: var(--gray-600);
     font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.28px;
}




#screen__Fp--final {
    display: none;
}

#screen__Fp {
    display: none;
}

#Fp__Final {
    display: none;

    flex-direction: column;
    gap: 16px;

    width: 100%;
    height: auto;

}


.fp__btn--confirm {
    display: none;
    width: 146px;
    padding: 8px 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background: #FFEFE0;
    color: var(--gray-600);
     font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.28px;
}

.Fp__FinalContraseña {
    width: 100%;
    gap: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.fp__btn--enviar--codigo {
    display: flex;
    width: 200px;
    height: 3em;
    padding: 8px 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background: var(--primary-600);
    color: var(--white-base);
     font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.28px;

}

.login__formRecupero--password {
    display: flex;
    width: auto;
    height: auto;
    padding: 0px 32px 0px 32px;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    flex-shrink: 0;
}

.fp__btn--div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.fp__btn--div p {
    margin-top: 1em;
    color: var(--gray-600);
    font-size: 14px;
}

.btns__final {
    display: none;
    flex-direction: column;
    align-items: center;
}

.btns__final p {
    margin-top: 1em;
    color: var(--gray-600);
    font-size: 14px;
}

.login__container input:focus-visible {
    border-radius: 10px;
    border: 2px solid var(--primary-400);
    background-color: var(--white-base);
    outline: none;
    height: auto !important;
}

.error__message--forgotPassword {
    font-size: 12px;
    color: var(--gray-600);
    padding-left: 2px;
}