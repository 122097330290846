@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
* {
  margin: 0;
  padding: 0;
}
.LoginForgotPasswordText{
  font-size: 12px;
  margin-bottom: 1em;
  padding: 0px;
  color: var(--primary-500);
  text-decoration: underline;
 cursor: pointer;
}
.login {
    display: flex;
width: auto;
flex-direction: column;
justify-content: center;
align-items: center;
}

.login__header{
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 10vh;
}
.login__container {
  display: flex;
  width: auto;
  padding: 32px;
  gap: 40px;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
background-color: var(--white-base);
filter: drop-shadow(1px 1px 9px #EDEDED);
}

.login__form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
}

.login__isologo {
  width: 160px;
  height: 36px;
}

.form__inputs_inicioSesion {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.inputs__password {
  display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}

.form__inputs--image--container {
  display: flex;
  flex-direction: row;
  position: absolute;
  padding: 0px;
  margin-left: 271px;
  width: 24px;
  margin-top: 23px;
  height: 24px;
}

.form__inputs--image {
  width: 24px;
  height: 24px;
}

input:focus-visible {
  border-radius: 10px;
  border: 2px solid var(--primary-400);
  background-color: var(--white-base);
  outline: none;
  height: 34px;
}



.form__inputs--text, .form__inputs--text-login {
  display: flex;
  height: 35px;
  width: 94%;
  padding: 0px 8px;
  align-items: center;
  border-radius: 6px;
  border: 2px solid var(--gray-300);
  background: var(--gray-25);
}


.form__inputs--text::placeholder, .form__inputs--text-login::placeholder {
  color: var(--gray-400);
      font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.24px;
}

.form__inputs--password {
  display: flex;
  height: 35px;
  padding: 0px 8px;
  align-items: center;
  width: 94%;
margin-top: 4px;
border-radius: 6px;
border: 2px solid var(--gray-300);
background: var(--gray-25);
}

.form__inputs--password::placeholder {
  color: var(--gray-400);
      font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.24px;
}

#rc-anchor-container {
  display: flex !important;
  flex-direction: row;
  align-items: center;
  padding: 16px 24px;
  gap: 32px;

  width: 426px !important;
  height: 65px !important;

  background: rgba(233, 236, 234, 0.25) !important;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.1) !important;
  border-radius: 5px;
}

.form__inputs--link {
  display: flex;
  width: 100%;
  height: 25px;
  flex-direction: column;
  justify-content: center;
  color: var(--blue-50);
      font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.login__form h1 {
  width: auto;
  height: auto;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: var(--gray-600);
}

.form__titles--account {
  width: 425px;
  height: 24px;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  /*  text-transform: uppercase; */
  color: var(--gray-600);
}

.login-form__titles--text {
  width: 425px;
  height: 24px;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--gray-600);
}

.disabled {
  display: flex;
  width: 146px;
  padding: 8px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  background: var(--primary-200);
  color: var(--white-base);
      font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.28px;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25);
}


.form__inputs--newaccounts {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 20px;
  width: auto;
  height: 30px;
  justify-self: center;
}

.inputs__newaccounts--signup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.inputs__newaccounts--signup p {
  color: var(--gray-600);
      font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.5px;
  letter-spacing: 0.26px;
}
.login__contenido {
  display: flex;
  justify-content: center;
  height: 90vh;
  width: 100%;
  align-items: flex-start;
}

.inputs__newaccounts--link {
  color:  #5a57ff;
      font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
}

.inputs__newaccounts--span {
  height: 1px;
  width: 65px;
  background-color: #0c0c0f;
}

.form_container {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
}

.form__checkbox {
  display: flex;
  padding: 0px 16px;
  align-items: center;
  gap: 6px;
  align-self: stretch;
  height: 20px;
}

.form_checkbox_box {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 14px;
  height: 14px;
  border: 1px solid #d1d1d6;
  border-radius: 4px;
}

.form__checkbox p {
  color: var(--gris-textos, var(--gray-400));
      font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
}

.form_checkbox_box:checked {
  border-radius: 4px;
  cursor: pointer;
  background-color: var(--primary-500);
  background-image: url(./Pathchecks.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
/*   width: 24px;
  height: 24px; */
  appearance: none;
  border: 2px solid var(--primary-500);
  padding: 0px;
}

.login__form__titles--text {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: var(--gray-600);
}

.login__form__titles--text {
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: var(--gray-600);
}

.login__form--buttonIniciarSesion {
  display: flex;
  width: 146px;
  padding: 8.5px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: var(--primary-200);
  color: var(--gray-400);
      font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.28px;
}

.login__image {
  background-image: url(Pathchecks.svg);
  padding: 24px;
  justify-self: center;
  width: 100%;
  height: 620px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.form__inputs--guard {
  margin: 0px;
}
.form__inputs__email label {
  color: var(--gray-600);
      font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
padding: 0px 6px;
align-items: flex-start;
gap: 10px;
}
.inputs__password__container label {
  color: var(--gray-600);
      font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
padding: 0px 6px;
align-items: flex-start;
gap: 10px;

}
.inputs__password__container .form__inputs--text-login:focus-visible, .form__inputs__email .form__inputs--text-login:focus-visible, .form__inputs__email input:focus-visible{
  height: 35px !important;
}

@media (min-width: 1024px) {
  .inputs__newaccounts--signup {
    font-size: 16px;
  }
}


@media only screen and (max-width: 1024px) {
  #captcha {
    transform: scale(0.9);
    display: flex;
    justify-content: inherit;
    margin-left: 0px;
  }
}

.form__inputs__email {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
}


.inputs__password__container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  
  align-self: stretch;
}


@media (max-width: 768px) {
  .login__isologo{
    width: 106px;
height: 24px;
  }
  .login__container {
    display: flex;
    width: auto;
    padding: 24px;
    flex-direction: column;
    /* justify-content: space-between; */
    align-items: center;
    gap: 40px;
    background-color: var(--white-base);
  }
  .login__form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
  }
  .form__inputs_inicioSesion {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
  }
  .form__inputs__email {
    gap: 4px;
}
  .form__inputs__email label{
    color: var(--gray-600);
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: normal;
display: flex;
    padding: 0px 8px;
    align-items: flex-start;
    gap: 10px;
  }
  .form__inputs--password::placeholder{
    color: var(--gray-400);
        font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


    font-size: 12px;
    font-style: normal;
    font-weight:300;
    line-height: normal;
    letter-spacing: 0.2px;
  }
  .inputs__password__container label{
    color: var(--gray-600);
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: normal;
display: flex;
    padding: 0px 8px;
    align-items: flex-start;
    gap: 10px;
  }
  .form__checkbox p{
    color: var(--gray-600);
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


font-size:   11px;
font-style: normal;
font-weight: 300;
line-height: normal;
letter-spacing: 0.16px;
  }
  .form__inputs--text, .form__inputs--text-login {
    display: flex;
    height: 0px;
    width: 94%;
   padding: 15px 8px;
    justify-content: space-between;
    align-items: center;
    border-radius: 6px;
    border: 2px solid var(--gray-300);
    background-color: var(--white-base);
  }
 

  .form__inputs--text::placeholder, .form__inputs--text-login::placeholder{
    color: var(--gray-400);
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


font-size: 12px;
font-style: normal;
font-weight: 300;
line-height: normal;
letter-spacing: 0.2px;
  }

  .inputs__password__container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
    width: 100%;
  }
  .form__inputs--password {
    display: flex;
    height: 0px;
    width: 94%;
    padding: 15px 8px;
    justify-content: space-between;
    align-items: center;
    border-radius: 6px;
    border: 2px solid var(--gray-300);
    background-color: var(--white-base);
  }
  .form__inputs--image--container {
    margin-left: 275px;
    margin-top: 26px;
}
  .form_checkbox_box {
    width: 14px;
    height: 14px;
  }
  .form__inputs--image {
    width: 20px;
    height: 20px;
}
  .login__isologo {
    width: 106.665px;
    height: 24px;
  }
  .form_container {
    gap: 8px;
  }
  .form_checkbox_box:checked{
width: 20px;
height: 20px;
  }
  .form__checkbox {
    display: flex;
    padding: 0px 8px;
    align-items: center;
    gap: 6px;
    align-self: stretch;
    height: 16px;
}

.login__form--buttonIniciarSesion{
  display: flex;
  height: 38px;
  padding: 8px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}
}


