@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
* {
    margin: 0;
    padding: 0;
}

.DashboardTitle {
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',  sans-serif;

    font-style: normal;
    font-weight: 300;
    font-size: 26px;
    line-height: 30px;
    display: flex;
    align-items: center;
    letter-spacing: 0.015em;
    color: var(--gray-600);
}

.DashboardTitle span {
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 30px;
    display: flex;
    align-items: center;
    letter-spacing: 0.015em;
    color: var(--gray-600);
    margin-left: 5px;
}

.DashboardTitleTwo {
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    display: flex;
    align-items: center;
    letter-spacing: 0.015em;
    color: var(--gray-600);
}

.dobleDoughnut {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
    width: 100%;
    height: 100%;
}

.dobleDoughnutdiv {
    display: flex;
    flex-direction: column;
    padding: 0px;
    gap: 32px;
    width: 100%;
    height: 100%;
}

.chartDataNseText {
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    color: var(--gray-600);
    justify-content: center;
}

.dobleDoughnutContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    margin-bottom: 32px;
    width: 582.5px;
    height: 88px;
}

.dobleDoughnutContent p {
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    min-height: 72px;
    align-items: center;
    color: var(--gray-600);
}

.dobleDoughnutContent h3 {
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: var(--gray-600);
}

.barGender {
    padding: 0px;
}

.buyerPerson {
    display: flex;
    justify-content: center;
}




.statusCarga {
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    color: var(--gray-600);
    padding: 20px;
    text-align-last: center;
    margin-bottom: 1vw;
}

.containerChartsh3 {
    font-size: 20px;
    font-weight: 400;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    margin: 2vw 4vw;
}

.barsBenchamarkGeneroConTitulo h3 {
    margin: 0px;
    padding: 0px;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: var(--gray-600);
    width: 100%;
}

.ChartsGraphicsText {
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    align-items: center;
    color: var(--gray-600);
}

.barTitle .ChartsGraphics {
    padding: 0px;
    background-color: var(--gray-100)!important;
}

.barTitle .ChartsGraphicsMale {
    padding: 0px;
}

.buyerPerson .doughnut-chart {
    width: 90%;
    margin-top: 32px;
}


.dobleDoughnutContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px;
    gap: 32px;
    width: 100%;
    height: 100%;
    background-color: var(--white-base);
    border: 1px solid #e2e7f1;
    border-radius: 8px;
}

.dobleDoughnutContentText {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 32px;
    width: 100%;
    height: 100%;
}

.dobleDoughnutText {
    width: 100%;
    height: 100%;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    align-items: center;
    color: var(--gray-600);
}

.chartsSelect {
    border: 1px solid #e2e7f1;
    border-radius: 4px 4px 0px 0px;
    margin-bottom: 18px;
}

.dobleSpineChartsWithTitleReverse {
    display: flex;
    justify-content: center;
    flex-direction: row-reverse;
    margin-top: 32px;
}

.dobleSpineChartsWithTitleReverse h3 {
    padding: 16px 17px 1px 0px;
}

.dobleSpineChartsContainerWithTitle {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px;
    gap: 32px;
    width: 100%;
    height: 100%;
    background-color: var(--white-base);
    border: 1px solid #e2e7f1;
    border-radius: 8px;
}

.dobleDoughnutdivWithTitle {
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 8px;
    gap: 16px;
    width: 100%;
    align-self: center;
    height: 100%;
}

.barDivContainer {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.barDivContainerGraph {
    width: 100%;
}

.dobleDoughnutContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
    width: 100%;
    height: auto;
}

.dobleDoughnutContenth3 {
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: var(--gray-600);
}

.dobleDoughnutContent p {
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    align-items: center;
    color: var(--gray-600);
}


.dobleSpineChartsWithTitle {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    width: 100%;
    height: auto;
}

.educationContainerText {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    width: 100%;
    height: auto;
}

.educationContainerText h3 {
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: var(--gray-600);
}

.dobleSpineChartsContainerText {
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    align-items: center;
    color: var(--gray-600);
}

.containerCharts {
    display: flex;
    width: 100%;
}

.dashboardVerticalMenuContainer {
    display: flex;
    flex-direction: column;
}

.verticalMenu {
    position: fixed;
    width: 93%;
    right: 0px;
    background-color: var(--white-base);
    z-index: 3;
}


.Dashboard {
    width: 85%;
    /* margin-left: 32px; */
  
    display: flex;
    flex-direction: column;
    height: auto;
    align-items: flex-start;
    padding-top: 32px;
    padding-right: 0px;
    padding-bottom: 0px;
    gap: 32px;
    align-self: center;
}

/* .caracteristicasSocioDemograficas {
    border: 1px solid #e2e7f1;
    align-self: center;
    width: 100%;
} */

.buttonContainer {
    display: flex;
    justify-content: flex-end;
    margin-top: 32px;
    margin-bottom: 32px;
    position: fixed;
    left: 96%;
    top: 90%;
}

.chartsSelectContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 32px;
    margin-top: 32px;
    width: 100%;
    height: auto;
}

.chartsSelect {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: center;
    padding: 8px;
    width: 100%;
    height: 40px;
    background-color: var(--white-base);
    border: 1px solid #e2e7f1;
    border-radius: 4px;
}

.chartsSelectTwo {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: center;
    padding: 8px;
    width: 50%;
    height: 40px;
    background-color: var(--white-base);
    border: 1px solid #e2e7f1;
    border-radius: 4px;
}

.boxErrorOne {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    align-items: center;
}

.boxErrorOne div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    height: 100vh;
    margin: auto;
    padding: auto;
    justify-content: center;
    gap: 32px;
}

.seleccionarMunicipio {
    padding: 8px 0px;
    align-self: flex-start !important;
}

.educationContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 32px;
    width: 100%;
    height: 100%;
}

.consumoContainer, .dobleDoughnutdivCLTextAndTitleCL {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 12px;
    width: 100%;
    height: auto;
}

.barsBenchamarkGeneroConTitulo {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 32px;
    width: 100%;
    height: 100%;
}

.barsBenchamarkGeneroConTitulo h3 {
    margin: 0px;
    padding: 0px;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: var(--gray-600);
    width: 100%;
}



.material_block {
    width: 580px;
    padding: 20px;
    background-color: var(--white-base);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
    margin: auto;
}

.spinner {
    -webkit-animation: rotation 1.35s linear infinite;
    animation: rotation 1.35s linear infinite;
}

@-webkit-keyframes rotation {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(270deg);
        transform: rotate(270deg);
    }
}

@keyframes rotation {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(270deg);
        transform: rotate(270deg);
    }
}

.circle {
    stroke-dasharray: 180;
    stroke-dashoffset: 0;
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
    -webkit-animation: turn 1.35s ease-in-out infinite;
    animation: turn 1.35s ease-in-out infinite;
}

@-webkit-keyframes turn {
    0% {
        stroke-dashoffset: 180;
    }
    50% {
        stroke-dashoffset: 45;
        -webkit-transform: rotate(135deg);
        transform: rotate(135deg);
    }
    100% {
        stroke-dashoffset: 180;
        -webkit-transform: rotate(450deg);
        transform: rotate(450deg);
    }
}

@keyframes turn {
    0% {
        stroke-dashoffset: 180;
    }
    50% {
        stroke-dashoffset: 45;
        -webkit-transform: rotate(135deg);
        transform: rotate(135deg);
    }
    100% {
        stroke-dashoffset: 180;
        -webkit-transform: rotate(450deg);
        transform: rotate(450deg);
    }
}

@-webkit-keyframes loader1 {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes loader1 {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@media only screen and (max-width: 1305) {
    .verticalMenu {
        width: 94%;
    }
}

.dobleSpineChartsContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 32px;
    width: 100%;
    height: 100%;
}

.dobleDoughnutdivTextAndTitle {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
    width: 100%;
    height: auto;
}

.dobleDoughnutdivTextAndTitle h3 {
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: var(--gray-600);
}

.dobleDoughnutdivTextAndTitle p {
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    align-items: center;
    color: var(--gray-600);
}

.selectMunicipioContainer {
    width: 100%;
}

.barTitle {
    margin-top: 2em;
    display: flex;
    flex-direction: column;
    width: 80%;
}

.verticalBar {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 32px;
    gap: 32px;
    width: 100%;
    height: 100%;
    background-color: var(--white-base);
    border: 1px solid #E2E7F1;
    border-radius: 8px;
}

.verticalBarText {
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.verticalBar h2 {
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: var(--gray-600);
}

.verticalBar p {
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: var(--gray-600);
}

.finalGraph {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 32px;
    gap: 32px;
    /* width: 100%; */
    height: auto;
    background-color: var(--gray-600)!important;
    border: 1px solid #E2E7F1;
    border-radius: 8px;
}

.finalGraphText {
    display: flex;
    gap: 5px;
    flex-direction: column;
}

.buyerPersonText {
    display: flex;
    flex-direction: column;
    gap: 32px;
}

button {
    background: none;
    border: none;
}
.apexcharts-legend-marker {
    border-radius: 50% !important;
}



.divDoughnutGénero {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    width: auto;
    height: auto;
}

.divDoughnutGénero h3 {
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: var(--gray-600);
}

.divDoughnutGénero p {
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    align-items: center;
    color: var(--gray-600);
}



.dobleDoughnutdivContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    width: auto;
    height: auto;
}

.dobleDoughnutdivContent h3 {
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: var(--gray-600);
}

.dobleDoughnutdivContent p {
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: var(--gray-600);
}

.barGSE {
    padding: 0 !important;
}

.retailContainer {
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    width: 100%;
    height: auto;
}

.retailContainerText {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 32px;
    width: 100%;
    height: auto;
}

.retailContainerText h3 {
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: var(--gray-600);
}

.retailContainerText p {
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: var(--gray-600);
}




.apexcharts-legend.apx-legend-position-bottom.apexcharts-align-left,
.apexcharts-legend.apx-legend-position-top.apexcharts-align-left,
.apexcharts-legend.apx-legend-position-right,
.apexcharts-legend.apx-legend-position-left {
    position: absolute !important;
    left: auto !important;
    top: 51px !important;
    right: 282px !important;
}

.serviciosContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 32px;
    width: 100%;
    height: auto;
}

.serviciosContainerText {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    width: 100%;
    height: auto;
}

.serviciosContainerText h3 {
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: var(--gray-600);
}

.serviciosContainerText p {
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: var(--gray-600);
}

@media (max-width:1024px) {
    .apexcharts-legend.apx-legend-position-bottom.apexcharts-align-left,
    .apexcharts-legend.apx-legend-position-top.apexcharts-align-left,
    .apexcharts-legend.apx-legend-position-right,
    .apexcharts-legend.apx-legend-position-left {
        right: 2px !important;
    }
    .buttonContainer {
        display: flex;
        justify-content: flex-end;
        margin-top: 32px;
        margin-bottom: 32px;
        position: fixed;
        left: 94%;
        top: 90%;
    }
}

/* @media (max-width:1440px) {
    .dashboardVerticalMenuContainer {
        margin-right: 32px;
    }
} */

@media (min-width:2000px) {
    .apexcharts-legend.apx-legend-position-bottom.apexcharts-align-left,
    .apexcharts-legend.apx-legend-position-top.apexcharts-align-left,
    .apexcharts-legend.apx-legend-position-right,
    .apexcharts-legend.apx-legend-position-left {
        right: 600px !important;
    }
}