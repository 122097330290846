*{
  margin: 0px;
  padding: 0px;
}
.SelectedCheckBox{
/*   position: absolute; */
  left: 200px;
  width: 100%;
}
.selectCheckBox__control--is-focused {
  border-radius: 10px;
  border: 2px solid var(--primary-400)!important;
  box-shadow: 2px solid var(--primary-400)!important;
}
.selectCheckBox__control{
  display: flex;
  height: auto;
  align-items: center;

  align-content: center;
  background: var(--gray-25);

  min-width: 460px;
}
.css-4qk116-option{
  display: flex;
  padding: 6px!important;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  align-self: center;
  max-width: 100%;
  overflow-x: hidden;
}
.selectCheckBox__option{
  padding: 6px!important; 
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  background-color: var(--white-base)!important;
  /* Texto options */
  color: var(--gray-600)!important;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
  font-size: 12px!important;
  font-style: normal!important;
  font-weight: 300!important;
  line-height: 19.5px; 
  letter-spacing: 0.28px!important;
}
.selectCheckBox__placeholder{
  color: var(--gray-400)!important;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
  font-size: 12px!important;
  font-style: normal!important;
  font-weight: 400!important;
  line-height: normal!important;
  letter-spacing: 0.28px!important;
}
.css-wsp0cs-MultiValueGeneric,.css-12a83d4-MultiValueRemove{
  display: flex;
  padding: 4px 6px;
  align-items: center;
  gap: 4px;
  border-radius: 4px!important;
  border: 0.5px 0.5px 0.5px 0px solid  #D9D9D9;
  background: var(--gray-100);
}
.selectCheckBox__multi-value__label,
.selectCheckBox__multi-value .css-1p3m7a8-multiValue{
  color: var(--gray-600);
      font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
  font-size: 10px!important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
}
.selectCheckBox__value-container--has-value,
.selectCheckBox__value-container,
.selectCheckBox__value-container--is-multi{
  flex-wrap: nowrap!important;
}
.css-12a83d4-MultiValueRemove svg{
  display: none;
}
.css-12a83d4-MultiValueRemove {
  background-image: url(../../Amplify/AmplifyManual/img/delete.svg);
  background-repeat: no-repeat;
  padding: 4px 6px;
  background-position: center;
  gap: 4px;
  width: 15px;
  border-radius: 0px 4px 4px 0px!important;
}

.css-1p3m7a8-multiValue{
  border-radius: 4px;
  border: 0.5px solid  #D9D9D9;
  background-color: var(--gray-100)!important;
}
.css-1u9des2-indicatorSeparator{
  display: none;
}
.css-1xc3v61-indicatorContainer{
  height: 35px;
}
.css-1xc3v61-indicatorContainer{
  padding: 6px;
}
.css-qbdosj-Input{
/*   padding-left: 8px!important; */
  margin: 0px!important;
  width: 100%!important;

}

.css-1fdsijx-ValueContainer{
  padding: 0px 0px!important;
}
.css-d7l1ni-option:hover{
  display: flex;
  padding: 6px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 4px;
}

.selectCheckBox__option:hover{
  padding: 6px 0px;
}
.css-d7l1ni-option:active{
  background: none!important;  
}
.css-tr4s17-option:active{
  background: none!important;
}

.selectCheckBox__menu-list {
  display: flex;
  width: 100%;
  padding: 8px!important;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  border-radius: 4px;
  background-color: var(--white-base);
  max-height: 300px;
  overflow-y: auto; /* Cambiado a 'auto' para desplazamiento vertical automático */
  overflow-x: hidden;
}

.selectCheckBox__menu {
  top: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 8px;
  gap: 8px;
  border-radius: 6px!important;
  background: var(--white-base)!important;
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.10)!important;
  margin: 0px!important;
  overflow-y: auto; 
  overflow-x: hidden; 
  scroll-behavior: smooth;
}
.css-4qk116-option:hover{
  border-radius: 4px;
  background: var(--primary-50);
}
.css-1fds1jx-ValueContainer{
  min-width: 436px!important;
}
.react-select-container .css-1n6sfyn-MenuList{
  min-width: 440px;
}
.css-1hex30e-control{
  border-radius: 6px!important;
  border: 1.5px solid var(--gray-300)!important;
  background: var(--white-base)!important;
}




/* .selectBox__option--is-selected {
  background-color: #EFF8FF!important; 

} */


/* Agrega cualquier otro estilo personalizado que necesites */
