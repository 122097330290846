@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
.mainCardBuyerPersona{
  width: 100%;
}
.cardBuyerPersona{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 119px;
  gap: 6px;
  align-self: stretch;
  border-radius: 10px;
border: 0.5px solid var(--gray-300);
background-color: var(--white-base);
box-shadow: 1px 1px 9px 0px #EDEDED;
}
.cardBPIcon{
  display: flex;
  width: 70px;
  height: 70px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 10px;
  background: var(--primary-200);
}
.cardBPContainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 6px;
  padding: 0px;
  width: 100%;
  height: 80px;
}
.cardBPText{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  width: 100%;
}
.cardBPTitle,
.cardBPClient,
.cardBPValue,
.cardBPValueTotal{
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  font-style: normal;
  line-height: normal;
  
}
.cardBPTitle{
  color: var(--gray-600);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.28px;
}
.cardBPClient{
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.26px;
}
.cardBPValue{
  color: var(--gray-600);
   font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.26px;
}
.modalMasCreditosTextEnlace{
cursor: pointer;
text-decoration: underline;
color: var(--gray-600);
}
.cardBPValueTotal{
  color:var(--blue-700);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.48px;
}
.cardBPProgressBar{
  width: 100%;
  height: 8px;
  border-radius: 20px;
  background: var(--gray-100);
}
.cardBPProgressBarActive{
  height: 8px;
  border-radius: 20px;
  background: var(--primary-400);
}
.modalMasCreditosTotal{
  display: inline-flex;
flex-direction: column;
justify-content: center;
align-items: center;
position: absolute;
/* left: 50%;
transform: translate(17em); */
margin-top: 15.5em;
    margin-left: 40em;
z-index: 1;
}
.modalMasCreditosArrow{
  display: flex;
justify-content: center;
align-items: center;
gap: 8px;
}
.modalMasCreditosArrow img{
  z-index: 1;
}
.modalMasCreditos{
  display: flex;
  width: 296px;
padding: 12px;
align-items: flex-start;
gap: 8px;
border-radius: 8px;
background-color: var(--white-base);
box-shadow: 0px 15px 35px 0px rgba(103, 110, 118, 0.08), 0px 5px 15px 0px rgba(0, 0, 0, 0.12);

}
.modalMasCreditosConteiner{
  display: flex;
width: 320px;
padding: 12px;
align-items: flex-start;
gap: 8px;
}
.modalMasCreditosContent{
  display: flex;
flex-direction: column;
align-items: flex-start;
gap: 8px;
}
.modalMascreditosTitle{
  color: var(--gray-900);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: 18px; /* 150% */
}
.modalMasCreditosText{
  color: var(--gray-600);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 18px; /* 150% */
}

.modalMasCreditosPosition{
  display: flex;
    flex-direction: column;
    align-items: flex-end;
}
@media  (max-width:1124px) {
  .cardBuyerPersona{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px;
    gap: 6px;
    flex: 1 0;
    width: 96%;
  }  
  .cardBPTexto{
    color: var(--gray-600);
        font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.22px;
  }
  .cardBuyerPersonaValor p{
    color: var(--gray-600);
        font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.4px;
  }
 }

/* @media (max-width:1000px){
  .modalMasCreditosTotal{
    transform: translate(8em);
    margin-top: 16.5em;
  }
}
@media (max-width:1400px) {
  .modalMasCreditosTotal{
    transform: translate(8em);
    margin-top: 16.5em;
  }
} */
