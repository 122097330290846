.buyerPersonaCardContent{
  width: 588px;
}
.buyerPersonaTextContainer{
 display: flex;
width: 691px;
padding: 24px;
flex-direction: column;
align-items: flex-start;
gap: 16px;

}

.buyerPersonaTextContent{
  display: flex;
  padding: 24px 24px 12px 24px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 10px 10px 0px 0px;
  border: 1.5px solid var(--gray-300);
  background-color: var(--white-base);
  }
  .buyerPersonaText{
    display: flex;
    padding: 12px 24px 24px 24px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 0px 0px 10px 10px;
    border-right: 1.5px solid var(--gray-300);
    border-bottom: 1.5px solid var(--gray-300);
    border-left: 1.5px solid var(--gray-300);
    background-color: var(--white-base);

  }
  .buyerPersonaTextTitleImg{
      display: flex;
  align-items: center;
  gap: 4px;
  flex-direction: row;
  }
  .buyerPersonaTextTitleImg p{
    color: var(--gray-600);
        font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 0.26px;
  }
  .buyerPersonaTextTitleImg p span{
    color: var(--gray-600);
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.26px;
  }
  .buyerPersonaTextTitleOne{
        display: flex;
    align-items: center;
    gap: 4px;
  }
  .buyerPersonaTextTitleOne p{
    color: var(--gray-600);
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.28px;
  }
  .buyerPersonaText{
        display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
  }
 
  .buyerPersonaText h2{
    color: var(--gray-600);
        font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px; /* 161.538% */
  }
  .buyerPersonaText p{
    color: var(--gray-600);
        font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px; /* 161.538% */
  }
  .buyerPersonaContent{
    display: flex;
    padding: 12px 24px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 10px 10px 0px 0px;
    border: 1.5px solid var(--gray-300);
    background-color: var(--white-base);
  }
  @media (min-width:1440px){
    .buyerPersonaContent{
      width: 553px;

    }
    .buyerPersonaText{
      width: 553px;
    }
  }
  
  .buyerPersonaTextTitleCard{
    color: var(--gray-600);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 21px; /* 161.538% */
letter-spacing: 0.26px;
  }
  
  