
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-180deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

.spinner_containerGris {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--white-bg);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}

.spinner__circleGris {
  position: relative;
  width: 40px;
  height: 40px;
  overflow: hidden;
  background-color: var(--gray-500);
  border-radius: 100%;
  animation: spin 1s infinite linear; 
}

.spinner__circle-innerGris,
.spinner__circle-gradientGris {
  position: absolute;
  width: 33px;
  height: 33px;
  transform: translate(-50%, -50%);
	border-radius: 3px; 
}

.spinner__circle-innerGris {
  top: 50%;
  left: 50%;
  background-color: #F5F8FB;
  border-radius: 100%;
}

.spinner__circle-gradientGris {
  top: -24px;
  left: 6px;
  background-color: #6D7A8F;
  background-image: -moz-linear-gradient(0deg, #F5F8FB 0%, #6D7A8F 80%);
  background-image: -webkit-linear-gradient(0deg, #F5F8FB 0%, #6D7A8F 80%);
  background-image: linear-gradient(0deg, #F5F8FB 0%, #6D7A8F 80%);
  transform: rotate(-50deg);
	animation-direction: reverse;
}
