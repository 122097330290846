.amplifySolicitudClones {
  display: flex;
  width: 386px;
  height: auto;
  padding: 16px;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  gap: 40px;
  background-color: var(--white-base);
  filter: drop-shadow(1px 1px 9px #EDEDED);
}

.amplifySolicitudClonesBuenaNoticia {
  display: flex;
  width: 386px;
  height: 524px;
  padding: 16px;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  gap: 40px;
  background-color: var(--white-base);
  filter: drop-shadow(1px 1px 9px #EDEDED);

}

.SolicitudClonesContenedorImg {
  display: flex;
  width: 340px;
  height: 173px;
  padding: 24px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  border-radius: 20px;
}

.SolicitudClonesContenedorImg img {
  height: 163px;
  width: 163px;
}

.SolicitudClonesContenedorImg.Encontrados {
  background: var(--success-25);
}

.SolicitudClonesContenedorImg.EncontradosWarning {
  background: var(--warning-25);
}

.SolicitudClonesContenedorImg.NoEncontrados {
  background: var(--error-25);
}

.amplifySolicitudClonesContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}

.SolicitudClonesContenedor {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  height: auto;

}

.amplifySolicitudContenedorPrincipal {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}

.solicitudClonesTitle {
  color: var(--gray-900);
   font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;



  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.32px;
}

.SolicitudClonesContenedorTitle {
  display: flex;
  align-items: center;
  gap: 4px;
  align-self: center;
}

.SolicitudClonesContenedorTitle img {
  width: 24px;
  height: 24px;
}

.SolicitudClonesContenedor p {
  color: var(--gray-600);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  font-style: normal;
}

.SolicitudClonesContenedorTitle h2 {
  color: var(--gray-900);
   font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;



  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.32px;
}

.SolicitudClonesContenedor p {
  color: var(--gray-600);
   font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;



  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.24px;
width: 100%;
}

.SolicitudClonesContenedorEncontrados {
  width: 226px;
  padding: 0px 8px;
}

.SolicitudClonesImgEncontrados {
  display: flex;
}

.SolicitudClonesCardDatosContenedor {
  display: flex;
  width: 210px;
  padding: 0px 8px;
  justify-content: center;
  align-items: center;
  gap: 2px;
}

/* .SolicitudClonesCardDatos, */
.SolicitudClonesCardDetalle {
  display: flex;
  /* padding: 2px 24px; */
  align-items: center;
  gap: 6px;
  /* align-self: stretch; */
  border-radius: 10px;

  width: 100%;
  align-self: center;

}

.SolicitudClonesCardDetalle p {
  cursor: pointer;
  color: var(--blue-500);
   font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;



  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
}

.SolicitudClonesCardDatos {
  display: flex;
  height: 32px;
  padding: 0px 4px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  width: 100%;
}

.SolicitudClonesCardDetalle {
  justify-content: flex-end;
}

.SolicitudClonesDatosText {
  display: flex;
  align-items: center;
  gap: 4px;
}

.amplifySolicitudContenido {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  height: 494px;
  width: 100%;
}

.SolicitudClonesCardDatos p {
  color: var(--gray-600);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  /* 100% */
  letter-spacing: 0.24px;
}

.SolicitudClonesCardDatos.Encontrados p,
.SolicitudClonesCardDatos.Solicitados p {
  color: var(--gray-600);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  /* 100% */
  letter-spacing: 0.24px;
}

.SolicitudClonesCardDetalle a {
  color: var(--blue-500);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-decoration-line: underline;
}

.botonesCardEncontrados {
  justify-content: center;
}

.Confirmacion_botones.NoEncontrados {
  align-self: center;
}

.btnCardEncontradosAmplificar {
  width: 186px;
}

.solicitudClonesContenedorDatos {
  width: 100%;
}

.cardEncontradosCenter {
  align-items: center;
}

.solicitudClonesContenedorInterno {
  display: flex;
  padding: 0px 24px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  height: auto;
}

.solicitudClonesContenedorInternoManuales {
  display: flex;
  padding: 0px 24px;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
}

.SolicitudClonesCardDatosContenedorManuales {
  display: flex;
  height: 32px;
  padding: 0px 24px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  border-radius: 4px;
  /* background: var(--gray-25); */

  /* width: 47vw;
  padding: 0px 0px;
  gap: 2px;  */
}

@media(min-width:768px) {
  .SolicitudClonesCardDatosContenedorManuales {
    display: flex;
    /* width: 47vw; */
    /* padding: 0px 0px; */
    justify-content: center;
    align-items: center;
    gap: 2px;
    width: 210px;
    padding: 0px 8px;
  }
}

@media(min-width:1024px) {
  .SolicitudClonesCardDatosContenedorManuales {
    display: flex;
    /* width: auto;
    padding: 0px 0px; */
    justify-content: center;
    align-items: center;
    gap: 2px;
    width: 210px;
    padding: 0px 8px;
  }
}

@media(min-width:1440px) {
  .SolicitudClonesCardDatosContenedorManuales {
    display: flex;
    height: 32px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    width: 210px;
    padding: 0px 8px;

    /* width: 90%; */
    /* padding: 0px 24px; */
    /* width: 22vw;
    padding: 0px 0px;
    gap: 2px; */
  }
}

@media(min-width:1440px) {
  .SolicitudClonesCardDatosContenedorManuales {
    display: flex;
    height: 32px;
    padding: 0px 0px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    width: auto;

  }
}

@media(min-width:2560px) {
  .SolicitudClonesCardDatosContenedorManuales {
    display: flex;
    /* width: 13vw; */
    /* padding: 0px 0px; */
    justify-content: center;
    align-items: center;
    gap: 2px;
    width: 210px;
    padding: 0px 8px;
  }
}