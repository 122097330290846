.amplifyTablaContainer{
  width: 1194px;
  display: flex;
  padding: 24px ;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  filter: drop-shadow(1px 1px 9px #EDEDED);

}
.amplifyTablaContainerText{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}
.amplifyTablaContainerText h2,
.amplifyTablaContainerText p{
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  font-style: normal;
}
.amplifyTablaContainerText h2{
  color: var(--gray-600);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 150% */
letter-spacing: 0.32px;
}
.amplifyTablaContainerText p{
  color: var(--gray-600);
  font-size: 12px;
  font-weight: 400;
  line-height: 19.5px; 
  letter-spacing: 0.26px;
}
.amplifyTablaContainerCards{
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
  align-self: stretch;
}
.amplifyTablaContainerButtons {
  display: flex;
justify-content: flex-end;
align-items: center;
gap: 16px;
align-self: stretch;
}
/* Estilos para el fondo gris que cubre toda la pantalla */
.greyBackgroundModal {
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
}

/* Estilos para el contenido del modal */
.ModalExitoDetalle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  border-radius: 5px;
  z-index: 1000;
  overflow: hidden;
}


@media (max-width: 1920px){
  .ModalExitoDetalle {
   height: 110vh;
   width: 118vw;

  }
 
}
@media (max-width: 1440px){
  .ModalExitoDetalle {
    height: 100vh;
   width: 100vw;
  }
  .amplifyTablaContainer{
    align-self: flex-start;
  }
}
@media (min-width:1440px) {
  .amplifyTablaContainer{
   width: 1123px;
  }
}
@media (min-width:1520px) {
  .amplifyTablaContainer{
    align-self: center;
  }
}
@media (min-width:1919px){
  .ModalExitoDetalle{
    top: 64%;
    left: 50%;
    width: 100%;
    height: 100%;
  }
}
