.mainWalkthrough {
  display: flex;
  justify-items: center;
  align-items: center;
  background: var(--white-bg);
  justify-content: center;
  height: 100vh;
}
.cardContainerWalkthrough{
  margin: 0 47px;
  /* width: 375px; */
}
.arrow {
  display: flex;
  width: 48px;
  height: 48px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.hidden {
  visibility: hidden;
}

