.popUpMensajeErrorBody{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
}
.popUpMensajeError{
display: flex;
width: 448px;
padding: 24px;
height: 217px;
flex-direction: column;
align-items: center;
gap: 60px;

border-radius: 20px;
border: 1.5px solid var(--gray-300);
background-color: var(--white-base);
box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.10);
}
.popUpMensajeErrorContenido{
    display: flex;
flex-direction: column;
align-items: center;
gap: 16px;
align-self: stretch;
}
.popUpMensajeErrorContenidoTitulo{
    color: var(--gray-600);
     font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.32px;
}
.popUpMensajeErrorContenidoTexto{
    color: var(--gray-600);
text-align: center;
 font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 19.5px; /* 162.5% */
letter-spacing: 0.24px;
}
.popUpMensajeErrorBotonContenedor{
    display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 10px;
align-self: stretch;
}
.popUpMensajeErrorBoton{
    display: flex;
width: 190px;
padding: 8px 12px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 8px;
background: var(--primary-600);
box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25);
color:  var(--white-base);
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 21px; 
letter-spacing: 0.28px;
}
.popUpMensajeErrorBoton:hover{
    border-radius: 8px;
background: var(--primary-500);
box-shadow: 0px 2px 4px 0px #D0D5DD;
}
@media (max-width:1439px){
    .popUpMensajeError{
        display: flex;
        width: 304px;
        height: 190px;
        padding: 24px;
        flex-direction: column;
        align-items: center;
        gap: 40px;
    }
.popUpMensajeErrorBotonContenedor img{
    width: 32px;
    height: 32px;
}
   
    .popUpMensajeErrorBoton{
        display: flex;
        height: 28px;
        width: 106px;
        padding: 8px 12px;
        align-items: center;
        gap: 10px;
        color: var(--white-base);
    }
}