@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
*{
  margin: 0;
  padding: 0;
}
.home{
  display: flex;
  flex-direction: column;
  width: 96%;
  height: 100vh;
  background: var(--white-bg);
}
.userContainer{
  display: flex;
width: 960px;
height: 90px;
justify-content: space-between;
align-items: center;

}
.home_body_container {
  transition: margin-left 0.5s ease;
  height: 100vh;
  align-self: center;
  position: relative;
}
.home_body_container.navbar-open {
  margin-left: 140px;
}
.headerHome{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 99.5%;
  height: 10%;
  gap: 10px;
}
.userContainer h1{
  color: var(--gray-600);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.64px;

}
.userContainer span{
  color: var(--gray-600);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.64px;
}
.mainHome{
  display: flex;
  width: 960px;
  padding: 24px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  flex: 1 0 0;
}
.homeBodyCardsState{
  display: flex;
  width: 100%;
  align-items: flex-start;
  gap: 32px;
  justify-content: space-between;
}
.tableContainerProcessExecuted{
  display: flex;
  flex-direction: column;
}


.loader1Home {
  height: 80px;
  width: 80px;
  border-radius: 80px;
  border: 3px solid var(--primary-default);
  top: 28%;
  top: -webkit-calc(50% - 43px);
  top: calc(50% - 43px);
  left: 35%;
  left: -webkit-calc(50% - 43px);
  left: calc(50% - 43px);
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-animation: loader1 3s linear infinite;
  animation: loader1 3s linear infinite;
}
.loader1Home:after {
  content: "";
  position: absolute;
  top: -5px;
  left: 20px;
  width: 11px;
  height: 11px;
  border-radius: 10px;
  background-color: var(--primary-default);
}
.createNewBuyerPersona{
  display: flex;
width: 11.875rem;
height: 2.375rem;
padding: 0.5rem 0.75rem;
justify-content: center;
align-items: center;
gap: 4px;
border-radius: 0.5rem;
background: var(--primary-600);
box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25);
color: var(--white-base);
 font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 21px; /* 150% */
letter-spacing: 0.28px;
}
.modalAmplify{
  display: flex;
width: 174px;
padding: 8px;
align-items: flex-start;
border-radius: 4px;
background-color: var(--white-base);
box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.30);
position: absolute;

/* left: 50%;
transform: translate(25.3em); */
margin-top: 4.5em;
    margin-left: 48.1em;
}
.modalAmplifyConteiner{
  width: 100%;
}
.modalAmplifyContentItem{
  display: flex;
  height: 40px;
  padding: 4px 8px;
  align-items: center;
  gap: 6px;
  align-self: stretch;
}
.modalAmplifyContentItem:hover{
  border-radius: 6px;
background: var(--secondary-gray-100, #F2F4F7);
}
.modalAmplifyContentItem button{
  color: var(--gray-600);
 font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
@-webkit-keyframes loader1 {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
}
@keyframes loader1 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}
.spinner_container_homeProcess{
  position: fixed;
  top: 0;
  left: 185px;
  width: 90%;
  height: 100%;
  background-color: var(--white-bg);
  z-index: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  display: flex;
}

@media (max-width:1439px){
  .home_body_container.navbar-open {
    width: 960px;
  }
  .tableContainerProcessExecuted{
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: center;
    width: 100%;
  }
 
}

@media (max-width:1439px) {
  .spinner_container_homeProcess{
   left: 0;
   width: 100%;
  }
}
@media (min-width:1920px) {
  .userContainer{
   margin-top: 7em;
  }
}
.spinner__circle_homeProcess {
  position: relative;
  width: 40px;
  height: 40px;
  overflow: hidden;
  background-color: #FF8C00;
  border-radius: 100%;
  animation: spin 1s infinite linear; 
}

.spinner__circle-inner_homeProcess,
.spinner__circle-gradient_homeProcess {
  position: absolute;
  width: 33px;
  height: 33px;
  transform: translate(-50%, -50%);
	border-radius: 3px; 
}

.spinner__circle-inner_homeProcess {
  top: 50%;
  left: 50%;
  background-color: #F5F8FB;
  border-radius: 100%;
}

.spinner__circle-gradient_homeProcess {
  top: -24px;
  left: 6px;
  background-color: #FF8C00;
  background-image: -moz-linear-gradient(0deg, #F5F8FB 0%, #FF8C00 80%);
  background-image: -webkit-linear-gradient(0deg, #F5F8FB 0%, #FF8C00 80%);
  background-image: linear-gradient(0deg, #F5F8FB 0%, #FF8C00 80%);
  transform: rotate(-50deg);
	animation-direction: reverse;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-180deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
@media (min-width:1920px){
  .home_body_container.navbar-open{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch;
  }
  .headerHome{
    align-self: center;
  }
  .mainHome{
    align-self: center;
  }
}  
