.cardAcordeon {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 8px;
  background-color: var(--white-base);
}
.card-header {
  display: flex;
  align-items: center;
  align-self: stretch;
  justify-content: space-between;
  padding: 12px 16px;
  gap: 10px;
  border-radius: 8px;
}
.card-header h2 {
  color: var(--gray-600);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 19.5px; 
  letter-spacing: 0.26px;
}
.card-body {
  border-radius: 0px 0px 8px 8px;
  padding: 12px 16px;
}
.card-body p {
  color: var(--gray-600);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 19.5px; 
  letter-spacing: 0.26px;
}
.card-body-hipervinculo{
  display: flex;
}
#card-body-hipervinculo-text{  
    margin-left: 4px;
  font-size: 12px;}