.popUpMensajeExito{
    display: flex;
    align-items: center;
    align-self: flex-end;
    width: 360px;
    padding: 24px;
    gap: 24px;
    border-radius: 10px;
    background-color: var(--white-base);
    box-shadow: 8px 8px 12px 0px rgba(168, 168, 168, 0.10);
    position: absolute;
    z-index: 9;
    right: -35%;
    top: 0.5em;
}
.popUpMensajeExitoContenido{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;
}
.popUpMensajeExitoContenidoTitulo{
    color: var(--gray-600);
        font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.32px;
}
.popUpMensajeExitoContenidoTexto{
    color: var(--gray-600);
    text-align: center;
        font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 19.5px; 
    letter-spacing: 0.26px;
}
.horizontal-bar {
    width: 304px;
    height: 8px;
    border-radius: 20px;
    background: var(--success-400);
}
@media (min-width:1440px){
    .popUpMensajeExito{  
        right: -45%;
    }
}