.amplifyTablaButtons{
    margin-top: 1.5em
}
.page-link,
.buttonActiveOrange{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 2px;
    width: 20px;
    height: 20px;
    gap: 10px;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19.5px; /* 139.286% */
    letter-spacing: 0.28px;
}
.buttonActiveOrange{
    color: var(--primary-500)!important;
}
.page-link{
    color: var(--gray-600);
}