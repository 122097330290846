.amplifyModalTablaError {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 155px;
  padding: 16px;
  border-radius: 10px;
  background-color: var(--white-base);
  box-shadow: 8px 8px 12px 0px rgba(168, 168, 168, 0.10);
  right: 6px;
  
}
.amplifyModalTablaError p {
  color: var(--gray-600);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  font-size: 10px;
  font-style: normal;
  font-weight: 300;
  line-height: 15px; 
  letter-spacing: 0.2px;
}