.amplifyCardBuyerPersona {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  border: 1px solid var(--gray-300);
  background-color: var(--white-base);
  width: auto;
  height: min-content;
}
.amplifyCardContentBuyerPersona {
  display: flex;
  padding: 8px;
  align-items: center;
  gap:32px;
  border-bottom: 1px solid var(--gray-100);
  width: 100%;
}
.amplifyCardTitleBuyerPersona {
  display: flex;
  align-items: center;
  gap: 4px;
  width: auto;
}
.amplifyCardTitleBuyerPersona img {
  width: 6px;
  height: 6px;
  fill: var(--violet-400);
}
.amplifyCardTitleBuyerPersona p {
  color: var(--gray-600);
      font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px; /* 150% */
  letter-spacing: 0.2px;
}
.input-containerPerfiles {
  display: flex;
  width: 182px;
height: 36px;
gap: 2px;
align-items: center;
flex-shrink: 0;
}

.input-containerPerfiles:focus-visible{
  border-radius: 10px;
  border: 2px solid var(--primary-400);
  background-color: var(--white-base);
}
.input-containerPerfiles.focused {
  border-radius: 10px;
  border: 2px solid var(--primary-400);
  background-color: var(--white-base);
}
.custom-input {
  border: 0px solid var(--gray-300);
  color: var(--gray-600);
      font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.28px;
  width: 60px;
}
.custom-input:focus-visible{
  border:none!important;
    outline: none;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.personas-text {
  color: var(--gray-600);
      font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.24px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}


.inputBuyerPersona {
  color: var(--gray-600);
      font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.24px;
  border: 2px solid transparent;
  transition: border-color 0.3s ease;
}

.completoConExito p,
.completoConAtencion p,
.inputTotalContainer p {
  color: var(--gray-600);
      font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.5px; /* 139.286% */
  letter-spacing: 0.28px;
}

.inputTotalText {
  width: 100%;
  /* margin-left: 39px; */
  gap: 4px;
  margin-left: 34px;
  display: flex;
}

@media (max-width:1439px) {
  .inputTotalText {
    margin-left: 12px;
  }
  
}
.inputTotalText img {
  margin-left: 10px;
}


.inputTotalContainer {
  display: flex;
  justify-content: space-between;
  width: 315px;
  height: 21px;
    background: var(--gray-25);
  align-items: center;
  gap: 4px;
}

@media (min-width:1440px) {
  .inputTotalContainer{
    height: 36px;
  }
  
}
.completoConAtencion {
  display: flex;
  padding: 0px 16px;
  align-items: center;
  align-self: stretch;
  border: 1.5px solid var(--warning-300);
background: var(--warning-25);
}

.completoConExito {
  display: flex;
  padding: 0px 16px;
  align-items: center;
  align-self: stretch;
  border: 1.5px solid var(--success-400);
  background: var(--gray-25);
}

.atencionText {
  color: var(--error-400);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.24px;
  display: flex;
  margin-top: 4px;
padding: 0px 16px;
align-items: flex-start;
gap: 10px;
}
.iconDefault{
  visibility: hidden;
}
.default{
  display: flex;
  padding: 0px 16px;
  align-items: center;
  align-self: stretch;
  border: 2px solid transparent;
  background: var(--gray-25);

}
@media (max-width:1439px){
  .amplifyCardBuyerPersona{
    width: 353px;
  }
  .input-containerPerfiles{
    width: 192px;
  }
  .inputTotalContainer{
    display: flex;
    padding: 8px 16px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    width: 60%;
  }
  .amplifyCardTitleBuyerPersona p {
    width: 97px;
  }
  .custom-input::placeholder{
    color: var(--gray-400);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.28px;
  }
  
  .inputTotalContainer{
    width: auto;
    gap: 32px;
  }

}

.inputTotalTextPrincipal{
  display: flex;
  width: 144px;

}
@media (min-width:1440px){
  .amplifyCardBuyerPersona{
    width: 355px;
  }
/*   .inputTotalText{
    margin-left: 43px;
  } */
}

