@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
    margin: 0;
    padding: 0;
}

.terminosyCondiciones {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
}

.terminosyCondiciones p {
    color: var(--gray-600);
     font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.terminosyCondiciones h2 {
    color: var(--gray-600);
     font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.terminosyCondiciones h1 {
    color: var(--gray-600);
     font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0.39px;
}

.terminosyCondiciones_container_contenido {
    display: flex;
    padding: 32px;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    align-self: stretch;
    border-radius: 20px;
    background-color: var(--white-base);
    box-shadow: 6px 2px 20px 0px rgba(172, 172, 172, 0.25);
}

.terminosyCondiciones_containerTitle {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: auto;
    height: 30px;
    justify-content: center;
}

.terminosyCondiciones_container {
    display: flex;
    flex-direction: column;
    margin-left: 7%;
    width: 90%;
    gap: 32px;
}