.tooltip-container{
  position: relative;
  display: flex;
}
.tooltip-component{
  position: absolute;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  
}

.TooltipBPText {
  right: - 16px; 
  top: calc(-100% - 90px);
  width: 165px;
  padding: 16px;
  border-radius: 10px;
  background-color: var(--white-base);
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.10);
  
  color: var(--gray-600);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
  letter-spacing: 0.24px;
}