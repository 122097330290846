.walkthroughLoader{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 32px;
    width: 100%;
    height: 339px;
}

.boxLoader{
	display: inline-block;
	height: 100px;
	width: 33.3%;
	
	position: relative;
	/*margin:0 -4px -5px -2px;*/
	transition: all .2s ease;
}


.loader1{
	position: relative;
	height: 80px;
	width: 80px;
	border-radius: 80px;
	border: 3px solid  var( --primary-default);

	top: 28%;
	top: -webkit-calc(50% - 43px);
	top: calc(50% - 43px);
	left: 35%;
	left: -webkit-calc(50% - 43px);
	left: calc(50% - 43px);

	-webkit-transform-origin: 50% 50%;
			transform-origin: 50% 50%;
	-webkit-animation: loader1 3s linear infinite;
			animation: loader1 3s linear infinite;
}

.loader1:after{
	content: "";
	position: absolute;
	top: -5px;
	left: 20px;
	width: 11px;
	height: 11px;
	border-radius: 10px;
	background-color:var( --primary-default);
}

@-webkit-keyframes loader1{
    0%{-webkit-transform:rotate(0deg);}
    100%{-webkit-transform:rotate(360deg);}
}

@keyframes loader1{
    0%{transform:rotate(0deg);}
    100%{transform:rotate(360deg);}
}
