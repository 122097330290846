@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
    margin: 0;
    padding: 0;
}

a,
button {
    cursor: pointer;
}
:root {
    --white-base: rgba(254, 254, 254, 1);
    --white-bg: rgba(250, 252, 255, 1);
    --gray-25: rgba(252, 252, 253, 1);
    --gray-50: rgba(249, 250, 251, 1);
    --gray-100: rgba(242, 244, 247, 1);
    --gray-200: rgba(228, 231, 236, 1);
    --gray-300: rgba(208, 213, 221, 1);
    --gray-400: rgba(152, 162, 179, 1);
    --gray-500: rgba(109, 122, 143, 1);
    --gray-600: rgba(71, 84, 103, 1);
    --gray-700: rgba(52, 64, 84, 1);
    --gray-800: rgba(29, 41, 57, 1);
    --gray-900: rgba(16, 24, 40, 1);
    --primary-25: rgba(255, 254, 254, 1);
    --primary-50: rgba(255, 246, 240, 1);
    --primary-100: rgba(255, 236, 222, 1);
    --primary-200: rgba(251, 218, 195, 1);
    --primary-300: rgba(247, 192, 152, 1);
    --primary-400: rgba(245, 169, 115, 1);
    --primary-500: rgba(241, 143, 73, 1);
    --primary-600: rgba(239, 123, 40, 1);
    --primary-700: rgba(225, 107, 22, 1);
    --primary-800: rgba(180, 83, 13, 1);
    --primary-900: rgba(111, 51, 8, 1);
    --blue-25: rgba(245, 250, 255, 1);
--blue-50: rgba(239, 248, 255, 1);
--blue-100: rgba(209, 233, 255, 1);
--blue-200: rgba(178, 221, 255, 1);
--blue-300: rgba(132, 202, 255, 1);
--blue-400: rgba(83, 177, 253, 1);
--blue-500: rgba(46, 144, 250, 1);
--blue-600: rgba(21, 112, 239, 1);
--blue-700: rgba(23, 92, 211, 1);
--blue-800: rgba(24, 73, 169, 1);
--blue-900: rgba(25, 65, 133, 1);

--violet-25: rgba(252, 250, 255, 1);
--violet-50: rgba(249, 245, 255, 1);
--violet-100: rgba(244, 235, 255, 1);
--violet-200: rgba(233, 215, 254, 1);
--violet-300: rgba(214, 187, 251, 1);
--violet-400: rgba(182, 146, 246, 1);
--violet-500: rgba(158, 119, 237, 1);
--violet-600: rgba(127, 86, 217, 1);
--violet-700: rgba(105, 65, 198, 1);
--violet-800: rgba(83, 56, 158, 1);
--violet-900: rgba(66, 48, 125, 1);
--pink-25: rgba(254, 246, 251, 1);
--pink-50: rgba(253, 242, 250, 1);
--pink-100: rgba(252, 231, 246, 1);
--pink-200: rgba(252, 206, 238, 1);
--pink-300: rgba(250, 167, 224, 1);
--pink-400: rgba(246, 112, 199, 1);
--pink-500: rgba(238, 70, 188, 1);
--pink-600: rgba(221, 37, 144, 1);
--pink-700: rgba(193, 21, 116, 1);
--pink-800: rgba(158, 22, 95, 1);
--pink-900: rgba(133, 22, 81, 1);
--success-25: rgba(246, 254, 249, 1);
--success-50: rgba(236, 253, 243, 1);
--success-100: rgba(209, 250, 223, 1);
--success-200: rgba(166, 244, 197, 1);
--success-300: rgba(108, 233, 166, 1);
--success-400: rgba(50, 213, 131, 1);
--success-500: rgba(18, 183, 106, 1);
--success-600: rgba(3, 152, 85, 1);
--success-700: rgba(2, 122, 72, 1);
--success-800: rgba(5, 96, 58, 1);
--success-900: rgba(5, 79, 49, 1);
--warning-25: rgba(255, 252, 245, 1);
--warning-50: rgba(255, 250, 235, 1);
--warning-100: rgba(254, 240, 199, 1);
--warning-200: rgba(254, 223, 137, 1);
--warning-300: rgba(254, 200, 75, 1);
--warning-400: rgba(253, 176, 34, 1);
--warning-500: rgba(247, 144, 9, 1);
--warning-600: rgba(220, 104, 3, 1);
--warning-700: rgba(181, 71, 8, 1);
--warning-800: rgba(147, 55, 13, 1);
--warning-900: rgba(122, 46, 14, 1);
--error-25: rgba(255, 251, 250, 1);
--error-50: rgba(254, 243, 242, 1);
--error-100: rgba(254, 228, 226, 1);
--error-200: rgba(254, 205, 202, 1);
--error-300: rgba(253, 162, 155, 1);
--error-400: rgba(249, 112, 102, 1);
--error-500: rgba(240, 68, 56, 1);
--error-600: rgba(217, 45, 32, 1);
--error-700: rgba(180, 35, 24, 1);
--error-800: rgba(145, 32, 24, 1);
--error-900: rgba(122, 39, 26, 1);
}

body {
    margin: 0;
    background:var(--white-bg);
}
