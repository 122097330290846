@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
  margin: 0;
  padding: 0;
}


.navbar_toggle_button {
  position: absolute;
  top: 16px;
  left: 16px;
  z-index: 10;
  padding: 8px;
  background-color: transparent;
  border: none;
  color: var(--white-base);
  font-size: 16px;
  cursor: pointer;
}

.FAQs_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: margin-left 0.5s ease;
  padding: 24px 32px;
  gap: 32px;
  flex: 1 0 0;
  align-self: stretch;
}

.FAQs_container.navbar-open {
  margin-left: 220px;
}

.FAQs_header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 80%;
  gap: 16px;
}

.FAQs_header h2{
  color: var(--gray-600);
      font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.4px;
}
.headerText {
  color: var(--gray-600);
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


font-size: 12px;
font-style: normal;
font-weight: 300;
line-height: 19.5px; /* 150% */
letter-spacing: 0.26px;
}
.FAQs_body {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  width: 80%;
}
@media (max-width:1439px) {
  .FAQs_body {
    width: 100%;
}
.FAQs_header{
  align-self: flex-start;
}
}