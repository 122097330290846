@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
*{
  margin: 0;
  padding: 0;
}
.formContainer{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: center;
  padding: 32px;
  gap: 24px;
  width: 436px;
  border-radius: 20px;
  background-color: var(--white-base);
  filter: drop-shadow(1px 1px 9px #EDEDED);
}
.contentContainer{
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  gap: 32px;
}
.contentContainer select {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 38px;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 21px;
  letter-spacing: 0.02em;
  color: var(--gray-600);
}
.contentContainer select:focus {
  outline: none;
  border-radius: 10px;
  border: 2px solid var(--primary-400);
  background-color: var(--white-base);
}
.InputConfigAA{
  padding: 0px 8px;
  height: 38px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  width: 516px;
}
.InputConfigAA::placeholder{
  color: var(--gray-400);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.28px;
}
.textCargaTuArchivo{
  color: var(--gray-600);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.24px;
}
.InputConfig{
  padding: 0px 8px;
  height: 34px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  width: auto;
}
.toggleText{
  color: var(--gray-600);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.24px;

}
.InputConfig::placeholder{
  color: var(--gray-400);
        font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 0.24px;
}
select:focus-visible{
  border-radius: 10px;
  border: 2px solid var(--primary-400);
  background-color: var(--white-base);
}
.inputContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  width: 100%;
}
.companyName{
  margin-top: 10px;
}
.containerTextOne{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  width: 100%;
}

.toggleContainer{
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 6px;
  width: 100%;
  height: 20px;
}
.toggleContainer img{
  width: 16px;
  height: 16px;
}
/* .toggleContainer p{
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  font-style: normal;
  font-weight: 200;
  font-size: 12px;
  line-height: normal;
  display: flex;
  align-items: center;
  letter-spacing: 0.24px;
  color: var(--gray-600);
} */
.textInputContainer {
  color: var(--gray-600);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0 6px;
}
.buttonsContainerPaso{
  display: flex;
  gap: 16px;
  width: 100%;
  height: 40px;
  place-content: flex-end;
}
.btnDisabled{
  display: flex;
  width: 146px;
  height: 38px;
  padding: 8px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  background: var(--primary-200);
  color: var(--gray-400);
        font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0.28px;
}
.btnSecondary{
  display: flex;
  width: 146px;
  padding: 8px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  color: var(--gray-600);
  border: 1.5px solid var(--gray-300);
  background-color: var(--white-base);
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 21px; /* 150% */
letter-spacing: 0.28px;
}
.btnSecondary:hover{
  border-radius: 8px;
  border: 0.5px solid var(--gray-300);
  background-color: var(--white-base);
  box-shadow: 0px 2px 4px 0px #D0D5DD;
}
.btnPrimary{
  display: flex;
width: 146px;
height: 38px;
/* padding: 8px 12px; */
flex-direction: column;
justify-content: center;
align-items: center;
flex-shrink: 0;
border-radius: 8px;
background: var(--primary-600);
box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25);
color: var(--white-base);

    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 21px; /* 150% */
letter-spacing: 0.28px;
}
.btnPrimaryBuyerPersona{
  display: flex;
width: 146px;
height: 38px;
padding: 8px 12px;
flex-direction: column;
justify-content: center;
align-items: center;
flex-shrink: 0;
border-radius: 8px;
background: var(--primary-600);
box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25);
color: var(--white-base);
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 21px; /* 150% */
letter-spacing: 0.28px;
}
.btnPrimaryBuyerPersona:hover{
  display: flex;
width: 146px;
height: 38px;
padding: 8px 12px;
flex-direction: column;
justify-content: center;
align-items: center;
flex-shrink: 0;
border-radius: 8px;
background: var(--primary-500);
box-shadow: 0px 2px 4px 0px #D0D5DD;
}
.btnPrimary:hover{background: var(--primary-500);
  box-shadow: 0px 2px 4px 0px #D0D5DD;
}
.textInputContainerBP{
  color: var(--gray-600);
   font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;



  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0px 6px;
}

.segmentacionContainer{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  width: 100%;
}
.uploadContainer{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
}
.uploadContainer p{
  color: var(--gray-600);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.24px;
}
.boxCargarArchivos {
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 20px;
  border: 1.5px dashed #E2E4E3;
  background-color: var(--white-base);
  cursor: pointer;
}
.textContainerUpload{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: 100%;
}
.textUpload{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}
.textUpload p:nth-child(1){
  color: var(--blue-500);
      font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.textUpload p:nth-child(2){
  color: var(--gray-600);
      font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


  font-size: 12px;
  font-style: normal;
  font-weight: 200;
  line-height: 19.5px; 
  letter-spacing: 0.26px;
}
.textContainerUpload p{
  color: var(--gray-600);
  text-align: center;
      font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 19.5px; 
  letter-spacing: 0.26px;
  display:flex;
  align-content: flex-start;
}

.inputsBuyerPersona{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
}
.infoIcon.hovered {
  opacity: 0.5;
  cursor: pointer; /* Cambia el cursor al hacer hover */
}

.contenedorIngresaTuBaseDeDatos{
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
}
.segmentacionContenedorPrincipal{
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}
.containerBPTitleSelects{
  display: flex;
flex-direction: column;
align-items: flex-start;
gap: 16px;
align-self: stretch;
}
@media (max-width:1439px){
 
  .formContainer{
    display: flex;
width: 432px;
padding: 32px;
flex-direction: column;
align-items: flex-start;
gap: 24px;
filter: drop-shadow(1px 1px 9px #EDEDED);

  }
  .InputConfig{
    display: flex;
    height: 36px;
    padding: 0px 8px;
    justify-content: space-between;
    align-items: center;
  }
  .InputConfig::placeholder{
    color: var(--gray-600);
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


font-size: 12px;
font-style: normal;
font-weight: 300;
line-height: normal;
letter-spacing: 0.22px;
  }
.contentContainer select{
  display: flex;
height: 38px;
}
.inputContainer{
  display: flex;
flex-direction: column;
align-items: flex-start;
gap: 4px;
align-self: stretch;
}
.textInputContainer{
  color: var(--gray-600);
   font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;



  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.sliderSwitch.round {
  border-radius: 34px;
  width: 26px;
  height: 14px;
}
.toggleContainer{
  display: flex;
align-items: center;
gap: 6px;
align-self: stretch;
margin: 0;
width: 100%;
}

.sliderSwitch:before{
  bottom: 2px;
  content: "";
  height: 10px;
  left: 2px;
  position: absolute;
  transition: .4s;
  width: 10px;
  background-color: #e2e7f1;
}
.uploadContainer{
  height: auto;
}
.uploadContainer p{
  color: var(--gray-600);
   font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;



  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.24px;

}
.btnDisabled{
  display: flex;
width: 146px;
height: 38px;
padding: 8px 12px;
flex-direction: column;
justify-content: center;
align-items: center;
  color: var(--gray-600);
      font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 150% */
  letter-spacing: 0.28px;
}
.btnPrimaryBuyerPersona{
  width: 144px;
  height: 38px;
  display: flex;
padding: 8px 12px;
flex-direction: column;
justify-content: center;
align-items: center;
border-radius: 8px;
background: var(--primary-500);
box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25);
}
.btnPrimaryBuyerPersona:hover{
  display: flex;
  height: 38px;
  padding: 8px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 144px;
}

.btnSecondary{
  display: flex;
  padding: 8px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 146px;
  height: 38px;
  border-radius: 8px;
  border: 0.5px solid  #E2E4E3;
  background-color: var(--white-base);
}

.textUpload p:nth-child(1) {
  color: var(--blue-500);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.textUpload p:nth-child(2){
  color: var(--gray-600);
   font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;



  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.5px; /* 162.5% */
  letter-spacing: 0.24px;
}
.boxCargarArchivos{
  height: 82px;
  width: 402px;
    align-self: center;
}
.buttonsContainerPaso{
  height: 38px;
}
}

.css-tj5bde-Svg{
fill: var(--gray-500)!important;
}
.css-115q2pj-control, .css-1057n9p-control{
  box-shadow: none!important;
}