@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

/* TEXTOS */
/* H2 */
.dobleDoughnutContentTextCL h2,
.Dashboard h2,
.dobleSpineChartsContainerWithTitleCL h2,
.verticalBarCL h2,
.finalGraph h2,
.dobleDoughnutContentTextCL h2,
.consumoContainer h2,
.buyerPersonTextCl h2 {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;;
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.28px;
  color: var(--gray-800);

  font-style: normal;
}

.dobleSpineChartsContainerWithTitleCL h2,
.verticalBarCL h2,
.finalGraph h2,
.dobleDoughnutContentTextCL h2,
.consumoContainer h2,
.dobleDoughnutContentTextCL h2,
.Dashboard h2 {
  display: flex;
  align-items: center;
}

.consumoContainer h2,
.verticalBarCL h2,
.finalGraph h2,
.consumoContainer h2 {
  text-transform: none;
}

/* .dobleDoughnutContentTextCL h2, .Dashboard h2{
  margin-left: 5px;
} */

/* H3 */
.dobleDoughnutContentCL h3,
.containerChartsh3,
.dobleDoughnutdivCLWithTitleCL h3,
.dobleDoughnutdivCLTextAndTitleCL h3,
.barsBenchamarkGeneroConTituloCl h3,
.dobleDoughnutContentCL h3,
.educationContainerText h3,
.viviendasContainer h3,
.divDoughnutGeneroCL h3,
.dobleDoughnutdivContentCL h3,
.barDivContainerGraphCL h3,
.retailContainerText h3,
.serviciosContainerText h3,
.vehiculosContainerContentText h3,
.dobleDoughnutdivTextAndTitleCL h3,
.dobleDoughnutContentCLTasacion h3,
.dobleDoughnutContentCLGraficos h3 {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.28px;
  color: var(--gray-800);

  font-style: normal;
}

.ChartClDownload {
  display: flex;
}

.ChartClDownload p {
  margin-right: 6px;
}

.DashboardTitleCL {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.dobleDoughnut {
  align-self: center;
  width: 100%;
}

.dobleDoughnutdivCLWithTitleCL {
  display: flex;
  flex-direction: column;
  width: 45%;
  height: 93.59%;
  padding: 16px;
  align-items: center;
  gap: 8px;

}

/* .dobleDoughnutdivCLWithTitle{

} */
.dobleDoughnutContentCL h3,
.dobleDoughnutdivContentCL h3,
.divDoughnutGeneroCL h3,
.barsBenchamarkGeneroConTituloCl h3,
.retailContainerText h3,
.serviciosContainerText h3,
.dobleDoughnutContentCLGraficos h3 {
  display: flex;
  align-items: center;
}

.containerChartsh3 {
  margin: 2vw 4vw;
}

.barsBenchamarkGeneroConTituloCl h3 {
  margin: 0px;
  padding: 0px;
  width: auto;
  display: flex;
  align-self: flex-start;
}

.dobleSpineChartsWithTitleReverse h3 {
  padding: 16px 17px 1px 0px;
}

/* p */
.dobleDoughnutContentCL p,
.selectGrupoSocioeconomico p,
.barDivContainerGraphCL p,
.serviciosContainerText p,
.dobleDoughnutdivTextAndTitleCL p,
.verticalBarCL p,
.dobleDoughnutdivCLWithTitleCL p,
.dobleDoughnutdivCLTextAndTitleCL p,
.divDoughnutGeneroCL p,
.dobleDoughnutdivContentCL p,
.retailContainerText p,
.finalGraph p,
.dobleDoughnutContentTextCL p,
.consumoContainer p,
.vehiculosContainerContentText p,
.dobleDoughnutContentCLTasacion p,
.dobleDoughnutContentCLGraficos p {
  color: var(--gray-600);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  /* 200% */
  letter-spacing: 0.15px;
}



.divDoughnutGeneroCL p,
.dobleDoughnutdivContentCL p {
  height: 78px;
}

.finalGraph p {
  width: 100%;
  height: auto;
  line-height: 21px;

}

.finalGraph:last-child p {
  width: 100%;
  height: auto;
  line-height: 21px;
  font-weight: 300;
}

.dobleDoughnutdivCLCL {
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 8px;
  background: var(--gray-100);
  padding: 16px;
  width: 100%;
}

/* Subtextos de graficos */
.chartDataNseTextCL {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  color: var(--gray-600);
  width: auto;
}

.chartDataNseTextCL p {
  margin: 0 200px;
  text-align: start;
  color: var(--gray-600);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;;
  font-size: 12px;
  font-style: normal;
  font-weight: 200;
  line-height: 18px;
  /* 150% */
}

/* Generales */
.containerCharts {
  display: flex;
  width: 100%;
}

strong {
  color: var(--gray-800) !important;
}

.finalGraphText strong {
  color: var(--gray-500) !important;

}

.finalGraphText p {
  line-height: 21px;
  font-weight: 300;
  letter-spacing: 0.4px;
  color: var(--gray-500) !important;

}

.dashboardVerticalMenuContainer {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.Dashboard {
  width: 100%;
  /* margin-left: 32px; */
  display: flex;
  flex-direction: column;
  height: auto;
  align-items: flex-start;
  padding-top: 32px;
  padding-right: 0px;
  padding-bottom: 0px;
  gap: 32px;
  align-self: center;
}

/* Nivel porcentual de completitud en variables del cliente */
.caracteristicasSocioDemograficas {
  width: 100%;
  max-width: 1144px;
}

/* Cards Generales */
.dobleDoughnutContainerCL,
.dobleSpineChartsContainerWithTitleCL,
.verticalBarCL,
.finalGraph {
  padding: 24px 40px 40px 40px;
  gap: 24px;
  width: 100%;
  max-width: 956px;
  border-radius: 10px;
  border: 1px solid var(--gray-300);
  background: var(--white-base);
  color: var(--gray-300);
  /* box-shadow: 1px 1px 9px 0px #EDEDED; */
}

.dobleDoughnutContainerCL,
.dobleSpineChartsContainerWithTitleCL {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  width: 100%;
}

.ChartsGraphicsCL,
.finalGraph {

  display: flex;
}

.verticalBarCL {
  height: auto;
}

.finalGraph {
  border-radius: 10px;
  background-color: var(--white-base) !important;
}

.finalGraphContenedor {
  border-radius: 8px;
  padding: 24px 0px;
  gap: 24px;
  display: flex;
  flex-direction: column;
  width: 100%;
}



.dobleDoughnutContentCL {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;
  width: 582.5px;
  height: 88px;
  align-self: flex-start;
}

.dobleDoughnutCL {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  gap: 32px;
  width: 100%;
  height: 100%;
}



.boxTablaTemplate {
  overflow-y: scroll;
  overflow-x: hidden;
}

.boxTablaTemplate::-webkit-scrollbar {
  width: 8px;
}

.boxTablaTemplate::-webkit-scrollbar-thumb {
  background-color: var(--primary-default);
  border-radius: 5px;
}

/* .distribucionporColonia {
  width: 50%;
} */
.barGender {
  padding: 0px;
}

.buyerPerson {
  display: flex;
  justify-content: center;
}

.barTitle .ChartsGraphics {
  padding: 0px;
}

.buyerPerson .doughnut-chart {
  width: 90%;
  margin-top: 32px;
}

.doughnut-chart {
  margin-top: -7px;
}

.anchoContenedorBarEdad {
  border-radius: 8px;
  background: var(--gray-100);
  gap: 24px;
  width: 100%;
  height: 344px;
}

.selectGrupoSocioeconomico {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;

}

.selectGrupoSocioeconomico select {
  padding: 10px 16px 10px 6px;
  gap: 10px;
  width: 440px;
  height: 38px;
  background: var(--white-base);
  border: 1.5px solid #E2E7F1;
  border-radius: 8px;
  color: var(--gray-600);
}

.dobleDoughnutContentTextCL {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;
  width: 100%;

}

.chartsSelect {
  border: 1px solid var(--gray-border);
  border-radius: 4px 4px 0px 0px;
  margin-bottom: 18px;
}

.dobleSpineChartsWithTitleReverse {
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
  margin-top: 32px;
}

.dobleDoughnutdivWithTitleCLdonut {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: 24px;
}

.dobleDoughnutdivCLWithTitleCLdonut {
  display: flex;
  flex-direction: column;
  margin: 15px;
  gap: 8px;
  border-radius: 8px;
  background: var(--gray-100);
  width: auto;
  align-self: flex-start;
}

.dobleDoughnutdivCLWithTitleCLdonutTasacion {
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 8px;
  background: var(--gray-100);
  width: auto;
  align-self: flex-start;
}

.dobleDoughnutdivWithTitleCL {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 16px;
  width: 100%;
  align-self: center;
  height: 100%;
}

.paddingTasacion {
  padding: 16px;
}

.barDivContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.barDivContainerGraphCL {
  width: 96.5%;
  display: flex;
  align-self: center;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  padding: 16px;
  background-color: var(--gray-100);
  border-radius: 8px;
}

.dobleDoughnutContentCL {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;
  width: 100%;
  height: auto;
}

.dobleDoughnutContentCLGraficos {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;
  width: auto;
  height: 88px;
}

.dobleDoughnutContentCLEdad {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;
  width: 100%;
  height: 18px;
  margin-bottom: 12px;
}

.dobleSpineChartsWithTitle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
  width: 100%;
  height: auto;
}

.dobleSpineChartsWithTitleGender {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  border-radius: 8px;
  background-color: var(--gray-100);
  width: 100%;
  height: auto;
}

.dobleSpineChartsWithTitleFlex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 16px;
  width: 100%;
  height: auto;
  align-self: flex-start;
}

.educationContainerText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  width: 100%;
  height: auto;
}

.verticalMenu {
  position: fixed;
  width: 93%;
  right: 0px;
  background-color: var(--white-base);
  z-index: 3;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
  margin-bottom: 32px;
  position: fixed;
  left: 96%;
  top: 90%;
}

.chartsSelectContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 24px;
  margin-top: 0px;
  width: 100%;
  height: auto;
}

.chartsSelect {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: center;
  padding: 8px;
  width: 100%;
  height: 40px;
  background: var(--white-base);
  border: 1px solid var(--gray-border);
  border-radius: 4px;
}

.chartsSelectTwo {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: center;
  padding: 8px;
  width: 50%;
  height: 40px;
  background: var(--white-base);
  border: 1px solid var(--gray-border);
  border-radius: 4px;
}

.verticalBarGraphicsCL {
  flex-direction: column;
  align-items: flex-start;
  /* padding: 32px 32px 0 32px; */
  gap: 32px;
  width: 100%;
  height: auto;
}

.boxErrorOne {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.boxErrorOne div {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;
  margin: auto;
  padding: auto;
  justify-content: center;
  gap: 32px;
}

.seleccionarMunicipio {
  padding: 8px 0px;
  align-self: flex-start !important;
}

.educationContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 32px;
  width: 100%;
  height: 100%;
}

.consumoContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  width: 100%;
  height: auto;
}

.barsBenchamarkGeneroConTituloCl {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  width: 95%;
  height: 100%;
  padding: 24px;
  background-color: var(--gray-100);
  border-radius: 10px;
}

.vehiculosContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 40px
}

.vehiculosContainerContentText {
  display: flex;
  gap: 8px;
  flex-direction: column;
  width: 100%;
}

.vehiculosContainerContent {
  display: flex;
  flex-direction: column;
  width: auto;
  align-self: center;
  gap: 8px;
  border-radius: 8px;
  background: var(--gray-100, #F2F4F7);
  padding: 16px;
}

.doughnut-chart-complete-CL {
  width: auto;
  padding: 24px;
}

.doughnut-chartCL {
  width: 100%;
}

.doughnut-chart-bar-cars-CL {
  padding: 0px 24px;
}

.chartDataContenedor {
  border-radius: 8px;
  background: var(--gray-100);
  gap: 24px;
  width: 387px;
  height: 363px;
  display: flex;
  justify-content: center;
}

.viviendasContainer {
  height: auto;
  display: flex;
  flex-direction: column;
  align-self: center;
  gap: 8px;
  width: auto;
  border-radius: 8px;
  background: var(--gray-100);
  padding: 16px;

}

.viviendasContainerTitle {
  height: 19px;
}

.dobleDoughnutdivCL {
  display: flex;
  width: 50%;
  height: auto;
  border-radius: 8px;
  background: var(--gray-100);
  padding: 16px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}
.chartDataContenedorGraphicCL{
  border-radius: 8px;
  background: var(--gray-100);
  gap: 24px;
  width: 100%;

  display: flex;
  justify-content: center;
}
.divGraphicCL {
  display: flex;
  width: 97%;
  height: auto;
  border-radius: 8px;
  background: var(--gray-100);
  padding: 16px;
  flex-direction: column;
  gap: 8px;
}
.divGraphicCLQVehiculos{
  display: flex;
  width: 97%;
  height: auto;
  border-radius: 8px;
  background: var(--gray-100);
  padding: 24px;
  flex-direction: column;
  gap: 8px;
}
.divGraphicCL h3, .divGraphicCLQVehiculos h3{
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.28px;
  color: var(--gray-800);
  font-style: normal;
}
.anchoContenedorBar {
  width: 482px;
  height: 100%;
}

.contenedorFlexFondo {
  display: flex;
  justify-content: center;
  width: 843px;
  align-self: center;
}

.chartDataContenedorDisposicionGastos {
  width: 96.3%;
  height: auto;
  padding: 16px;
  background: var(--gray-100);
  border-radius: 8px;
}

.dobleDoughnutdivCLWithTitle {
  width: 96.34%;
  border-radius: 8px;
  background: var(--gray-100);
  padding: 16px;
}

/* .viviendasContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 16px;
  width: 100%;
} */
/*  .dobleDoughnutdivCLWithTitle{
  height: auto;
  display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
    gap: 8px;
    width: 97%; 
    padding: 16px;
    border-radius: 8px;
background: var(--gray-100);


} */

.viviendaTercerGraficoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.material_block {
  width: 580px;
  padding: 20px;
  background-color: var(--white-base);
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4); */
  margin: auto;
}

.spinner {
  -webkit-animation: rotation 1.35s linear infinite;
  animation: rotation 1.35s linear infinite;
}

.dobleSpineChartsContainerCL {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 28px;
  width: 100%;
  height: auto;
}

.dobleDoughnutdivTextAndTitleCL {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;
  width: 533px;
  height: auto;
}

.selectMunicipioContainer {
  width: 100%;
}

.barTitle {
  margin-top: 0em;
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: -webkit-center;
}

.barsGender {
  background: var(--gray-100) !important;
}

.verticalBarText {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.ProfileGraphText {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: var(--gray-100);
  border-radius: 0.5em;
  padding: 1em;
}

.finalGraphText {
  display: flex;
  margin-top: 2em;
  margin-bottom: 2.5em;
  box-shadow: 2px 2px 2px 1px rgba(74, 73, 73, 0.2);
  border-radius: 0.7em;
  padding:48px 60px; 
  background-color: var(--white-base);
  min-height: 32em;

}

.ProfileGraphTextDivContainer {
  display: flex;
}

.ButtonsProfileGraphButtonClContainer {
  display: flex;
  flex-direction: column;
}

.buyerPersonTextCl {
  display: flex;
  flex-direction: column;

}

.buyerPersonTextCl h2 {
  margin-bottom: 16px;
}

.buyerPersonTextCl p {
  font-size: 12px;
  line-height: normal;

}

button {
  background: none;
  border: none;
}

.apexcharts-legend-marker {
  border-radius: 50% !important;
}

.divDoughnutGeneroCL,
.dobleDoughnutdivCLContentCL {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;
  width: auto;
  height: 107px;
}

.dobleDoughnutdivContentCL {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;
  width: auto;
  height: auto;
}

.barGSE {
  padding: 0 !important;
}

.apexcharts-canvas {
  width: 100% !important;

}

.retailContainer {
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  width: 100%;
  height: auto;
}

.retailContainerText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 32px;
  width: 100%;
  height: auto;
}

.apexcharts-legend.apx-legend-position-bottom.apexcharts-align-left,
.apexcharts-legend.apx-legend-position-top.apexcharts-align-left,
.apexcharts-legend.apx-legend-position-right,
.apexcharts-legend.apx-legend-position-left {
  position: absolute !important;
  left: auto !important;
  top: 51px !important;
  right: 282px !important;
}

.SMVGSAlto {
  height: auto;
}

.serviciosContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 32px;
  width: 100%;
  height: auto;
}

.serviciosContainerText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  width: 100%;
  height: auto;
}

.columnsDobleSpineChartsWithTitle {
  flex-direction: column;
}

.contenedorFlexFondoVivencia {
  width: auto;
}

.dobleDoughnutContentCLTasacion {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;
  height: 68px;
  width: 100%;
  align-self: flex-start;
}

.graphicNivelPorcentual {
  /*  display: flex; */
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  border-radius: 8px;
  background: var(--gray-100);
}

.dobleDoughnutVivienda {
  display: flex;
  justify-content: center;
  align-content: center;
  /* width: 100%; */
  align-items: start;
  align-self: flex-start;
  flex-direction: column;
}

.TitleBarsChartsGraphics {
  text-align: start;
  color: var(--gray-800, #1D2939);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.24px;
}

@-webkit-keyframes rotation {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
  }
}

@keyframes rotation {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
  }
}

.circle {
  stroke-dasharray: 180;
  stroke-dashoffset: 0;
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  -webkit-animation: turn 1.35s ease-in-out infinite;
  animation: turn 1.35s ease-in-out infinite;
}

.doughnut-chart-complete-CL {
  position: relative;
  right: 0;
}



.doughnut-chart-complete-CL .apexcharts-legend.apx-legend-position-right {
  top: 35px !important;
  left: 33em !important;
  position: absolute !important;
  width: max-content;
}

.doughnut-chartCL-Pie-viviendas #apexchartspie {
  display: flex;
  justify-self: center;
  justify-content: center;

}

.doughnut-chartCL-Pie-viviendas .apexcharts-legend.apx-legend-position-bottom {
  top: 18.5em !important;
  position: absolute !important;
  width: max-content;
  height: min-content !important;
}

.doughnut-chartCL-Pie-viviendasRefeComercial .apexcharts-legend.apx-legend-position-bottom {
  left: 6em !important;
}

.dobleDoughnutVivienda p {
  height: 54px;
}

.displaynoneGraph {
  display: none;
}

.flex {
  display: flex;
}

@-webkit-keyframes turn {
  0% {
    stroke-dashoffset: 180;
  }

  50% {
    stroke-dashoffset: 45;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }

  100% {
    stroke-dashoffset: 180;
    -webkit-transform: rotate(450deg);
    transform: rotate(450deg);
  }
}

@keyframes turn {
  0% {
    stroke-dashoffset: 180;
  }

  50% {
    stroke-dashoffset: 45;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }

  100% {
    stroke-dashoffset: 180;
    -webkit-transform: rotate(450deg);
    transform: rotate(450deg);
  }
}

@-webkit-keyframes loader1 {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes loader1 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.finalGraphTextTxtImg {
  display: flex;
  justify-content: space-between;
  align-items: center;

}
.finalGraphTextTxtImg p{
  font-size: 13px;
}
.finalGraphTextTxtImg:first-child img {
  margin-right: 0.5em;
  width: 2em;
  object-fit: contain;

}

.finalGraphTextTxtImgBp {
  font-size: 1em !important;
  color: var(--gray-800) !important;

}
.ButtonsProfileGraphButtonClContainer strong {
  
  color: var(--gray-700) !important;
}
.ButtonsProfileGraphButtonClContainer p {
  margin-left: 8px;
}
.StrongTitleGraphCl {
  margin-top: 1em;

}

.StrongTxtTitleGraphCl {
  color: var(--gray-600);
  line-height: 21px;
  letter-spacing: 2%;

}

.ButtonsProfileGraphTextCl {
  height: auto;
  display: flex;
  flex-direction: column;
}

.ButtonsProfileGraphButtonCl {
  margin-top: 2em;
  padding: 1em;
  border-radius: 0.5em;
  box-shadow: 2px 2px 2px 1px rgba(74, 73, 73, 0.2);
  width: 15em;
  margin-right: 1em;
  display: flex;
  justify-content: space-between;
  background-color: var(--white-base);
}

.firstButtonGraphCl {
  background-color: var(--violet-200);
}

.secondButtonGraphCl {
  background-color: var(--error-200);
}

.threeButtonGraphCl {
  background-color: var(--blue-200);
}

.fourButtonGraphCl {
  background-color: var(--success-200);
}

.fiveButtonGraphCl {
  background-color: var(--pink-200);
}

@media only screen and (max-width: 1305) {
  .verticalMenu {
    width: 94%;
  }
}

@media (max-width:1024px) {

  .apexcharts-legend.apx-legend-position-bottom.apexcharts-align-left,
  .apexcharts-legend.apx-legend-position-top.apexcharts-align-left,
  .apexcharts-legend.apx-legend-position-right,
  .apexcharts-legend.apx-legend-position-left {
    right: 2px !important;
  }

  .buttonContainer {
    display: flex;
    justify-content: flex-end;
    margin-top: 32px;
    margin-bottom: 32px;
    position: fixed;
    left: 94%;
    top: 90%;
  }

  .dobleDoughnutdivCLWithTitleCLdonut,
  .dobleDoughnutdivCLWithTitleCLdonutTasacion {
    width: 97%;
  }
}

/* @media (max-width:1440px) {
  .dashboardVerticalMenuContainer {
    margin-right: 32px;
  }
} */

@media (min-width: 1440px) {
  .dobleDoughnutdivCLWithTitleCLdonut {
    width: 96.5%;
    margin-top: 15px;
  }

  .dobleDoughnutdivCLWithTitleCLdonut,
  .dobleDoughnutdivCLWithTitleCLdonutTasacion {
    width: 96.5%;
  }

  .amplify_body_container.navbar-open.tablero,
  .amplify_body_container.tablero {
    width: 100%;
  }

  .caracteristicasSocioDemograficas {
    max-width: 1038px;
    align-self: center;
  }

}

@media (min-width:1920px) {

  .apexcharts-legend.apx-legend-position-bottom.apexcharts-align-left,
  .apexcharts-legend.apx-legend-position-top.apexcharts-align-left,
  .apexcharts-legend.apx-legend-position-right,
  .apexcharts-legend.apx-legend-position-left {
    right: 600px !important;
  }

  .dobleDoughnutContainerCL,
  .dobleSpineChartsContainerWithTitleCL,
  .finalGraph,
  .verticalBarCL {
    max-width: 876px;
  }
}

.css-xae7kp-container {
  width: auto !important;
  height: 100% !important;
  background-color: var(--gray-100);
  margin-bottom: 24px;
}

.css-1f43avz-a11yText-A11yText {
  padding-left: 8px !important;
}

.css-pt76tv-menu {
  margin-top: 0px !important;
  margin-bottom: 0px !important;

}

.css-pt76tv-menu {
  width: 481px !important;
  z-index: 9 !important;
}

.apexcharts-toolbar {
  z-index: 1 !important;
}


.chartDataContenedordonut {
  width: auto !important;
}

/* .dobleDoughnutContainerCL{
  max-width: 876px;
} */

@media (min-width:1920px) {
  .ChartsGraphicsCL {
    max-width: 878px;
  }
}

/*  css-pt76tv-menu" */
@media (min-width:1024px) and (max-width:1439px) {
  .dobleDoughnutdivCLWithTitleCLdonutTasacion {
    width: 97%;
  }
}

.dobleDoughnutdivCLdonut {
  width: 30% !important;
}

.dobleDoughnutdivCLdonutBars {
  width: 70% !important;
  ;
}

.apexcharts-tooltip {
  color: var(--gray-600);
}