@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

.cardSliderBarGeneroContainer{
  display: flex;
  width: 359px;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 10px;
  border: 1.5px solid var(--gray-300);
  background-color: var(--white-base);
}
.cardSliderBarGeneroTitle,
.cardSliderBarGeneroTexto {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  
  font-style: normal;
  line-height: 19.5px; 
  letter-spacing: 0.26px;
}
.cardSliderBarGeneroTitle{
  color: var(--gray-600);
    font-weight: 600;

  font-size: 16px;
}
.cardSliderBarGeneroTexto{
  color: var(--gray-600);
  font-size: 12px;
  font-weight: 400;
 }
