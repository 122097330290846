@font-face {
  font-family: 'I';
  src: url('https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap');
}

.Configuracion{
  display: flex;
  width: 433px;
  padding: 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  background-color: var(--white-base);
  align-self: center;
  filter: drop-shadow(1px 1px 9px #EDEDED);

}
.Configuracion_FormContainer h1{
  margin-bottom: 16px;
} 
.Configuracion_FormContainer{
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}
.Configuracion_FormContainer p {
  color: var(--gray-600);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0px 8px;
}
.formConfig {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  margin: 16px 0;
}
.SelectConfig{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 38px;
  width: 100%;
  color: var(--gray-600);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.26px;
}
.SelectConfig:focus{
  outline: none;
  border-radius: 10px;
  border: 2px solid var(--primary-400);
  background-color: var(--white-base);
}
.selectConfigText{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
}
.Configuracion_botones {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  align-self: flex-end;
  padding: 0px;
  gap: 16px;
}
.secondaryButton {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  width: 122px;
  height: 40px;
  background-color: var(--white-base);
  color: var(--gray-600);
  border: 1.5px solid #E2E4E3;
  border-radius: 20px;
  cursor: pointer;
}
.primaryButton{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  gap: 10px;  
  width: 122px;
  height: 40px;
  background: var(--primary-600);
  color: var(--white-base);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  cursor: pointer;
}

@media (max-width:1439px){
  .Configuracion{
    display: flex;
    width: 301px;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
  }
  .SelectConfig{
    display: flex;
    height: 32px;
    padding: 0px 8px;
  }
  .btnPrimaryBuyerPersona{
    display: flex;
height: 38px;
padding: 8px 12px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 10px;
width: 120px;
  }
  .btnSecondary{
width: 144px;
  }
  .Configuracion_botones{
    gap: 12px;
  }
}

.css-t3ipsp-control{
  width: 433px!important;
  height: 38px!important;
  box-shadow: none!important;
  border-radius: 10px !important;
  border: 2px solid var(--gray-300)!important;
  background: var(--white-base)!important;
  padding: 0px 8px!important;
  margin: 0px!important;
  font-size: 12px!important;
   font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',  sans-serif !important;

  color: var(--gray-600)!important;
}
.css-t3ipsp-control:hover {
  box-shadow: none!important;
  border-radius: 10px!important;
  border: 2px solid var(--primary-400)!important;
  background: var(--white-base)!important;
margin: 0px!important;
}


/* #react-select-4-listbox{
  height: 100px!important; 
  max-height: 100px!important;
} */
.css-1nmdiq5-menu{
  height: auto!important;
  font-size: 12px!important;
   font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',  sans-serif !important;
  color: var(--gray-600)!important;
  margin-top: 0px!important;
  margin-bottom: 0px!important;
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.10)!important;
  border-radius: 6px!important;
  
  }
  
/* .css-15lsz6c-indicatorContainer{
padding: 0px!important;
} */
