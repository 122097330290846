.amplifyManualFiltros {
    width: 1060px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 24px;
    gap: 24px;
    border-radius: 10px;
    border: 0.5px solid var(--gray-300);
    background-color: var(--white-base);
}
.amplifyManualFiltroRegion {
    display: flex;
    width: 100%;
    height: auto;
    align-items: center;
    gap: 6px;
}
.amplifyManualFiltroLabel {
    display: flex;
    padding: 0px 8px;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    color:  var(--gray-600);
     font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.amplifyManualFiltroContainer {
    display: flex;
    width: 100%;
    height: auto;
    align-items: center;
    gap: 16px;
    min-width: 600px
}
.amplifyManualFiltroContainerSelect {
    display: flex;
    flex-direction: column;
    align-self: center;
    gap: 6px;
    width: 244px;
}
.amplifyManualFiltroContainerSelectCheckbox {
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: center;
    gap: 6px;
}
.amplifyManualFiltroImgDelete {
    display: flex;
    align-items: center;
    margin-top: 25px;
}
.css-3w2yfm-ValueContainer {
    min-width: none;
    flex-wrap: initial !important;
    -webkit-flex-wrap: initial !important;

}
.css-1p3m7a8-multiValue {
    min-width: max-content !important;
}
.css-dn2fk8-container {
    height: 38px !important;
    min-width: 200px;
}
.css-nwhe5y-container{
    height: 38px!important;
}
.botonManualesContenedor, .botonManualesContenedorPrimero {
    display: flex;
    align-self: end;
    gap: 8px;
    margin-left: 0
}
.ButtonManual {
    display: flex;
    width: 38px;
    height: 38px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border: 0.5px solid #E2E4E3;
    background-color: var(--white-base);
}
.ButtonManualMas {
    margin-left: 0px;
}
.inputManuales {
    display: flex;
height: 36px;
    width: 66px;
    align-items: center;
    align-self: stretch;
    background-color: var(--white-base);
}
.inputManualesGuion{
    color: var(--gray-600);
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 19.5px; /* 139.286% */
    letter-spacing: 0.28px;
    margin-left: -12px;
    margin-right: -12px;
}
.inputManualesGuionPrimero{
    margin-top: 22px;
}
.inputManualesPrimero {
    margin-top: 21px;
}
.selectManualesInt__control{
  padding: 0px 8px;
 min-width: 460px!important;
  border-radius: 10px!important;
border: 2px solid var(--gray-300)!important;
background: var(--gray-25)!important;  
}
/* .css-w1iznm-control{
    min-width: 460px!important;
} */
.primerSelectAM__control{
  padding: 0px 8px;
  width: 244px;
  height: 38px!important;
  border-radius: 10px!important;
border: 2px solid var(--gray-300)!important;
background: var(--white-base)!important;
}
.primerSelectAM__menu,
.selectManualesInt__menu{
    display: flex;
    padding: 8px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    margin: 0px!important;
    border-radius: 6px;
    background: var(--white-base)!important;
    box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.10)!important;
}
.primerSelectAM__menu.css-pt76tv-menu{
    width: 244px!important;
    margin-top: 8px!important;
}
.selectAnchoMayorActive{
    min-width: 244px;
    max-width: 344px;
    
}
.primerSelectAM__single-value,
.selectManualesInt__single-value,
.inputManuales, .css-qbdosj-Input, .css-i8wigj-control, .css-2olqon-control,.css-i8wigj-control{
    color: var(--gray-600)!important;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
    font-size: 12px!important;
    font-style: normal!important;
    font-weight: 400!important;
    line-height: 19.5px; /* 139.286% */
    letter-spacing: 0.28px!important;
}
.css-i8wigj-control{
    width: 481px!important;
    height: 38px!important;
}
.css-2olqon-control{
    padding-left: 8px!important;
}
.primerSelectAM__control--is-focused,
.selectManualesInt__control--is-focused,
.inputManuales:focus, .css-i8wigj-control:focus{
    border-radius: 10px!important;
    border: 2px solid var(--primary-400)!important;
    box-shadow: 2px solid var(--primary-400)!important;
}
.primerSelectAM__placeholder,
.selectManualesInt__placeholder,
.placeholderSelectManualesInt,
.inputManuales.inputManualesPrimero::placeholder,
.inputManuales::placeholder, .primerSelectAM__control::placeholder, .css-104q2g0-placeholder, .css-i8wigj-control::placeholder {
    color: var(--gray-400)!important;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
    font-size: 12px!important;
    font-style: normal!important;
    font-weight: 400!important;
    line-height: normal!important;
    letter-spacing: 0.28px!important;
    padding: 0px!important;
}
.css-104q2g0-placeholder, .css-1dimb5e-singleValue{
    margin-left: 0px!important;
    margin-right: 0px!important;
}
@media (max-width:1919px){
    .amplifyManualFiltros{
        max-width: 1100px;
    }
}
@media (min-width:1920px){
    .amplifyManualFiltros{
        max-width: 1100px;
    }

.css-1dyz3mf{
    flex-wrap: nowrap !important;
} 
 }
 .ValTextManuales::first-letter {
    text-transform: uppercase !important
 }

 .selectManualesInt__single-value{
    width: 100%!important;
 }