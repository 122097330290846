
.badgedSolid {
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 10.5px;
  line-height: 15%;
  align-items: center;
  text-align: center;
  letter-spacing: 0.005em;
  color: #ffffff;
  width: fit-content;
  padding: 10.2px 7.2px;
}
.badgedSoft {
  display: flex;
  flex-direction: row;
  padding: 10.2px 7.2px;
  font-style: normal;
  color: white;
  font-weight: 400;
  font-size: 10.5px;
  line-height: 15%;
  align-items: center;
  text-align: center;
  letter-spacing: 0.005em;
  width: fit-content;
  border-radius: 10px;
  border: 0.25px solid;
}

.badgedCompleteSoft {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #00E5B1;
  border-radius: 10px;
}
.badgedNoSuccessSoft {
  display: inline-flex;
height: 8px;
padding: 6.2px 4.2px;
justify-content: center;
align-items: center;
flex-shrink: 0;
border-radius: 6px;
border: 0.25px solid #FF4560;
background: rgba(255, 77, 104, 0.15);
color: #FF4560;
text-align: center;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


font-size: 10px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.05px;
}
.badgedProgressSolid {
  background: #feb019;
}
.badgedCompleteSolid {
  background: #00d8b6;
}
.badgedNoSuccessSolid {
  background: #ff4560;
} 
.badgedSolid {
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 10.5px;
  line-height: 15%;
  align-items: center;
  text-align: center;
  letter-spacing: 0.005em;
  color: var(--gray-600);
  width: fit-content;
  padding: 10.2px 7.2px;
}
.badgedSoft {
  display: flex;
  flex-direction: row;
  padding: 6.2px 7.2px;
  font-style: normal;
  font-weight: 400;
  font-size: 10.5px;
  line-height: 108%;
  align-items: center;
  text-align: center;
  letter-spacing: 0.005em;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 10px;
  border: 0.25px solid;
}
.badgedCompleteSoft {
  display: inline-flex;
  height: 8px;
  padding: 6.2px 4.2px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 6px;
  border: 0.25px solid  #43D39E;
  background: rgba(0, 229, 177, 0.20);
color:  #00E5B1;
text-align: center;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


font-size: 10px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.05px;
}
.badgedProgressSoft {
  display: inline-flex;
  height: 20.4px;
  padding: 6.2px 4.2px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
.modelando{
  display: inline-flex;
  height: 8px;
  padding: 6.2px 4.2px;
  border-radius: 6px;
  border: 0.25px solid var(--primary-500);
  background: rgba(255, 140, 0, 0.20);
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  color: var(--primary-500);
  text-align: center;
      font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.05px;
}
.perfilEnConstruccion{   
  display: inline-flex;
  height: 8px;
  padding: 6.2px 4.2px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 6px;
  border: 0.25px solid var(--gray-600);
  background: var(--gray-50);
  color: var(--gray-600);
  text-align: center;
      font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.05px;
}
.badgedSinExito{
  display: inline-flex;
  height: 8px;
  padding: 6.2px 4.2px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 6px;
  border: 0.25px solid #FF4560;
  background: rgba(255, 77, 104, 0.15);
  color: #FF4560;
  text-align: center;
      font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.05px;
}
.badgedProgressSolid {
  background: var(--yellow);
}
.badgedCompleteSolid {
  background: #00d8b6;
}
.badgedNoSuccessSolid {
  background: var(--red);
}
@media (max-width:1439px) {
  .badgedNoSuccessSoft{
    display: flex;
    width: 40px;
    height: 10px;
    padding: 4px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    color: #FF4560;
text-align: center;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


font-size: 8px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.04px;
border-radius: 6px;
border: 0.25px solid #FF4560;
background: rgba(255, 77, 104, 0.15);
  }
  
}