.amplifyCardContainerResumen{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}
.amplifyCardResumenTabla {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 20px;
  border: 1.5px solid var(--gray-300);
  background: var(--gray-50);
  width: 258px;
}
.amplifyCardResumenContainer{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: 8px;
}
.amplifyCardContainerResumen h2,
.titleResumenBP p,
.resumenBPDatosText p{
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  font-style: normal;
}
.amplifyCardContainerResumen h2{
  color: var(--gray-500);
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.28px;
  padding: 0px 16px;
  /* margin-bottom: 16px; */
}
.resumenBP{
  width: 242px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 8px;
  border-radius: 10px;
  border: 1.5px solid var(--gray-300);
  background-color: var(--white-base);
  align-self: center;
}
.resumenPerfiles{
  display: flex;
  padding: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 4px;
}
.titleResumenBP{
  display: flex;
  padding: 8px 0px;
  align-items: center;
  gap: 4px;
  align-self: stretch;
}
.titleResumenBP p{
  color: var(--gray-500);
  font-size: 12px;
  font-weight: 400;
  line-height: 12px; /* 92.308% */
  letter-spacing: 0.26px;
}
.resumenBPCardDatos,
.resumenGeneralDatos{
  display: flex;
  height: 28px;
  padding: 0px 4px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  width: 96%;
}
.resumenBPDatosText,
.resumenBPDatosTextTotal{
  display: flex;
  align-items: center;
  gap: 2px;
  align-self: stretch;
}
.resumenBPDatosText img,
.resumenBPDatosTextTotal img {
  width: 16px;
  height: 16px;
  flex-shrink: 0;
}
.resumenBPDatosText p,
.resumenBPCardDatos p,
.resumenGeneralDatos p {
  color: var(--gray-500);
      font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 12px; /* 100% */
  letter-spacing: 0.24px;
}
.resumenBPDatosText.solicitados {
  font-weight: 500;
  
}
.resumenBPDatosText.solicitados2,
.resumenBPDatosText.buscados2  {
  color: var(--gray-600);
      font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 12px; /* 100% */
  letter-spacing: 0.24px;
}
.resumenBPDatosText.encontrados2{
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0.24px;
}
.amplifyCardResumenTabla h3{
  color: var(--gray-500);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.24px;
  display: flex;
  /* height: 32px; */
  padding: 0px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}
.solicitadosBlack{
  color: var(--gray-600);
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 12px; /* 100% */
letter-spacing: 0.24px;
}
.dividerResumenTabla{
  border: 0.5px solid #E2E4E3;
  width: 100%;
}
.resumenGeneral{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: 100%;
}
.resumenGeneralContenidoContainer{
  width: 258px;
  display: flex;
  padding: 8px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  align-self: center;
  border-radius: 10px;
background-color: var(--white-base);
}
.borderOrange{
border: 1.5px solid var(--warning-300);
}
.borderGreen{
  border: 1.5px solid var(--success-300);
}
.redLegendSolicitados{
  color: var(--error-400);
      font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
  display: flex;
    justify-content: start;
    align-items: self-start;
    gap: 4px;
    width: 100%;
    margin-left: 28px;
}
.busquedaOrange {
  color: var(--warning-500)  !important;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
  font-size: 11px  !important;
  font-style: normal  !important;
  font-weight: 500  !important;
  line-height: 12px  !important;
  letter-spacing: 0.22px  !important;
  text-decoration-line: underline  !important;
}
.resumenGeneralContenedor{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: 8px;
}
.resumenBPCardPBDatos{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  width: 100%;
}
.resumenBPCardDatosContainer{
  width: 100%;
}
.resumenDatosTextSolicitados{
  color: var(--gray-600);
font-weight: 500!important;
}

.resumenGeneralDatosContainer{
  display: flex;
width: 86%;
flex-direction: column;
justify-content: center;
align-items: flex-start;
}