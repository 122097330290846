.vertical-progress-bar {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .dot {
    position: relative;
    width: 5px;
    height: 5px;
    background-color: #D9D9D9;
    border-radius: 50%;
    margin: 12px 0;
  }
  
  .dot::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 2px;
    height: 28px;
    background-color: #D9D9D9;
  }
  
  .dot:last-child::before {
    content: none; /* Oculta la línea después del último punto */
  }
  
  .dotOrange{
    background-color: var(--warning-400);
  }
  .dotGreen{
    background-color: var(--success-400);
  }