@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

.sliderBarGenero {
  text-align-last: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}
.sliderBar {
  margin-bottom: 1vh;
  height: 8px !important;
  background: #F5A973;
}
.sliderInput {
  display: flex;
  width: 67px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
}
.sliderInput input{
  display: flex;
  width: 34px;
height: 34px;
  justify-content: center;
  align-items: center;
  

  color: var(--gray-400);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.24px;
}
.sliderInput label{
  color: var(--gray-600);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px; /* 161.538% */
  letter-spacing: 0.26px;
}
@media (max-width:1024px){
  .css-vruit9-Slider{

    margin-bottom: 20px;
  }
  .sliderBarGenero{
    align-self: flex-start;
  }
}