.divChartContainer {
   margin: 1em;

}

.chart {
   display: flex;
   flex-direction: column;
   width: 100%;
   height: 100%;
}

.first-bar {
   border-top-left-radius: 5px;
   border-bottom-left-radius: 5px;
}

.last-bar {
   border-top-right-radius: 5px;
   border-bottom-right-radius: 5px;
}

.chartPropioDivBars {
   display: flex;
   width: 90%;
   height: 3em;
   justify-content: center;
   align-items: flex-end;
   align-self: center;
}

.textChartPropioDivBars {
   margin-bottom: 3em;
   color: var(--gray-800);
   font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
   ;
   font-size: 12px;
   font-style: normal;
   font-weight: 500;
   line-height: normal;
   letter-spacing: 0.24px;
}

.chartPropioDivPrimerElemento {
   margin-bottom: 2em;
}

.chartPropioDiv {
   display: flex;
   flex-direction: column;
   width: 100%;
   justify-content: flex-end;
   align-items: flex-start;
   height: 12em;

}

.bar {
   display: flex;
   position: relative;
   justify-content: center;
   align-items: center;
   height: 2.5em;
}


.bar-label {
   padding: 10px;
   position: absolute;
   top: -45%;
   left: 50%;
   transform: translate(-50%, -50%);
   color: var(--gray-600);
   font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
   font-style: normal;
   font-weight: 400;
   line-height: normal;
   letter-spacing: 0.26px;
   font-size: 12px;

}

.barDotsElement {
   margin-top: 1px;
   border-radius: 50%;
   width: 0.8em;
   height: 0.8em;
}

.barDotsElementText {
   padding: 0 5px;
   color: var(--gray-600);
   font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
   font-style: normal;
   font-weight: 400;
   line-height: normal;
   letter-spacing: 0.26px;
   font-size: 12px;
}

.barDotsDisabled {
   opacity: 40%;
}

.barDotsContainer {
   width: 100%;
   display: flex;
   justify-content: center;
   margin-top: 1em;
}

.barDots {
   display: flex;
   align-items: center;
   justify-content: center;
}

.tooltipBarrasPropio {
   display: flex;
   justify-content: center;
   align-items: center;
   position: absolute;
   right: -16px;
   top: calc(-100% + 14px);
   transform: translateX(80%);
   white-space: nowrap;
   padding: 10px 10px;
   border-radius: 6px;
   z-index: 5;
   background-color: white;
   opacity: 0.97;
   color: black;
   border: 1px solid var(--gray-200);
   font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
   font-size: 12px;
   font-style: normal;
   font-weight: 500;
   line-height: normal;
   letter-spacing: -0.14px;

}

.dotTooltipPropio {
   width: 1em;
   height: 1em;
   border-radius: 10px;
   margin-right: 0.5em
}

.quantityLabelPropio {
   margin-left: 0.5em;
   font-weight: bold
}

.chartPropioDivBarsNone {
   display: none;
}