
.manualesContainer{
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.manualesContainer h1{
  color: var(--gray-600);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  letter-spacing: 0.32px;
}
.automaticoContainerText,
.manualesContainerText{
  color: var(--gray-600);
  font-size: 12px;
  font-weight: 400;
  line-height: 19.5px; /* 139.286% */
  letter-spacing: 0.28px;
}

.manualesContainerInputSolicitud{
  display: flex;
  width: 427px;
  flex-direction: column;
  gap: 4px;
  height: 57px;
}
.manualesContainerLabelSolicitudText{
  color: var(--gray-600);
      font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  padding: 0px 6px;
  align-items: flex-start;
  gap: 10px;
}
.manualesContainerInputSolicitud input{
  display: flex;
  width: 236px;
  height: 36px;
  padding: 0px 8px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 10px;
  border: 2px solid var(--gray-300);
  background-color: var(--white-base);
}
.manualesContainerInputSolicitudText{
  color: var(--gray-600);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.5px; /* 139.286% */
  letter-spacing: 0.28px;
}
.manualesContainerInputSolicitud input:focus{
  border-radius: 10px;
  border: 2px solid var(--primary-400);
  background-color: var(--white-base);
}
.manualesContainerInputSolicitud input::placeholder{
  color: var(--gray-400);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.28px;
}
.manualesBotonesContainer{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}