.amplifyCardDescarga {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  width: 436px;
  height: 500px;
  padding: 24px 32px;
  border-radius: 20px;
  border: 2px solid var(--gray-300);
    background-color: var(--white-base);
    filter: drop-shadow(1px 1px 9px #EDEDED);

}
.amplifyCardDescarga img {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  width: 252px;
  height: 252px;
  padding: 24px;
}
.amplifyCardDescarga h2, 
.amplifyCardDescarga p {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  font-style: normal;
  text-align: center;
}
.amplifyCardDescarga h2 {
  color: var(--success-400);
   font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;



  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.48px;
  
}
.amplifyCardDescarga p {
  color: var(--gray-600);
  text-align: center;
   font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;



  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.5px; /* 162.5% */
  letter-spacing: 0.24px;
}
.amplifyCardDescarga p:nth-of-type(1){

  margin-bottom: 60px;
}

.amplifyCardDescargaTextos{
  display: flex;
flex-direction: column;
align-items: center;
gap: 24px;
align-self: stretch;
}