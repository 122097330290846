.inMaintenanceCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    gap: 44px;
    width: 100%;
    height: 100vh;
    border-radius: 8px;
}
.inMaintenance_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 19px;
    text-align: -webkit-center;
    width: 350px;
}
.inMaintenance_container h1 {
    color: var(--gray-600);
    text-align: center;
        font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.52px;
}
.textInMaintenance {
    width: 100%;
    height: 100%;
    color: var(--gray-600);
    text-align: center;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 19.5px;
    letter-spacing: 0.26px;
}

/* @media (max-width: 1440px) {
    .inMaintenanceCard{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 32px;
        gap: 32px;
        width: 100%;
        height: 100%;
        border-radius: 8px;
    }
} */