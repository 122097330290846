.progresBarAmplify{
  width: 100%;
  padding: 12px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.progressBar{
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
}
.progressBar::before{
  content: "";
  position: absolute;
  background: var(--gray-300);

  border-radius: 5px;
  height: 2px;
  width: 100%;
  z-index: -1;
  transition: 0.4s ease;
  
}
.progress{
  position: absolute;
  background: var(--gray-900);

  border-radius: 5px;
  height: 2px;
  width: 50%;
  z-index: -1;
  transition: 0.4s ease;
}
.circleBar {
  background-color: var(--gray-300);
  color: var(--gray-300);
  border-radius: 50%;
  height: 8px;
  width: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid var(--gray-300);
  transition: 0.4s ease;
}
.circleBar.activeBar {
  background-color: var(--white-base);
  color: var(--white-base);
  border: 2px solid var(--gray-900);
}
.circleBar.lastActiveBar{
  background-color: var(--gray-900);
  color: var(--gray-900);
  border: 2px solid var(--gray-900);
}
.progressContainerText {
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 8px 0;
  width: 108%;
}
.progressContainerText p {
  color: var(--gray-600);
      font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.24px;
}
.progressContainerText p.activeBar {
  color: var(--gray-600);
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.24px;
}