.greyBackgroundModalPopUpNewCompany {
    position: fixed;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 999;
    color: var(--gray-600);
}
.greyBackgroundModalPopUpNewCompany input{
   border:solid 1px var(--gray-300);
   padding-left: 10px;
}

.ModalExitoDetalleNewCompany {

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    border-radius: 5px;
    z-index: 1000;
    overflow: hidden;
}

.amplifyModalTablaExitoPopUpNewCompany {
    width: 563px;
    height: 328px;
    display: flex;
    padding: 32px;
    flex-direction: column;
    align-items: start;
    border-radius: 20px;
    background-color: var(--white-base);
    box-shadow: 8px 8px 12px 0px rgba(168, 168, 168, 0.10);
    left: 40%;
    top: 30%;
    position: absolute;
    justify-content: space-between;

}

.divPopUpAdminButtons {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.divPopUpAdminButtons button {
    margin: 1em;
}
.AdminInputBPAMNewCompany{
    width: 340px;
    height: 34px;
    border-radius: 8px;
    
}
.amplifyModalTablaExitoPopUpNewCompany p{
    margin-bottom: 5px;
}
.SelectFlags{
    display: flex;
    justify-content: start;
    align-items: center;
    padding-left: 1em;
}
.SelectFlags img {
   padding-right:0.5em;
}
@media (min-width:1439px) {
    .amplifyModalTablaExitoPopUpNewCompany {
        left: 35%;
        top: 16%;
    }
}

@media (min-width:1921px) {
    .amplifyModalTablaExitoPopUpNewCompany {
        width: auto;
        height: auto;
        left: 46%;
        top: 16%;

    }
}

@media (max-width: 1920px) {
    .ModalExitoDetalleNewCompany {
        height: 110vh;
        width: 118vw;

    }

}

@media (max-width: 1440px) {
    .ModalExitoDetalleNewCompany {
        height: 100vh;
        width: 100vw;
    }
}

@media (min-width:1919px) {
    .ModalExitoDetallePopUpAdmin {
        top: 64%;
        left: 50%;
        width: 100%;
        height: 100%;
    }
}