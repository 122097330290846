.buttonPrimaryLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7.2px 14.4px;
  background: var(--primary-600);
  border-radius: 5px;
  font-weight: 400;
  font-size: 19px;
  line-height: 30px;
  letter-spacing: 0.015em;
  color: var(--gray-600);
  border: none;
  width: 222.8px;
  height: 44.4px;
  cursor: pointer;
}
.buttonPrimary:hover {
  background: var(--primary-500);
    box-shadow: 4px 4px 4px #dedede;
    border-radius: 5px;
}

.buttonPrimary:active {
  background: var(--primary-600);
}

.buttonPrimary:disabled {
  background: var(--primary-200);
  border-radius: 5px;
}
.noLine {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 7.2px 14.4px;
  gap: 4px;
  width: 91.8px;
  height: 44.4px;
  background-color: var(--white-base);
  border-radius: 5px;
  font-weight: 400;
  font-size: 20px;
  border: none;
}
