.select {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  width: 100%;
  height: 40px;
  background-color: var(--white-base);
  border: 1px solid #e2e7f1;
  border-radius: 4px;
}

.boxToggle {
  display: flex;
  gap: 6px;
  align-items: center;
}
.selectToggle{
  display: flex;
}
.switch {
  position: relative;
  display: inline-block;
  width: 26px;
  height: 14px;
  padding: 2px;
}
.sliderSwitch.round {
  border-radius: 34px;
  width: 26px;
  height: 14px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.sliderSwitch {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--white-base);
   -webkit-transition: 0.4s;
  transition: 0.4s;
  border: 1px solid #e2e7f1;
  border-radius: 10px;
  
}

.sliderSwitch:before {
  position: absolute;
  content: "";
  height: 10px;
  width: 10px;
  left: 2px;
  bottom: 2px;
  background-color: #e2e7f1;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .sliderSwitch {
  background-color: var(--primary-500);
  border-color: var(--primary-500);
}


input:checked + .sliderSwitch:before {
  -webkit-transform: translateX(12px);
  -ms-transform: translateX(12px);
  transform: translateX(12px);
  background-color: var(--white-base);
   border: none;
}

.sliderSwitch.round:before {
  border-radius: 50%;
}

