.cardDatosEconomicos{
    display: flex;
    width: 359px;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    border-radius: 10px;
    border: 1.5px solid var(--gray-300);
    background-color: var(--white-base);
}
.cardDatosEconomicosTitulo,
.cardDatosEconomicosTexto{
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


    font-style: normal;
    line-height: 19.5px; 
    letter-spacing: 0.26px;
}
.cardDatosEconomicosTitulo{
    color: var(--gray-600);
    font-weight: 600;
    font-size: 16px;
}
.cardDatosEconomicosTexto{
    color: var(--gray-600);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


font-size: 12px;
font-weight: 400;
}
.cardDatosEconomicosSelector{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}
.cardDatosEconomicosSelector p,
.cardDatosEconomicosSelector select{
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

    font-style: normal;
    line-height: normal;
}
.cardDatosEconomicosSelector p{
    color: var(--gray-600);
    font-size: 12px;
    font-weight: 400;
}
.cardDatosEconomicosSelector select{
    display: flex;
    height: 38px;
    padding: 0px 8px;
    justify-content: center;
    gap: 10px;
    align-self: stretch;

    color: var(--gray-600);
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0.26px;
}
.cardDatosEconomicosSelector select:focus {
    outline: none;
    border-radius: 10px;
border: 2px solid var(--primary-400);
background-color: var(--white-base);
  }

  @media (max-width:1439px) {
    .cardDatosEconomicos{
        display: flex;
        width: 359px;
        padding: 24px;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
    }
  }
  .css-b62m3t-container{
    width: 100%;
}
.css-13cymwt-control{
    border: none!important;
   
}

.css-lgh12g-control, .css-1cckwem-control, .css-10sl92e-control,.css-2l2ysc-control, .css-13cymwt-control, .css-3kd8s5-control,.css-13mvri2-control, .css-tkzrsg-control, .css-4vr87o-control, .css-1057n9p-control, .css-i8wigj-control {
    border-radius: 10px!important;
    border: 2px solid var(--gray-300)!important;
box-shadow: none!important;
font-size: 12px!important;
 font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',  sans-serif !important;
color: var(--gray-600)!important;
font-weight: 400!important;
padding-left: 8px;
}
.css-1057n9p-control{
    border:2px solid var(--primary-500)!important;
}
.css-1cckwem-control{
    width: 190px!important;
    padding-left: 8px!important;
}
.css-tkzrsg-control:hover{
border-color: var(--gray-300)!important;
}
.css-1yzwmfm-control, .css-1c58wh6-control, .css-qbdosj-Input, .css-1dimb5e-singleValue{
    padding-left: 8px!important;
}
.css-1c58wh6-control{
    width: 190px!important;
}
.css-1yzwmfm-control:active{
    border-color: var(--gray-300)!important;
    padding-left: 8px!important;
}
.css-1yzwmfm-control:hover{
    border-color: var(--gray-300)!important; 
    padding-left: 8px!important;
}
.css-tkzrsg-control{
    padding-left: 8px!important;
}
.css-1yy2nbg-placeholder{
    padding-left: 8px;
    margin-left: 0px!important;
    margin-right: 0px!important;
}