.tooltip-containerBP{
  position: relative;
  display: inline-block!important;
}
.tooltip-container{
  position: relative;
  display: flex;
}
.tooltip,
.tooltip-menu-secundario .tooltip  {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: auto;
  right: -16px;
  top: calc(-100% + 18px);
  transform: translateX(100%);
  white-space: nowrap;
  padding: 8px 12px;
  height: 24px;
  z-index: 5;
  border-radius: 6px;
  background: var(--gray-100, #F2F4F7);
  color: var(--gray-600);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.14px;
}

@media (max-width:1439px) {
  .tooltip,
  .tooltip-menu-secundario .tooltip {
    top: calc(-100% + 11px);
    right: -17px;
    height: 24px;

    color: var(--gray-600);
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.12px;
  }
}