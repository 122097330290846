.amplifyModalTablaExito {
  display: flex;
  width: auto;
  padding: 32px;
  flex-direction: column;
  height: auto;
  align-items: center;
  border-radius: 20px;
  background-color: var(--white-base);
  box-shadow: 8px 8px 12px 0px rgba(168, 168, 168, 0.10);
  left: 40%;
  top: 30%;
  position: absolute;
}
.imgModalExito{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 367px;
  height: 216px;
  padding: 24px;
  border-radius: 20px;
  margin-bottom: 24px;
}
.imgModalExito img{
  width: 252px;
  height: 252px;
}
.amplifyModalTablaExito h2,
.amplifyModalTablaExito p {
  color: var(--gray-600);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  font-style: normal;
}
.amplifyModalTablaExito h2 {
  color: var(--gray-600);
   font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;



  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.32px;
}
.modalExitoCntenido{
  display: flex;
flex-direction: column;
align-items: center;
gap: 16px;

}
.Confirmacion_botones .cardExitoBotones{
  align-items: center;
}
.cardExitoBotones button{
  width: 199px;
  height: 40px;
}

.amplifyModalTablaExito p {
  font-size: 12px;
  font-weight: 400;
  line-height: 19.5px; 
  letter-spacing: 0.26px;
  margin-bottom: 60px;
}
@media (min-width:1439px){
  .amplifyModalTablaExito{
    left: 35%;
    top: 16%;
  }
}
@media (min-width:1921px) {
  .amplifyModalTablaExito{
width: auto;
height: auto;
left: 46%;
top: 16%;

  }
}