.CodeVerificationTitle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.codeVerfication .login__formRecupero {
    display: flex;
    width: auto;
    height: auto;
    padding: 0px 32px 40px 32px;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    flex-shrink: 0;
}

.CodeVerificationTitle h3 {
    margin-bottom: 1em;
    color: var(--gray-600);
}

.codeVerificationUl {
    font-size: 12px;
    padding-left: 2em;

}

.VerificationCodeVerification li {
    color: var(--gray-600);
    font-size: 12px;
    margin-left: 3em;
}

.form__titles--textRecupero--CodeVerification {
    padding-left: 5px;
    font-size: 12px;
    color: var(--gray-600);

}

.VerificationCodeVerification {
    display: flex;
    justify-content: start;
    width: 100%;
    flex-direction: column;
}
.form__input--texts:focus-visible{
height: auto !important;
}
.form__input--texts--error {
    border: 2px solid var(--primary-400);
    }
.error-message{
    font-size: 12px;
    color: var(--primary-600);
}
.CodeVerficationEye img, .CodeVerficationEyeBottom img{
    width: 1.3em;
}
.CodeVerficationEye{
    position: absolute;
  margin-top: 40px;
  margin-left: 68%;
    }
.CodeVerficationEyeBottom{
    position: absolute;
  margin-top: 90px;
  margin-left: 68%;
    }