@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

a {
  text-decoration: none;
}

/* Badget */
.procesandoDatos,
.modelandoDatos,
.analizandoPerfiles,
.badgedConExito,
.badgedSinExito {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 6.2px 4.2px;
  height: 8px;
  border-radius: 6px;

  color: var(--gray-600);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.05px;
}

.procesandoDatos,
.modelandoDatos,
.analizandoPerfiles {
  width: 111.6px;
}

.badgedConExito,
.badgedSinExito {
  width: 46px;
}

.procesandoDatos {
  border: 0.25px solid var(--gray-300);
  background: var(--gray-50);
}

.modelandoDatos {
  border: 0.25px solid var(--gray-300);
  background: var(--gray-200);
}

.analizandoPerfiles {
  border: 0.25px solid var(--warning-300);
  background: var(--warnig-100);
}

.badgedConExito {
  border: 0.25px solid var(--success-300);
  background: var(--success-100, );
}

.badgedSinExito {
  border: 0.25px solid var(--error-300);
  background: var(--error-100);
}

/* General */
.tablaHome, .tablaHomeAdmin {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 20px;
  max-height: 800px;
  border: .5px solid var(--gray-300);
}
.tablaHome{
  overflow-y: auto;
}

.tablePrincipalContainer {
  display: flex;
  flex-direction: column;
  width: 960px;


}

.tableContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  padding: 24px;
  width: 908px;

  background-color: var(--white-base);
  filter: drop-shadow(1px 1px 9px #EDEDED);
}

/* Header Contenedor*/
.tableHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  background-color: var(--white-base);
}

.tableHeader p {
  color: var(--gray-600);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.32px;
}

.search-input {
  position: relative;
  display: flex;
  align-items: center;
}

.search-input input {
  display: flex;
  width: 300px;
  align-items: center;
  height: 36px;
  font-size: 12px;
}

.search-input input::placeholder {
  color: var(--gray-400);
   font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.28px;
}

.inputSearchHome:focus-visible {
  border-radius: 10px;
  border: 2px solid var(--primary-400);
  background-color: var(--white-base);
  height: 34px;
}

.inputSearchHome::placeholder {
  padding-left: 24px;
}

.search-input img {
  position: absolute;
  margin-left: 9px;
}

.tableTabs {
  display: flex;
  align-items: center;
  align-self: stretch;
  /* gap: 4px; */
}

.tableTabs .tabsNoActive,
.tableTabs .tabsActive {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 20px 20px 0px 0px;
  border-top: 2px solid var(--gray-300);
  border-right: 2px solid var(--gray-300);
  border-left: 2px solid var(--gray-300);

  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.tableTabs .tabsNoActive {
  background: var(--gray-50);
  color: var(--gray-600);
}

.tableTabs button.tabsActive {
  background-color: var(--white-base);
  color: var(--primary-500);
}

/* TABLA: Header */
.tableContainer table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 20px;
  table-layout: fixed;
}

.tableContainer thead {
  border-bottom: 1px solid var(--gray-300);
}

.tableContainer tr {
  border-bottom: 0.5px solid var(--gray-300);
}

.tableContainer th {
  height: 46px;
  padding: 0px 8px;
  border-bottom: 0.5px solid var(--gray-300);
  background: var(--white-bg);
}

.thFilter {
  color: var(--gray-500);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.24px;
}

.thRegistro,
.thAction {
  width: 85px;
}

.thEstado,
.thFecha {
  width: 130px;
}

.thProceso {
  width: 120px;
}

.thHeader {
  display: flex;
  padding: 8px;
}

/* TABLA: Filtro */
.thHeader.thHovered {
  border-radius: 8px;
  border: 3px solid var(--blue-50);
}

.thInnerContainer:hover {
  border: 1px solid var(--blue-200);
}

.thInnerContainer {
  display: flex;
  padding: 8px 8px 8px 4px;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
}

.thInnerContainer.thSorted {
  border: 1px solid var(--blue-500);
}

.thHeader.thSorted {
  border-radius: 8px;
  border: 3px solid var(--blue-100);
}

/* TABLA: Body */
.tableContainer td {
  height: 48px;
  padding: 0px 16px;
}
.tableContainer .tdShortPadding {
  padding: 0px 8px;
}
.tdName,
.tdOrganization,
.tdIndustry,
.tdAmount,
.tdCreated,
.tdBadged,
.verticalIcon {
  /* color: var(--texto-negro-texto, #707477); */
  color: var(--gray-500);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 12px;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.24px;
  overflow: hidden;
  white-space: revert;
}

.tdName,
.tdAmount {
  font-weight: 500;
}

.tdOrganization,
.tdIndustry,
.tdCreated {
  font-weight: 400;
}

.verticalIcon {
  text-align: center;
}

/* Buyer Persona */
/* Amplify */
.tableBuyerPerson,
.tableAmplify {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.thAdminAmplify {
  width: 70px;
}
.thAdminName,.thAdminPais, .thAdminEstado{
  width: 90px;
}
.thAdminEstado{
width: 110px;
}
 .thAdminBuyerPerson,.thAdminActions {
width: 105px;
}

.thFechaDeCreacion{
  width: 7em;

}
.thFechaDeCreacion .thHeader{
 padding: 0px;
}
.thAdminUserActive{
  width:90px ;
}
.tdFlagTextAdmin{
display: flex;
align-items: center;
justify-content:start;
text-overflow: ellipsis;
white-space: nowrap;
}
.tdFlagTextAdmin img{
  margin-right: 0.3em;
  }
  .activeUserOrganization, .inactiveUserOrganization{
    width: 68px;
    padding: 5px ;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content:center;
height: 20px;
cursor: pointer;
  }
  .activeUserOrganizationPopUp, .inactiveUserOrganizationPopUp{
    width: 68px;
    padding: 5px ;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content:center;
height: 20px;
cursor: pointer;
  }
  .activeUserOrganization,.activeUserOrganizationPopUp{
    background-color: var(--success-100);
    border: solid 0.25px var(--success-300);
  }
  .inactiveUserOrganization , .inactiveUserOrganizationPopUp{
    background-color: var(--gray-100);
    border: solid 1px var(--gray-300);
  }
  .inactiveUserOrganization img, .activeUserOrganization img{
    width: min-content;
    height: min-content;
    margin: 0px  6px;

    align-self: center;
  }

@media screen and (max-width: 1024px) {
  .tablePrincipalContainer {
    width: 90%;
  }

  .tableContainer {
    width: 95%
  }

  .thRegistro,
  .thAction {
    width: 40px;
  }

  .thEstado {
    width: 100px;
  }

  .thFecha {
    width: 90px;
  }
}


@media (max-width:1439px) {
  .tableContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    gap: 24px;
    border-radius: 20px 20px 20px 20px;
    border: 2px solid var(--gray-300);
    background-color: var(--white-base);
  }

  .tableTabs {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    /* gap: 4px; */
    align-self: flex-start;
    width: 100%;
  }

  .tablePrincipalContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .tabla {
    overflow-x: auto;
    display: block;
  }

  th,
  td {
    white-space: nowrap;
  }

  .tdName,
  .tdOrganization,
  .tdIndustry,
  .tdAmount,
  .tdCreated {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .filtro-options {
    margin-top: 5px;
    border: none;
  }

  .thFilter {
    color: var(--gray-500);
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.24px;
  }

  .tdIndustry {
    overflow: hidden;
    white-space: revert;
  }

  .tdName {
    overflow: hidden;
    white-space: revert;
  }

  .extraccionDeDatos {
    display: inline-flex;
    height: 10px;
    padding: 4px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 6px;
    background: var(--gray-600);
    color: var(--white-base);
    text-align: center;
     font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
;
    font-size: 8px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 100%;
    letter-spacing: 0.04px;
  }

  .badgedSinExito {
    display: inline-flex;
    height: 10px;
    padding: 4px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    color: var(--error-300);
    text-align: center;
     font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
;
    font-size: 8px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.04px;
  }

  .modelando {
    display: inline-flex;
    height: 10px;
    padding: 4px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 6px;
    border: 0.25px solid var(--primary-500);
    background: rgba(255, 140, 0, 0.20);
    color: var(--primary-500);
    text-align: center;
     font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
;
    font-size: 8px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.04px;
  }

  .perfilEnConstruccion {
    display: inline-flex;
    height: 10px;
    padding: 4px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 6px;
    border: 0.25px solid var(--gray-600);
    background: var(--gray-50);
    color: var(--gray-600);
    text-align: center;
     font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 8px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.04px;
  }

  .badgedCompleteSoft {
    display: inline-flex;
    height: 10px;
    padding: 4px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    color: #00E5B1;
    text-align: center;
     font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
;
    font-size: 8px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.04px;
    border-radius: 6px;
    border: 0.25px solid #43D39E;
    background: rgba(0, 229, 177, 0.20);
  }
}

.PopUpEliminar {
  z-index: 999;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: rgba(0,0,0,.7);
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0px;
}

.PopUpEliminarContainer {
  display: flex;
  width: 374px;
  height: 189px;
  padding: 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 20px;
  background: #FEFEFE;
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.10);
}

.PopUpEliminarContainer h2 {
  color: #0C0C0F;
  align-self: center;
  text-align: center;
   font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.32px;
  min-width: 236px;
  height: 49px;
  max-width: 70%;
}

.PopUpEliminarContainer p {
  color: var(--gray-600);
  text-align: center;
   font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.5px;
  /* 162.5% */
  letter-spacing: 0.24px;
}

.PopUpEliminarContainerButton {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin-top: 16px;
  align-self: stretch;
}
.PopUpEliminarContainerButtonElementCancelar{
  display: flex;
width: 146px;
height: 38px;
padding: 8px 12px;
justify-content: center;
align-items: center;
gap: 4px;
border-radius: 8px;
border: 1.5px solid var(--gray-300);
background: var(--base);
}
.PopUpEliminarContainerButtonElement{
  display: flex;
width: 146px;
height: 38px;
padding: 8px 12px;
justify-content: center;
align-items: center;
gap: 4px;
border-radius: 8px;
background: var(--primary-600);
box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25);
color: var(--white-base);
}
.imgLupaBuscador {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.tablePrincipalContainer {
  position: relative;
}
.lastItemClass{
  border-bottom: 0px !important;
}
.nombreAdminThHeader{
  border-radius: 1.2em 0px 0px 0px !important;
}
.iconAdminThHeader{
  border-radius:0px 1.2em 0px 0px !important;
}


