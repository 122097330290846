.mainAdminUser .tdName {
    margin-left: 8px;
}

.mainAdminUser table {
    border-collapse: collapse;
    border: none;
}

.mainAdminUser table input {
    padding-left: 5px;
    color: var(--gray-600);
}

.headerAdmin .adminBreadCrums {
    display: flex;
    gap: 16px;
    align-items: center;
    padding-top: 40px;
    padding-left: 80px;
}

.headerAdmin .adminBreadCrums img {
    width: 8px;
    height: 13px;
    object-fit: contain;
}

.headerAdmin .adminBreadCrums .firstTextHeaderAdmin {
    color: var(--gray-400);
}

.adminTitle {
    padding-top: 40px;
    padding-left: 80px;
}

.adminTitle p {
    font-size: 32px;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

}

.mainAdminUser {
    padding-top: 20px;
    padding-left: 20px;
    color: var(--gray-600);
    background-color: var(--white-base);
    border: 1.5px solid var(--gray-300);
    border-radius: 15px;
    margin-top: 40px;
    margin-left: 80px;
    max-width: 1050px;
    width: 86%;
    overflow: hidden;
    align-self: center;
}

.headerMainAdmin {
    display: flex;
}

.headerMainAdminInput,
.AdminInputPassword,
.AdminInputBPAM {
    display: flex;
    width: 340px;
    height: 36px;
    align-items: start;
    flex-shrink: 0;
    border-radius: 10px;
    border: 2px solid var(--gray-300);
    background-color: var(--white-base);
    padding-left: 5px;

}

.headerMainAdminInput {
    font-weight: 300;
    color: var(--gray-600);
}

.divAdminUserName p,
.divContainerAdminCountry p {
    margin-bottom: 5px;
}

.mainAdminUser .thAdminBuyerPerson {
    width: 7em;
}

.mainAdminUser .verticalIconAdmin img {
    width: 14px;
}

.mainAdminUser .verticalIconAdmin {
    text-align: start;
}

.mainAdminUser .thAdminUserAmplify {
    width: 8em;
}

.thAdminNameUser {
    width: 175px;
}

.AdminInputBPAM,
.AdminInputPassword {
    width: 70px;
    height: 34px;
}

.divContainerAdminCountry {
    margin-left: 62px;
}

.divAdminCountry {
    display: flex;
}

.thAdminCountry {
    width: 90px;
}


.AdminSaveChangeButtons {
    display: flex;
    justify-content: end;
}

.containerAdmin th {
    background-color: var(--gray-50);
}

.containerAdmin .thFechaDeCreacion {
    padding-left: 0.5em;

}

.mainAdminUser thead tr {
    height: 3em;
}

.mainAdminUser tbody tr {
    height: 4em;
    border-top: solid 1px var(--gray-300);
}

.mainAdminUser tbody .thAdminEstado {
    width: 112px;
}

.admin_body_container {
    display: flex;
    margin-left: 80px;
    flex-direction: column;
}

@keyframes animation {
    from {
        background-color: var(--gray-400)
    }

    to {
        background-color: var(--primary-600)
    }
}

.btnUsuarioAdminTable {
    background-color: var(--gray-400);
    color: var(--white-base);
    width: 98px;
    height: 38px;
    border-radius: 7px;
}

.btnUsuarioAdminTable:hover {
    background-color: var(--primary-600);
    animation-name: animation;
    animation-duration: 1s;
}

.thFechaDeCreacionAdminUser {
    width: 11em;
}

.mainAdminUser .btnSecondary,
.mainAdminUser .btnPrimary {
    margin: 1em;
}

.divAdminCountry {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.divAdminCountry img {
    margin-right: 5px;
    object-fit: contain;
}

.mainAdminUserTitle {
    margin: 2em 0 10px 0;
    font-weight: 500;
}

.btnUsuarioAdminTable {
    margin-top: 1em;
    margin-bottom: 2em;
}

.userOrganizationSelect .css-19pb2od-control {
    height: 33px;
}

.verticalIconStars {
    text-align: start;
}

.thAdminTableUserActive {
    width: 157px;
}

.AdminTableInputPassword {
    width: 85%;
}

.thAdminEstadoTable {
    width: 118px;
}

.error-message-admin {
    position: absolute;
    font-size: 10px;
    color: var(--gray-600);
    background-color: white;
    border: solid 1px var(--gray-600);
    border-radius: 1em 1em 1em 1em;
    padding: 3px;
    width: 14em;
    translate: 100% -82%;

}
.thAdminEstadoTableAdmin{
    width: 180px;
}
.btnDisabledAdminTable{
background-color: var(--primary-200);
color: var(--gray-400);}