.amplifyResumen_body_container {
    display: flex;
    flex-direction: column;
    align-items: center;

}

.amplifyResumen_data_container {
    display: flex;
    width: 26.25rem;
    padding: 1rem;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
}

.AmplifyResumenDatosCardContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
}

.AmplifyResumenDatosCardContainer p {
    color: var(--gray-600);
    text-align: center;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.21875rem;
    /* 162.5% */
    letter-spacing: 0.015rem;
}

.imgContainerAmplifyResumen {
    border-radius: 0.375rem;
    background: var(--blue-25);
    display: flex;
    width: 2.5rem;
    height: 2.5rem;
    justify-content: center;
    align-items: center;
}

.headerAmplifyResumen {

    width: 676px;
}

.mainAmplifyResumen {
    height: 100%;
    width: 420px;
    background-color: var(--white-base);
    padding: 1rem;
    border-radius: 1.25rem;
    border: 2px solid var(--gray-300);
    box-shadow: 1px 1px 9px 0px #EDEDED;
}

.AmplifyResumenDatosContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.375rem;
    align-self: stretch;

}

.AmplifyResumenDatosCard {
    display: flex;
    width: 20rem;
    align-items: center;
    gap: 0.5rem;
    margin: 0.5em 0;
    justify-content: flex-start;
}

.TitleAmplifyResumenDatos {
    color: var(--gray-600);
    text-align: center;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.21875rem;
    /* 121.875% */
    letter-spacing: 0.02rem;
}

.mainAmplifyResumenImgContainer {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 0.625rem;
    background: var(--blue-25);
    margin-bottom: 1em;
}

.AmplifyResumenDatosContainer div h2 {
    color: var(--gray-400);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

    font-size: 0.75rem;
    font-style: normal;
    font-weight: 300;
    line-height: 1.21875rem;
    /* 162.5% */
    letter-spacing: 0.015rem;
}

.AmplifyResumenDatosContainer div p {
    color: var(--gray-600);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.21875rem;
    /* 108.333% */
    letter-spacing: 0.0225rem;
}

.AmplifyResumenDatosContainerDatosDinamicos {
    display: flex;
    padding: 0.5rem 0.125rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.125rem;
    flex: 1 0 0;
}


.signosAmplifyResumenDatosContainer {
    color: var(--gray-400);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.21875rem;
    /* 97.5% */
    letter-spacing: 0.025rem;
}

.lineAmplifyResumenDatosContainer {
    width: 19.1875rem;
    height: 0.09375rem;
    background: var(--gray-300);
    margin-left: 12%;
}

.AmplifyResumenButtonsCard {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
    align-self: stretch;
    width: 100%;
    margin-top: 1.5rem
}

.AmplifyResumenButtonCancelar {
    display: flex;
    height: 2.5rem;
    padding: 0.5rem 0.75rem;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
    flex: 1 0 0;
    border-radius: 0.5rem;
    border: 1.5px solid var(--gray-300);
    background-color: var(--white-base);
    color: var(--gray-500);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


font-size: 0.875rem;
font-style: normal;
font-weight: 500;
line-height: 1.3125rem; /* 150% */
letter-spacing: 0.0175rem;
}

.AmplifyResumenButtonGenerar {
    display: flex;
    height: 2.5rem;
    padding: 0.5rem 0.75rem;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
    flex: 1 0 0;
    border-radius: 0.5rem;
    background: var(--primary-600);
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25);
    color: var(--white-base);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


font-size: 0.875rem;
font-style: normal;
font-weight: 500;
line-height: 1.3125rem; /* 150% */
letter-spacing: 0.0175rem;
}