.procesoExitosoContainer{
   display: flex;
width: auto;
height: 100vh;
padding: 10px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 10px;
}
.procesoExitosoCard{
    display: flex;
padding: 20px;
flex-direction: column;
align-items: center;
gap: 44px;
}
.procesoExitosoTexto{
    display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 19px;
}
.procesoExitosoTexto h1{
    color: var(--gray-600);
text-align: center;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


font-size: 26px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.52px;

}
.procesoExitosoTexto p{
    color: var(--gray-600);
text-align: center;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


font-size: 12px;
font-style: normal;
font-weight: 300;
line-height: 19.5px; 
letter-spacing: 0.26px;
}
.procesoExitosoButton{
    display: flex;
width: 146px;
padding: 8px 12px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 8px;
background: var(--primary-600);
color: var(--white-base);
font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 21px; 
letter-spacing: 0.28px;
box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25);

}
.procesoExitosoButton:hover{
    border-radius: 8px;
background: var(--primary-500, #F18F49);

box-shadow: 0px 2px 4px 0px #D0D5DD;
}